import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from '../Buttons/CustomButton';

const collection1BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/AquaCollection/refs/heads/main/';
const collection2BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/ImagenesEar/refs/heads/main/';
const collection3BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/TerraImages/refs/heads/main/';

const getRandomImageUrl = () => {
  const totalWeight = 50 + 40 + 10;
  const random = Math.random() * totalWeight;

  if (random < 50) {
    const id = Math.floor(Math.random() * (10280 - 10181 + 1)) + 10181;
    return { url: `${collection3BaseUrl}${id}.png`, collection: 'TERRA COLECCIÓN' };
  } else if (random < 50 + 40) {
    const id = Math.floor(Math.random() * (140 - 47 + 1)) + 47;
    return { url: `${collection1BaseUrl}${id}.png`, collection: 'AQUA COLECCIÓN' };
  } else {
    const id = Math.floor(Math.random() * (97 - 1 + 1)) + 1;
    return { url: `${collection2BaseUrl}${id}.png`, collection: 'AER COLECCIÓN' };
  }
};

const NFTCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  /* Ajustes responsive */
  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 10px;
  }
`;

const ImageSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  /* Ajustes responsive */
  @media (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const CardImage = styled.img`
  width: 400px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  /* Ajustes responsive para pantallas más pequeñas */
  @media (max-width: 768px) {
    width: 100%;
    max-width: 400px;
  }
`;

const NavButton = styled.button`
  position: absolute;
  background-color: rgba(192, 192, 192, 0.3);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(124, 124, 124, 0.8);
  }

  &.left {
    left: -50px;
  }

  &.right {
    right: -50px;
  }

  /* Ajustes responsive */
  @media (max-width: 768px) {
    &.left {
      left: 0;
      transform: translateX(-50%);
    }

    &.right {
      right: 0;
      transform: translateX(50%);
    }
  }
`;

const TextContainer = styled.div`
  padding: 20px;
  max-width: 600px;

  /* Ajustes responsive */
  @media (max-width: 992px) {
    max-width: 100%;
    padding: 10px;
  }
`;

const Title = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 15px;

  /* Ajustes responsive */
  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: justify; 

  /* Ajustes responsive */
  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
`;

const BuyButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BuyButton = styled(Button)`
  background-color: #264466; /* Azul */
  border: none;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1d3b4e; /* Un tono más oscuro del azul */
  }
`;

const NFTCard = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const newImages = Array.from({ length: 54 }, () => getRandomImageUrl());
    setImages(newImages);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleBuyNFT = () => {
    navigate('/course/register');
  };

  return (
    <NFTCardContainer>
      {images.length > 0 && (
        <>
          <ImageSection>
            <NavButton className="left" onClick={handlePrev}>←</NavButton>
            <CardImage src={images[currentIndex].url} alt={images[currentIndex].collection} />
            <NavButton className="right" onClick={handleNext}>→</NavButton>
          </ImageSection>

          <TextContainer>
            <Title>{images[currentIndex].collection}</Title>
            <Description>
              {images[currentIndex].collection === 'TERRA COLECCIÓN' &&
                'Simboliza la estabilidad y la firmeza. La Tierra es el elemento que proporciona una base sólida para la vida y es considerado como un símbolo de seguridad y fundamento. En esta colección de NFTs aparecen frutos, plantas, animales, personajes, y todos guardan relación entre sí. Dentro de la colección se generan series como es la del “tomate”, con "Bloody Mery", sobrenombre popular que los ingleses dieron a la reina María I de Inglaterra, María Tudor. El caballo simboliza la libertad, la resistencia, la majestuosidad, la determinación y el coraje. Representa la capacidad de superar obstáculos y desafíos con determinación y vigor. El mono nos muestra el valor del tiempo como bien limitado. Otra ilustración que aparece son las hormigas que significan unión, trabajo en equipo, organización, paciencia y seguridad. Es importante saber que todo lo bueno llega a base del esfuerzo y la disciplina.'}
              {images[currentIndex].collection === 'AQUA COLECCIÓN' &&
                'Está inspirada en la riqueza de la diversidad de la especie de nuestro planeta. Sirenas empoderadas, todas de la misma naturaleza, pero con características distintas. Estos seres dan luz, con su variada personalidad única y diferente, a un mensaje claro de concienciación y respeto a la vida. Su propósito es promover la capacidad individual de cada ser humano de tomar decisiones en las iniciativas de sostenibilidad para proteger y defender nuestras aguas y la pluralidad de las especies. Esta colección está creada con figuras geométricas sobre un formato de sello para un mensaje. Representan a las personas de todas las partes del mundo con su elección de ser, cada una presenta atributos que las hace ser singulares, como son los rasgos, las telas de diferentes culturas, texto en distintos idiomas, números simbólicos… Y todos con un mismo propósito; proteger nuestros ríos, lagos, mares, océanos, y todas las especies.'}
              {images[currentIndex].collection === 'AER COLECCIÓN' &&
                'Es una representación visual de atmósferas internas de personas y los motivos que las generan, hablamos de la “Rueda de las Emociones”. Existen varias teorías sobre las emociones, según la “Rueda de las Emociones” se identifican ocho Emociones Básicas: Alegría, Confianza, Miedo, Sorpresa, Tristeza, Aversión, Ira y Anticipación. Y ocho Emociones Avanzadas: Amor, Vergüenza, Envidia, Empatía, Culpa, Orgullo, Nostalgia y Gratitud. Los colores utilizados para representar las emociones, sobre una esfera negra de fondo, son los que aparecen definidos en la “Rueda de las Emociones”. Estas emociones se clasifican en un círculo cromático para mostrar cómo una emoción puede llevar a otra, y pueden combinarse para formar emociones más complejas y sutiles. También se utilizan para su representación formas abstractas y figurativas. Los dibujos en color blanco escenifican algunos de los motivos o detonantes más comunes que desencadenan las emociones.'}
            </Description>
            <BuyButtonContainer>
              <CustomButton text="Comprar NFT" size="large" onClick={handleBuyNFT} />
            </BuyButtonContainer>
          </TextContainer>
        </>
      )}
    </NFTCardContainer>
  );
};

export default NFTCard;
