import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Sidebar from '../components/NavBar/toolsSidebar';
import AllTools from '../data/tools/allTools';
import Header from '../components/LoadPages/AcademicCollaborationCard';

// Componente para mostrar el título con efecto cristal y gradiente animado
const AnimatedTitle = ({ text }) => {
  return (
    <div style={styles.animatedTitleContainer}>
      {text.split('').map((char, index) => (
        <span key={index} style={styles.animatedLetter}>
          {char}
        </span>
      ))}
    </div>
  );
};

// Componente para la tarjeta animada con efecto de elevación al pasar el mouse
const AnimatedCard = ({ children }) => {
  const [hover, setHover] = useState(false);
  const cardStyle = {
    ...styles.card,
    transform: hover ? 'translateY(-10px)' : 'translateY(0)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    boxShadow: hover ? '0 8px 16px rgba(0, 0, 0, 0.2)' : styles.card.boxShadow,
    cursor: 'pointer',
  };
  return (
    <div
      style={cardStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
    </div>
  );
};

// Componente para el botón animado en el <a>
const AnimatedButton = ({ href, children }) => {
  const [hover, setHover] = useState(false);
  const buttonStyle = {
    ...styles.link,
    display: 'inline-block',
    padding: '8px 16px',
    borderRadius: '5px',
    backgroundColor: hover ? '#0056b3' : 'transparent',
    color: hover ? '#fff' : '#007bff',
    transform: hover ? 'scale(1.05)' : 'scale(1)',
    transition: 'all 0.3s ease',
  };
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      style={buttonStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
    </a>
  );
};

// Hook para detectar si el viewport es "mobile"
function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= breakpoint);
    };
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, [breakpoint]);

  return isMobile;
}

const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState(AllTools[0]);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Hook que detecta si estamos en mobile
  const isMobile = useIsMobile();

  // Filtramos los links de la categoría seleccionada en base al 'searchTerm'
  const filteredLinks = selectedCategory?.links?.filter((link) => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return (
      link.name.toLowerCase().includes(lowerCaseTerm) ||
      link.description.toLowerCase().includes(lowerCaseTerm)
    );
  });

  // Estilos principales. Ajustamos según si es mobile o no.
  const contentContainerStyle = {
    marginLeft: isSidebarCollapsed ? '80px' : '260px',
    width: isSidebarCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 260px)',
    transition: 'margin-left 0.3s ease-in-out, width 0.3s ease-in-out',
    padding: '20px',
    marginTop: '5vh',
    ...(isMobile && {
      marginLeft: '10vw',
      width: '95%',
    }),
  };

  // Estilo para envolver los headers y permitir que el texto se parta en varias líneas
  const headerWrapperStyle = {
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
  };

  return (
    <div className="layout" style={{ display: 'flex' }}>
      {/* Incluir estilos globales para animación del gradiente */}
      <style>
        {`
          @keyframes gradientWave {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
        `}
      </style>

      <Sidebar
        style={{
          marginTop: '15vh',
          height: '90vh',
          overflowY: 'auto',
          ...(isMobile && {
            marginTop: '20vh',
            height: '95vh',
          }),
        }}
        categories={AllTools}
        selectedCategory={selectedCategory}
        onSelect={setSelectedCategory}
        onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
      />

      <div className="content-container" style={contentContainerStyle}>
        <main className="content">
          {selectedCategory && (
            <>
              {/* Barra de búsqueda */}
              <div style={{ width: '100%', marginBottom: '20px' }}>
                <input
                  type="text"
                  placeholder="Buscar..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                />
              </div>

              {/* Header principal */}
              <div style={headerWrapperStyle}>
                <Header title={selectedCategory.title} subtitle="" />
              </div>

              {/* Contenido Markdown (info) con párrafos justificados y <li> alineados a la izquierda */}
              {selectedCategory.info && (
                <div style={{ marginBottom: '20px' }}>
                  <ReactMarkdown
                    components={{
                      p: ({ node, ...props }) => (
                        <p style={{ textAlign: 'justify', margin: '0 0 1em 0' }} {...props} />
                      ),
                      li: ({ node, ordered, ...props }) => (
                        <li style={{ textAlign: 'left', marginBottom: '0.5em' }} {...props} />
                      ),
                    }}
                  >
                    {selectedCategory.info}
                  </ReactMarkdown>
                </div>
              )}

              {/* Segundo header */}
              <div style={headerWrapperStyle}>
                <Header title="Sitios Legítimos de Criptomonedas" subtitle="" />
              </div>

              {/* Tarjetas filtradas */}
              {filteredLinks && filteredLinks.length > 0 && (
                <div style={styles.cardContainer}>
                  {filteredLinks.map((link, idx) => (
                    <AnimatedCard key={idx}>
                      <AnimatedTitle text={link.name} />
                      <div style={styles.cardContent}>
                        <p style={styles.cardText}>{link.description}</p>
                        <div style={styles.links}>
                          <AnimatedButton href={link.review_url}>
                            Review
                          </AnimatedButton>
                        </div>
                      </div>
                    </AnimatedCard>
                  ))}
                </div>
              )}

              {/* Si no hay resultados */}
              {filteredLinks && filteredLinks.length === 0 && (
                <p>No se encontraron resultados.</p>
              )}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

// Estilos para las tarjetas y el efecto cristal del título
const styles = {
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  card: {
    width: '100%',
    minHeight: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #ddd',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    textAlign: 'left', // Alineación del texto a la izquierda
    padding: '10px',
  },
  cardContent: {
    flex: 1,
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardText: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '8px',
  },
  links: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    color: '#007bff',
    fontWeight: 'bold',
  },

  // Contenedor con efecto cristal y gradiente animado
  animatedTitleContainer: {
    display: 'flex',
    justifyContent: 'flex-start', // Alineación a la izquierda
    alignItems: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    padding: '20px',
    marginBottom: '10px',
    background: 'linear-gradient(135deg, rgba(58, 180, 180, 0.6), rgba(79, 232, 255, 0.6), rgba(4, 104, 115, 0.6))',
    backgroundSize: '200% 200%',
    animation: 'gradientWave 5s ease infinite',
    borderRadius: '10px',
    backdropFilter: 'blur(10px)',
    color: '#000', // Cambiado de blanco a negro
  },
  animatedLetter: {
    margin: '0 2px',
  },
};

export default Home;
