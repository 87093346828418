import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoTicker from "../components/Trading/CryptoTicker";


const WalletProfile = () => {
  const navigate = useNavigate();
  const [activeCourse, setActiveCourse] = useState(null);
  const [showGif, setShowGif] = useState(false);

   // Estado para manejar el hover individual de cada botón
  const [hoveredButtons, setHoveredButtons] = useState({});

  const styles = {
    container: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "#ffffff",
      marginTop: "10vh",
    },
    containerCard: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "95%",
      padding: "20px",
      gap: "20px",
      flexWrap: "wrap",
    },
    courseCard: {
      position: "relative",
      width: "300px",
      height: "350px",
      background: "#0A5364",
      borderRadius: "15px",
      padding: "20px",
      textAlign: "center",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
    },

    courseCardImage: {
      width: "100%",
      height: "200px",
      borderRadius: "10px",
      objectFit: "cover",
    },
    courseCardText: {
      fontSize: "18px",
      color: "#fff",
      margin: "15px 0",
    },
    courseCardButton: {
      padding: "10px 20px",
      backgroundColor: "transparent",
      borderRadius: "10px",
      border: "2px solid #fff",
      color: "#fff",
      cursor: "pointer",
     
    },
    courseCardButtonHover: {
      backgroundImage: "linear-gradient(45deg, #f9b406,rgb(224, 162, 6))",
      color: "#fff",
      borderColor: "#fff",
    },
    
   
  };

  const courses = [
    { id: "CDB", name: "Dominando el Blockchain", img: "https://raw.githubusercontent.com/AppsDevsLeon/Revista_blockchain/refs/heads/main/imagenes/3cbd11ec-65a5-42bc-8cdb-babef2098fc2.jpg" },
    { id: "videos", name: "Clases Grabadas", img: "https://raw.githubusercontent.com/AppsDevsLeon/Revista_blockchain/refs/heads/main/imagenes/-a-futuristic-3d-rendered-visualization-of-blockch.png" },
    { id: "historia-del-dinero", name: "Historia del dinero", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(4).gif" },
    { id: "blockchain-en-la-industria", name: "Blockchain en la Industria", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(2).gif" },
    { id: "bitcoin-historia", name: "Bitcoin: Un Antes y un Después en la Historia del Dinero", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(3).gif" },
    { id: "criptomonedas-y-tokens", name: "Criptomonedas y Tokens", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(5).gif" },
    { id: "mineria-blockchain", name: "Minería del Blockchain", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(1).gif" },
    { id: "criptografia-blockchain", name: "Criptografía en Blockchain", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(3).gif" },
    { id: "criptografia-asimetrica", name: "Criptografía Asimétrica y las Claves Públicas y Privadas", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(5).gif" },
    { id: "explorando-hash-sha256", name: "Explorando Hash y SHA-256", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(2).gif" },
    { id: "arboles-merkle-blockchain", name: "Árboles de Merkle en Blockchain", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(1).gif" },
    { id: "introduccion-wallets-blockchain", name: "Introducción a Wallets con Blockchain", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(3).gif" },
    { id: "nft-blockchain", name: "NFT en Blockchain", img: "https://moneymorning.com/wp-content/blogs.dir/1/files/2021/04/shutterstock_1936167706-featured.jpg" },
    { id: "contratos-inteligentes", name: "Contratos Inteligentes", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(5).gif" },
    { id: "desarrollo-dapps-blockchain", name: "Desarrollo de DApps: Creando Aplicaciones en la Blockchain", img: "https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(3).gif" },
    { id: "aspectos-legales-blockchain", name: "Aspectos Legales y Regulatorios del Blockchain", img:"https://raw.githubusercontent.com/AppsDevsLeon/gif/refs/heads/main/1%20(1).gif" },
    { id: "finanzas-descentralizadas-defi", name: "Finanzas Descentralizadas (DeFi): Beneficios y Utilidades", img: "https://cdn.mos.cms.futurecdn.net/9EieB365kjRPqo9kL2TWBg.jpg" }
  ];

  const handleCourseClick = (courseId) => {
    setActiveCourse(courseId);
 
    // Se muestra el GIF y después de 1 segundo se redirige
    setTimeout(() => {
      navigate(`/course/${courseId}`);
    }, 1000);
  };

  return (
    <div style={styles.container}>
      <CryptoTicker />
      <div style={styles.containerCard}>
        {courses.map((course) => (
       
          <div key={course.id} style={styles.courseCard}>
            <img src={course.img} alt={course.name} style={styles.courseCardImage} />
            <h3 style={styles.courseCardText}>{course.name}</h3>
            <button
                   style={{
                    ...styles.courseCardButton,
                    ...(hoveredButtons[course.id] ? styles.courseCardButtonHover : {}),
                  }}
                  onMouseEnter={() =>
                    setHoveredButtons((prev) => ({ ...prev, [course.id]: true }))
                  }
                  onMouseLeave={() =>
                    setHoveredButtons((prev) => ({ ...prev, [course.id]: false }))
                  }
              onClick={() => handleCourseClick(course.id)}
            >
              Ir al Curso
            </button>
         
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletProfile;
