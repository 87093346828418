import React from "react";
import uvegLogo from "./logo-blanco.png"; // 🔹 Reemplaza con la ruta correcta de tu logo
import educaLogo from "./Escudo-IE.png"; // 🔹 Logo adicional si lo necesitas
import coachBlockchainLogo from "./logo1.png"; // 🔹 Logo de Coach Blockchain

const BotPage = () => {
  const goToBot = () => {
    window.open(
      "https://chatgpt.com/g/g-67c61a41c8088191abfbbf403ebfe875-coach-blockchain",
      "Chat Inteligente",
      "width=800,height=600,left=200,top=100,noopener,noreferrer"
    );
  };

  return (
    <div
      style={{
        textAlign: "center",
        padding: "50px",
        backgroundColor: "#0a5364", // 💠 Azul turquesa
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
      }}
    >
      {/* Logos en la parte superior */}
      <div style={{ marginBottom: "20px", display: "flex", alignItems: "center", gap: "15px" }}>
        <img src={uvegLogo} width="60" height="60" alt="UVEG Logo" />
        <img src={educaLogo} width="60" height="60" alt="Educa Logo" />
        <img src={coachBlockchainLogo} width="120" height="60" alt="Coach Blockchain Logo" />
      </div>

      <h1 style={{ fontSize: "2.5em", marginBottom: "20px" }}>
         Chat Inteligente
      </h1>
      <p style={{ fontSize: "1.2em", maxWidth: "600px", textAlign: "center", marginBottom: "20px" }}>
        Bienvenido a nuestro **Chat Inteligente**.  
        Este bot ha sido entrenado para responder todas tus dudas sobre **Blockchain, Finanzas Descentralizadas (DeFi), Smart Contracts y NFTs**.  
        **Haz tu pregunta y obtén respuestas precisas.**
      </p>

      <button
        onClick={goToBot}
        style={{
          padding: "15px 30px",
          fontSize: "18px",
          fontWeight: "bold",
          color: "#0a5364", // Azul turquesa invertido
          backgroundColor: "#fff", // Botón blanco
          border: "2px solid #fff",
          borderRadius: "10px",
          cursor: "pointer",
          transition: "0.3s",
          boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.2)",
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#f0f0f0")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#fff")}
      >
        Abrir Chat Inteligente
      </button>
    </div>
  );
};

export default BotPage;
