import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const coins = [
  { id: 'bitcoin', label: 'Bitcoin (BTC)' },
  { id: 'ethereum', label: 'Ethereum (ETH)' },
];

const intervals = [
  { label: '1D', value: '1' },
  { label: '1M', value: '30' },
];

const CryptoChart = () => {
  const [selectedCoin, setSelectedCoin] = useState('bitcoin');
  const [period, setPeriod] = useState('30');
  const [chartData, setChartData] = useState([]);
  const [price, setPrice] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError('');
        setChartData([]);
        setPrice(null);

        // Precio actual
        const resPrice = await fetch(
          `https://api.coingecko.com/api/v3/simple/price?ids=${selectedCoin}&vs_currencies=usd`
        );
        const dataPrice = await resPrice.json();
        setPrice(dataPrice[selectedCoin]?.usd);

        // Datos históricos
        const resChart = await fetch(
          `https://api.coingecko.com/api/v3/coins/${selectedCoin}/market_chart?vs_currency=usd&days=${period}`
        );
        const chartJson = await resChart.json();

        if (!chartJson.prices) throw new Error('No hay datos disponibles.');

        const formatted = chartJson.prices.map(([timestamp, value]) => {
          const date = new Date(timestamp);
          return {
            date:
              period === '1'
                ? `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`
                : `${date.getMonth() + 1}/${date.getDate()}`,
            price: parseFloat(value.toFixed(2)),
          };
        });

        setChartData(formatted);
      } catch (err) {
        console.error('Error:', err);
        setError('No se pudo cargar la gráfica.');
      }
    };

    fetchData();
  }, [selectedCoin, period]);

  return (
    <div className="max-w-5xl mx-auto p-4">
      <h2 className="text-3xl font-bold mb-2">
        {coins.find((c) => c.id === selectedCoin)?.label}
      </h2>
      <p className="text-2xl text-green-600 mb-4">
        {price ? `$${price.toLocaleString()}` : 'Cargando precio...'}
      </p>

      <div className="flex flex-wrap gap-2 mb-4">
        {intervals.map((i) => (
          <button
            key={i.value}
            onClick={() => setPeriod(i.value)}
            className={`px-4 py-1 rounded-full text-sm ${
              period === i.value
                ? 'bg-black text-white'
                : 'bg-gray-200 text-black'
            }`}
          >
            {i.label}
          </button>
        ))}
      </div>

      <select
        className="p-3 border rounded w-full mb-4"
        value={selectedCoin}
        onChange={(e) => setSelectedCoin(e.target.value)}
      >
        {coins.map((coin) => (
          <option key={coin.id} value={coin.id}>
            {coin.label}
          </option>
        ))}
      </select>

      {error ? (
        <p className="text-red-600 text-center">{error}</p>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="greenArea" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00b300" stopOpacity={0.5} />
                <stop offset="95%" stopColor="#00b300" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="price"
              stroke="#00b300"
              fill="url(#greenArea)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default CryptoChart;
