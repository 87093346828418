import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/NavBar/Sidebar";
import MarkdownProcessor from "../components/Reusable/MarkdownCourse";
import TitleSection from "../components/Reusable/TitleSection";
import ContentSection from "../components/Reusable/CourseContent";
import { rawFiles } from "../data/rawFiles";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Menu from "../components/Reusable/Menu";



const ExportButton = ({ exportToPDF }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const baseStyle = {
    marginTop: "1rem",
    padding: "0.5rem 1.5rem",
    border: "2px solid #4A90E2",
    borderRadius: "8px",
    backgroundColor: "transparent",
    color: "#4A90E2",
    cursor: "pointer",
    fontSize: "16px",
    transition: "all 0.3s ease",
  };

  const hoverActiveStyle = {
    backgroundColor: "#4A90E2",
    color: "#ffffff",
  };

  const combinedStyle =
    isHovered || isActive ? { ...baseStyle, ...hoverActiveStyle } : baseStyle;

  return (
    <button
      onClick={exportToPDF}
      style={combinedStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setIsActive(false);
      }}
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
    >
      Exportar Todo a PDF
    </button>
  );
};

const Home = () => {
  const [selectedDay, setSelectedDay] = useState("day1");
  const [language, setLanguage] = useState("es");
  const [sections, setSections] = useState([]);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const contentRef = useRef(null);

  // Convertimos las secciones en temas para el menú
  const topics = sections.map((section, index) => ({
    id: section.id || index,
    title: section.title,
  }));
  

  useEffect(() => {
    document.title = "Formación - Nuestra Plataforma";
  }, []);

  // Detecta cambios en el tamaño de la ventana
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const availableDays = Object.keys(rawFiles)
    .filter((key) => key.endsWith(`_${language}`))
    .map((key) => key.replace(`_${language}`, ""));

  const selectedFile = rawFiles[`${selectedDay}_${language}`];

  const handleContentProcessed = (processedSections) => {
    setSections(processedSections);
  };

  const exportToPDF = async () => {
    const content = contentRef.current;
    if (!content) return;
    try {
      const canvas = await html2canvas(content, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const imgWidth = pdfWidth;
      const imgHeight = (canvasHeight * imgWidth) / canvasWidth;

      let position = 0;
      let heightLeft = imgHeight;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }
      pdf.save(`Contenido_${selectedDay}_${language}.pdf`);
    } catch (error) {
      console.error("Error al generar el PDF", error);
    }
  };

  // Ajusta el estilo del contenedor de contenido según el ancho de pantalla y el estado del sidebar
  const getContentStyle = () => {
    if (windowWidth < 768) {
      return {
        marginLeft: "0",
        width: "100%",
        padding: "20px",
        maxWidth: "100%",
        marginTop: "5vh",
      };
    } else {
      return {
        marginLeft: isSidebarCollapsed ? "8vw" : "20vw",
        width: isSidebarCollapsed ? "90vw" : "85vw",
        transition: "margin-left 0.3s ease-in-out, width 0.3s ease-in-out",
        padding: "20px",
        maxWidth: "100%",
        marginTop: "5vh",
      };
    }
  };

  return (
    <div className="layout" style={{ display: "flex", flexDirection: "column" }}>
      <div className="flex" style={{ flexGrow: 1 }}>
        <Sidebar
          days={availableDays}
          selectedDay={selectedDay}
          onSelect={setSelectedDay}
          onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        />

        <div className="content-container" style={getContentStyle()}>
          <main className="content" ref={contentRef}>
            <div className="relative inline-block text-left mb-4">
              {/* Menú superior */}
              <Menu topics={topics} isSidebarCollapsed={isSidebarCollapsed} />
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="mt-5 px-4 py-2 border border-gray-300 bg-white text-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-lightblue-500 focus:border-blue-500 cursor-pointer"
              >
                <option value="es">🇪🇸 Español</option>
                <option value="en">🇬🇧 English</option>
              </select>
            </div>
            <MarkdownProcessor url={selectedFile} onContentProcessed={handleContentProcessed} />
            {sections.length > 0 && (
              <div>
                {sections.map((section, index) => (
                  <div key={index} style={{ marginBottom: "4vh", scrollMarginTop: "80px" }}>
                    <TitleSection title={section.title} id={`topic-${section.id || index}`} />
                    <ContentSection sections={[section]} language={language} />
                  </div>
                ))}



              </div>
            )}

          </main>
          <ExportButton exportToPDF={exportToPDF} />
        </div>
      </div>
    </div>
  );
};

export default Home;
