import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaBook } from "react-icons/fa";
import styled from 'styled-components';
import videoData from '../../data/videoData.json';

const SidebarContainer = styled.div`
  position: fixed;
  top: 4.5vw;
  left: 0;
  height: 90vh;
  width: ${(props) => (props.isCollapsed ? '80px' : '260px')};
  background: #fff;
  color: #333;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  z-index: 1000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 768px) {
    top: 10vh;
    height: calc(100vh - 10vh);
  }
`;

const SidebarToggle = styled.div`
  position: absolute;
  right: -10px;
  top: 20px;
  background: white;
  color: #007cc2;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f0f0f0;
  }
`;

const SidebarHeader = styled.div`
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
`;

const SidebarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  h3 {
    color: #007cc2;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
  }
`;

const SidebarMenu = styled.nav`
  flex-grow: 1;
  padding: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Alinea el texto a la izquierda */
    text-decoration: none;
    color: #333;
    padding: 12px;
    font-size: 16px;
    transition: 0.3s;

    &:hover {
      background: #f1f1f1;
      border-radius: 5px;
    }
  }
`;

const MenuItem = styled.li`
  a {
    background: ${(props) => (props.active ? '#e6f4f8' : 'transparent')};
    border-radius: 5px;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    color: ${(props) => (props.active ? '#007cc2' : '#333')};
  }
`;

const Pagination = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 14px;
  border-top: 1px solid #ccc;
  background: #f9f9f9;
`;

const Sidebar = ({ onSelect }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(videoData.videos.length / itemsPerPage);
  const visibleVideos = videoData.videos.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Auto-seleccionar el primer video al montar el componente
  useEffect(() => {
    if (videoData.videos && videoData.videos.length > 0) {
      setSelectedVideo(videoData.videos[0]);
      onSelect(videoData.videos[0]);
    }
  }, [onSelect]);

  useEffect(() => {
    function checkMobile() {
      setIsCollapsed(window.innerWidth <= 768);
    }
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <SidebarContainer isCollapsed={isCollapsed}>
      <SidebarToggle onClick={handleToggle}>
        {isCollapsed ? <FiChevronRight size={24} /> : <FiChevronLeft size={24} />}
      </SidebarToggle>

      <SidebarHeader>
        <SidebarLogo>
          <FaBook size={26} color="#007cc2" />
          {!isCollapsed && <h3>CDB</h3>}
        </SidebarLogo>
      </SidebarHeader>

      <SidebarMenu>
        <ul>
          {visibleVideos.map((video, index) => (
            <MenuItem key={index} active={selectedVideo === video}>
              <a
                href="#"
                onClick={() => {
                  setSelectedVideo(video);
                  onSelect(video);
                }}
              >
                {isCollapsed
                  ? index + 1 + (currentPage - 1) * itemsPerPage
                  : video.title}
              </a>
            </MenuItem>
          ))}
        </ul>
      </SidebarMenu>

      {totalPages > 1 && !isCollapsed && (
        <Pagination>
          <button
            onClick={() =>
              setCurrentPage((p) => Math.max(1, p - 1))
            }
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          &nbsp; Página {currentPage} de {totalPages} &nbsp;
          <button
            onClick={() =>
              setCurrentPage((p) => Math.min(totalPages, p + 1))
            }
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </Pagination>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
