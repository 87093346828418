import React, { useEffect, useRef } from "react";

const CryptoTicker = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Limpiar contenido previo
    if (containerRef.current) {
      containerRef.current.innerHTML = "";
    }

    // Crear y configurar el script del widget de TradingView
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-tickers.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        { title: "BTC/USD", proName: "BITFINEX:BTCUSD" },
        { title: "ETH/USD", proName: "BITFINEX:ETHUSD" },
        { title: "LTC/USD", proName: "BITFINEX:LTCUSD" },
        { title: "XRP/USD", proName: "BITFINEX:XRPUSD" },
        { title: "EOS/USD", proName: "BITFINEX:EOSUSD" },
        { title: "XLM/USD", proName: "BITFINEX:XLMUSD" },
        { title: "ADA/USD", proName: "BITFINEX:ADAUSD" },
        { title: "TRX/USD", proName: "BITFINEX:TRXUSD" },
        { title: "IOTA/USD", proName: "BITFINEX:IOTUSD" },
        { title: "XMR/USD", proName: "BITFINEX:XMRUSD" },
        { title: "NEO/USD", proName: "BITFINEX:NEOUSD" },
        { title: "ZEC/USD", proName: "BITFINEX:ZECUSD" },
        { title: "DOGE/USD", proName: "COINBASE:DOGEUSD" },
        { title: "BNB/USD", proName: "COINBASE:BNBUSD" },
        { title: "ETC/USD", proName: "COINBASE:ETCUSD" },
        { title: "VET/USD", proName: "COINBASE:VETUSD" },
        { title: "MKR/USD", proName: "COINBASE:MKRUSD" },
        { title: "SOL/USD", proName: "COINBASE:SOLUSD" }
      ],
      locale: "en"
    });

    // Agregar el script al contenedor
    containerRef.current.appendChild(script);

    // Opcional: clonar el iframe para simular un ticker infinito
    script.onload = () => {
      setTimeout(() => {
        const iframe = containerRef.current.querySelector("iframe");
        if (iframe) {
          const clone = iframe.cloneNode(true);
          containerRef.current.appendChild(clone);
        }
      }, 2000);
    };
  }, []);

  return (
    <div className="ticker-container">
      <style>{`
        .ticker-container {
          width:95%;
          overflow: hidden;
          padding: 5px;
        }
        .ticker-header {
          color: #fff;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
        }
        .ticker-canvas {
          width: calc((200px * 20) + 2px); /* Se calcula para 20 cryptos */
          animation: ticker-canvas 20s linear infinite;
          margin-top: 20px;
        }
        .ticker-canvas:hover {
          animation-play-state: paused;
        }
        @keyframes ticker-canvas {
          0% {
            transform: translate3d(0, 0, 0);
            visibility: visible;
          }
          100% {
            transform: translate3d(-66.66%, 0, 0);
          }
        }
        .tradingview-widget-container {
          position: relative;
        }
        .tradingview-widget-container iframe {
          position: absolute;
          top: 0;
          /* Se elimina el filtro para conservar los colores originales */
          transform: scale(1, 1);
          transform-origin: top left;
          margin-right: 30px; /* Espacio entre cada cripto */
        }
        .tradingview-widget-container iframe:nth-of-type(2) {
          left: 66.66%;
        }
      `}</style>
      <div className="ticker-canvas">
        <div className="tradingview-widget-container" ref={containerRef}>
          <div className="tradingview-widget-container__widget"></div>
        </div>
      </div>
    </div>
  );
};

export default CryptoTicker;
