import React from 'react';

let counter = 0;

const TitleSection = ({ title, id }) => {


  return (
    <div id={id} className="title-section">
      <h1> {title}</h1>
      <div className="underline" />

      <style jsx>{`
        .title-section {
          background-color: white;
          padding: 2rem 1rem;
          text-align: center;
        }

        h1 {
          color: #333;
          font-size: 2.5rem;
          font-weight: bold;
          margin: 0;
        }

        .underline {
          margin-top: 1rem;
          width: 80px;
          height: 4px;
          background-color: #ccc;
          margin-left: auto;
          margin-right: auto;
          border-radius: 2px;
        }

        @media (max-width: 768px) {
          h1 {
            font-size: 2rem;
          }

          .underline {
            width: 60px;
            height: 3px;
          }
        }

        @media (max-width: 480px) {
          h1 {
            font-size: 1.5rem;
          }

          .underline {
            width: 50px;
            height: 2px;
          }
        }
      `}</style>
    </div>
  );
};

export default TitleSection;
