import React from 'react';
import ReactMarkdown from 'react-markdown';

const ContentSection = ({ sections }) => {
  // Definimos los renderizadores personalizados
  const customComponents = {
    // Imágenes al 50% y con margen
    img: ({ ...props }) => (
      <img
        {...props}
        style={{
          width: '50%',
          display: 'block',
          margin: '1rem auto',
        }}
        alt={props.alt || ''}
      />
    ),
    // Enlaces que abren en nueva pestaña
    a: ({ node, ...props }) => {
      return (
        <a
          {...props}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: 'blue' }}
        >
          {props.children}
        </a>
      );
    },
    // Párrafos y encabezados con el mismo tamaño de fuente
    p: ({ node, ...props }) => {
      return (
        <p style={{ fontSize: '16px', lineHeight: '1.5', margin: '0.5rem 0' }}>
          {props.children}
        </p>
      );
    },
    h1: ({ node, ...props }) => {
      return (
        <h1 style={{ fontSize: '16px', lineHeight: '1.5', margin: '0.5rem 0', fontWeight: 'bold' }}>
          {props.children}
        </h1>
      );
    },
    h2: ({ node, ...props }) => {
      return (
        <h2 style={{ fontSize: '16px', lineHeight: '1.5', margin: '0.5rem 0', fontWeight: 'bold' }}>
          {props.children}
        </h2>
      );
    },
    h3: ({ node, ...props }) => {
      return (
        <h3 style={{ fontSize: '16px', lineHeight: '1.5', margin: '0.5rem 0', fontWeight: 'bold' }}>
          {props.children}
        </h3>
      );
    },
  };

  return (
    <>
      {sections.map(({ id, content }) => (
        <div
          key={id}
          id={id}
          style={{
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <ReactMarkdown components={customComponents}>
            {content.join('\n')}
          </ReactMarkdown>
        </div>
      ))}
    </>
  );
};

export default ContentSection;
