import * as THREE from 'three';
import { Canvas, useLoader } from '@react-three/fiber';
import { Environment, OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three';
import { useMemo } from 'react';

const collection1BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/AquaCollection/main/';
const collection2BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/ImagenesEar/main/';
const collection3BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/TerraImages/main/';

const getRandomImageUrls = (count) => {
  return Array.from({ length: count }).map(() => {
    const totalWeight = 50 + 40 + 10;
    const random = Math.random() * totalWeight;
    let id, url;

    if (random < 50) {
      id = Math.floor(Math.random() * (10280 - 10181 + 1)) + 10181;
      url = `${collection3BaseUrl}${id}.png`;
    } else if (random < 50 + 40) {
      id = Math.floor(Math.random() * (140 - 47 + 1)) + 47;
      url = `${collection1BaseUrl}${id}.png`;
    } else {
      id = Math.floor(Math.random() * (97 - 1 + 1)) + 1;
      url = `${collection2BaseUrl}${id}.png`;
    }
    return url;
  });
};

export default function Gallery() {
  const imageUrls = useMemo(() => getRandomImageUrls(50), []);
  const textures = useLoader(TextureLoader, imageUrls); // ✅ Se llama correctamente el hook aquí

  return (
    <Canvas camera={{ position: [0, 15, 30] }}>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 20, 10]} angle={0.3} penumbra={1} />
      <Environment preset="city" />

      {/* Piso mucho más grande */}
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.1, 0]}>
        <planeGeometry args={[50, 50]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>

      {/* Pared mucho más grande */}
      <mesh position={[0, 10, -5]}>
        <planeGeometry args={[50, 20]} />
        <meshStandardMaterial color="#C0C0C0" />
      </mesh>

      {/* Techo agregado */}
      <mesh rotation={[Math.PI / 2, 0, 0]} position={[0, 20, 0]}>
        <planeGeometry args={[50, 50]} />
        <meshStandardMaterial color="#A9A9A9" />
      </mesh>

      {/* Cuadros en toda la pared */}
      {textures.map((texture, index) => {
        const row = Math.floor(index / 10);
        const col = index % 10;
        return (
          <mesh key={`${row}-${col}`} position={[-20 + col * 4.5, 8 - row * 4, -4.9]}>
            <planeGeometry args={[4, 3]} />
            <meshStandardMaterial map={texture} />
          </mesh>
        );
      })}

      <OrbitControls />
    </Canvas>
  );
}
