import React from 'react';
import { NavLink } from "react-router-dom"; // Usamos NavLink en lugar de Link para resaltar la ruta activa
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import uvegLogo from './logo-blanco.png';
import educaLogo from './Escudo-IE.png';
import coachBlockchainLogo from './logo1.png';
import styles from './Navigation.module.css';

{/** const Navigation = ({ web3Handler, account }) => {*/}
    
const Navigation = ({  }) => {
    return (
        <Navbar expand="lg" bg="primary" variant="dark" className={styles.navbar}>
            <Container>
                <Navbar.Brand>
                    <img src={uvegLogo} width="40" height="40" className={styles.logo} alt="UVEG Logo" />
                    <img src={educaLogo} width="40" height="40" className={`${styles.logo} mx-3`} alt="Educa Logo" />
                    <img src={coachBlockchainLogo} width="100" height="40" className={styles.logo} alt="Coach Blockchain Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar navbar-dark bg-primary" />
                <Navbar.Collapse id="navbar navbar-dark bg-primary">
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to="/" className={styles.navLink} activeClassName={styles.activeNavLink}>
                            Inicio
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/course/NFTs" className={styles.navLink} activeClassName={styles.activeNavLink}>
                            NFTs
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/course/blockchain" className={styles.navLink} activeClassName={styles.activeNavLink}>
                            Formación
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/course/roadmap" className={styles.navLink} activeClassName={styles.activeNavLink}>
                            Ruta
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/course/about-us" className={styles.navLink} activeClassName={styles.activeNavLink}>
                        Conócenos
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/course/guia" className={styles.navLink} activeClassName={styles.activeNavLink}>
                            Guía
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/course/acceso" className={styles.navLink} activeClassName={styles.activeNavLink}>
                            Acesso
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/course/ia-respuestas" className={styles.navLink} activeClassName={styles.activeNavLink}>
                            FAQ Inteligente
                        </Nav.Link>
                        
            
                       
                    </Nav>
                    <Nav>
                      {/** {account ? (
                            <Nav.Link
                                href={`https://etherscan.io/address/${account}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${styles.navButton} mx-4`}>
                                <Button variant="outline-light" className={styles.accountButton}>
                                    {account}
                                </Button>
                            </Nav.Link>
                        ) : (
                            <> */} 
                                <NavLink to="/course/register" className="mx-2">
                                    <Button variant="outline-light" className={styles.connectButton}>
                                        Iniciar
                                    </Button>
                                </NavLink>
                             
                              {/**  <Button onClick={web3Handler} variant="outline-light" className={`${styles.connectButton} mx-2`}>
                                    Connect Wallet
                                </Button> 
                               
                            </>  
                        )}*/}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
