// Archivo: src/data/tools/allCategories.js
// Cárgalo con el nombre que gustes, por ejemplo "allCategories.js"


import cryptocurrencyExchanges from './cryptocurrency-exchanges.json';
import cryptocurrencyGambling from './cryptocurrency-gambling.json';
import cryptocurrencyAccounting from './cryptocurrency-accounting.json';
import cryptocurrencyPayments from './cryptocurrency-payments.json';
import cryptocurrencyGraphs from './cryptocurrency-graphs.json';
import cryptocurrencyMining from './cryptocurrency-mining.json';
import cryptocurrencyExchangeFees from './cryptocurrency-exchange-fees.json';
import cryptocurrencyNews from './cryptocurrency-news.json';
import cryptocurrencyP2P from './cryptocurrency-p2p.json';
import cryptocurrencyPriceTrackers from './cryptocurrency-price-trackers.json';
import btcToUsd from './btc-to-usd.json';
import cryptocurrencyDebitCard from './cryptocurrency-debit-card.json';
import cryptocurrencyWalletAppForAndroid from './cryptocurrency-wallet-app-for-android.json';
import cryptocurrencyTracking from './cryptocurrency-tracking.json';
import miningPools from './mining-pools.json';
import bitcoinMiningSoftware from './bitcoin-mining-software.json';
import cryptocurrencyWallets from './cryptocurrency-wallets.json';
import hardwareWallet from './hardware-wallet.json';
import altcoinWallet from './altcoin-wallet.json';
import cryptocurrencyWebHosting from './cryptocurrency-web-hosting.json';
import paperWallet from './paper-wallet.json';
import cryptocurrencyLoans from './cryptocurrency-loans.json';
import bitcoinOnYoutube from './bitcoin-on-youtube.json';
import cryptocurrencyCalendar from './cryptocurrency-calendar.json';
import cryptocurrencyAppsForAndroid from './cryptocurrency-apps-for-android.json';
import cryptocurrencyMarginTrading from './cryptocurrency-margin-trading.json';
import cryptocurrencyReddit from './cryptocurrency-reddit.json';
import bitcoinCfdTrading from './bitcoin-cfd-trading.json';
import bitcoinMixerSites from './bitcoin-mixer-sites.json';
import desktopWallet from './desktop-wallet.json';
import cryptocurrencyWalletForIos from './cryptocurrency-wallet-for-ios.json';
import cryptocurrencyStatistics from './cryptocurrency-statistics.json';
import airdrops from './airdrops.json';
import cryptocurrencyExchangeData from './cryptocurrency-exchange-data.json';
import cryptocurrencyForums from './cryptocurrency-forums.json';
import cryptocurrencyNewsAggregators from './cryptocurrency-news-aggregators.json';
import altcoinExchanges from './altcoin-exchanges.json';
import cryptocurrencyBlogs from './cryptocurrency-blogs.json';
import bitcoinDocumentary from './bitcoin-documentary.json';
import cryptocurrencyTradingBots from './cryptocurrency-trading-bots.json';
import upcomingIcos from './upcoming-icos.json';
import tradingviewBitcoin from './tradingview-bitcoin.json';
import cryptocurrencyStaking from './cryptocurrency-staking.json';
import cryptocurrencyRisks from './cryptocurrency-risks.json';
import cryptocurrencyInvestments from './cryptocurrency-investments.json';
import cryptocurrencyRegulations from './cryptocurrency-regulations.json';
import cryptocurrencySecurity from './cryptocurrency-security.json';
import cryptocurrencyLending from './cryptocurrency-lending.json';
import cryptocurrencyEducation from './cryptocurrency-education.json';
import cryptocurrencyBlockchain from './cryptocurrency-blockchain.json';
import cryptocurrencySmartContracts from './cryptocurrency-smart-contracts.json';
import cryptocurrencyStablecoins from './cryptocurrency-stablecoins.json';
import cryptocurrencyTokenization from './cryptocurrency-tokenization.json';
import cryptocurrencyNfts from './cryptocurrency-nfts.json';
import cryptocurrencyWalletSecurity from './cryptocurrency-wallet-security.json';
import cryptocurrencyMarketplaces from './cryptocurrency-marketplaces.json';

// Combina todos los objetos (uno por cada JSON) en un solo array.
// IMPORTANTE: asegúrate de que cada .json tenga una estructura similar
// y un "id" único para evitar problemas al mapearlos.
const AllTools = [
  cryptocurrencyExchanges,
{/**  cryptocurrencyGambling,
  cryptocurrencyAccounting,
  cryptocurrencyPayments,
  cryptocurrencyGraphs,
  cryptocurrencyMining,
  cryptocurrencyExchangeFees,
  cryptocurrencyNews,
  cryptocurrencyP2P,
  cryptocurrencyPriceTrackers,
  btcToUsd,
  cryptocurrencyDebitCard,
  cryptocurrencyWalletAppForAndroid,
  cryptocurrencyTracking,
  miningPools,
  bitcoinMiningSoftware,
  cryptocurrencyWallets,
  hardwareWallet,
  altcoinWallet,
  cryptocurrencyWebHosting,
  paperWallet,
  cryptocurrencyLoans,
  bitcoinOnYoutube,
  cryptocurrencyCalendar,
  cryptocurrencyAppsForAndroid,
  cryptocurrencyMarginTrading,
  cryptocurrencyReddit,
  bitcoinCfdTrading,
  bitcoinMixerSites,
  desktopWallet,
  cryptocurrencyWalletForIos,
  cryptocurrencyStatistics,
  airdrops,
  cryptocurrencyExchangeData,
  cryptocurrencyForums,
  cryptocurrencyNewsAggregators,
  altcoinExchanges,
  cryptocurrencyBlogs,
  bitcoinDocumentary,
  cryptocurrencyTradingBots,
  upcomingIcos,
  tradingviewBitcoin,
  cryptocurrencyStaking,
  cryptocurrencyRisks,
  cryptocurrencyInvestments,
  cryptocurrencyRegulations,
  cryptocurrencySecurity,
  cryptocurrencyLending,
  cryptocurrencyEducation,
  cryptocurrencyBlockchain,
  cryptocurrencySmartContracts,
  cryptocurrencyStablecoins,
  cryptocurrencyTokenization,
  cryptocurrencyNfts,
  cryptocurrencyWalletSecurity,
  cryptocurrencyMarketplaces*/} 
];

export default AllTools;
