import React from "react";
import IPFSVideoPlayer from "./IPFSVideoPlayer";

const videos = [
  {
    id: 1,
    title: "Guía en Video",
    cid: "bafybeia37wsc6peibhtry2zcmnhunucwdpptb6ah2kfgwkvt63tfqzf3gi",
    gateway: "https://gateway.pinata.cloud/ipfs/"
  }
];

const VideoList = () => {
  return (
    <div className="p-4">
      <h1 className="text-xl font-bold text-center mb-4">Videos desde IPFS</h1>
      {videos.length === 0 ? (
        <p className="text-blue-500">⏳ Cargando videos...</p>
      ) : (
        videos.map((video) => (
          <div key={video.id} className="mb-6">
            <h2 className="text-lg font-semibold">{video.title}</h2>
            <IPFSVideoPlayer cid={video.cid} gateway={video.gateway} />
          </div>
        ))
      )}
    </div>
  );
};

export default VideoList;
