import React from "react";

const IframeRenderer = ({ url }) => {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden", position: "relative" }}>
      <iframe
        title="Public Page"
        src={url}
        style={{
          width: "100%",
          height: "120vh", // Se hace más grande para que la parte visible se recorte mejor
          border: "none",
          position: "relative",
          top: "-12vh", // Ajusta este valor según la cantidad de ocultamiento necesario
        }}
      />
    </div>
  );
};

export default IframeRenderer;
