import React, { useState, useEffect } from 'react';
import TitleSection from '../components/Reusable/TitleSection';
import ContentSection from '../components/Reusable/MDContectSecition';
import MarkdownProcessor from '../components/Reusable/MarkdownProcessor';
import FoxViewer from '../components/FiberElements/FoxViewer'

const Home = () => {
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    document.title = "Guía - Nuestra Plataforma";
  }, []);

  const handleContentProcessed = (processedSections) => {
    setSections(processedSections);
    if (processedSections.length > 0) {
      setSelectedSection(processedSections[0].id);
    }
  };

  const handleMenuClick = (id) => {
    setSelectedSection(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <FoxViewer />
      {/* Carga y procesa el contenido del Markdown */}
      <MarkdownProcessor
        url="https://raw.githubusercontent.com/AppsDevsLeon/metamask-start-guide/refs/heads/main/readme_es.md"
        onContentProcessed={handleContentProcessed}
      />

      {sections.length > 0 && (
        <>


          {/* Renderizar cada sección con `TitleSection` y `ContentSection` */}
          {sections.map(({ id, title, content }) => (
            <div key={id} id={id}>
              <TitleSection title={title} />
              <ContentSection sections={[{ id, title, content }]} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Home;
