import React, { useRef } from 'react';
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";



const RotatingLight = ({ position, color, speed }) => {
  const lightRef = useRef();

  useFrame(() => {
    if (lightRef.current) {
      lightRef.current.position.x = position[0] * Math.cos(speed * Date.now() * 0.001);
      lightRef.current.position.z = position[2] * Math.sin(speed * Date.now() * 0.001);
    }
  });

  return <pointLight ref={lightRef} color={color} intensity={1} />;
};

const SphereToPlaneWithRotation = () => {
  const sphereRef = useRef();

  const texture = new THREE.TextureLoader().load("https://raw.githubusercontent.com/coachblockchain/Images/main/collage.png");
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  const textureColumns = 6;
  const textureRows = 4;

  useFrame(() => {
    if (sphereRef.current) {
      sphereRef.current.material.map.repeat.set(textureColumns, textureRows);
      sphereRef.current.rotation.y += 0.005;
    }
  });

  return (
    <mesh ref={sphereRef} position={[1, 0, 0]} onClick={() => { }}>
      <sphereGeometry args={[2.8, 32, 50]} />
      <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
    </mesh>
  );
};

const SphereScene = () => {
  return (
    <div style={{ width: '100%', height: '50vh', position: 'relative' }}>
      {/* Texto grande en la parte inferior centrado
      <TextOverlay
        title="La Revolución del Arte Digital"
        subtitle="Transformando la Creatividad Digital a través de la Tecnología."
        style={{
          top: '20px',  // Aparece en la parte superior
          width: '100%',  // Abarca todo el ancho del contenedor
          textAlign: 'center',  // Centra el texto

        }}
      /> */}

      {/* Contenedor del canvas con fondo animado */}
      <div style={{
        width: '100%',
        height: '50vh',
        position: 'relative',
        background: 'linear-gradient(135deg,#fff,rgb(255, 255, 255))',
        overflow: 'hidden'
      }}>
        {/* Fondo animado */}
        <div style={{
          width: '200%',
          height: '200%',
          background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.3) 100%)',
          animation: 'moveBackground 8s infinite alternate',
          position: 'absolute',
          top: '-50%',
          left: '-50%',
          zIndex: 0
        }} />


        {/* Canvas con la escena */}
        <Canvas camera={{ fov: 75, position: [0, 0, 5] }}>
          <ambientLight intensity={0.5} />
          {/* Luces animadas detrás de la esfera */}
          <RotatingLight position={[10, 10, 0]} color={'#ff0040'} speed={0.5} />
          <RotatingLight position={[-10, 10, 0]} color={'#0040ff'} speed={0.3} />
          <RotatingLight position={[0, -10, 10]} color={'#00ff40'} speed={0.7} />

          {/* Ajuste en la posición y escala */}
          <group position={[-1, 0, 0]}>
            <SphereToPlaneWithRotation scale={[0.5, 0.5, 0.5]} />
          </group>
        </Canvas>

      </div>

      {/* Keyframes para animar el fondo */}
      <style>{`
  @keyframes moveBackground {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }
`}</style>


    </div>
  );
};

export default SphereScene;
