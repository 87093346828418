import React from 'react';
import { Link } from 'react-router-dom'; // Importa Link
import styles from './Footer.module.css';
import coachLogo from '../NavBar/logo1.png';
import educaLogo from '../NavBar/Escudo-IE.png';
import uvegLogo from '../NavBar/logo-blanco.png';

const Footer = () => {
  return (
    <footer className={`${styles.footer}`}>
      <div className="container py-4">
        <div className="row">
          <div className="col-md-3 text-center">
            <a href="https://coachblockchain.com" target="_blank" rel="noopener noreferrer">
              <img src={coachLogo} alt="Coach Blockchain" className={styles['footer-logo']} />
            </a>
            <div className={`${styles['footer-partners']} mt-2`}>
              <a href="https://educa.com" target="_blank" rel="noopener noreferrer">
                <img src={educaLogo} alt="EDUCA" className={styles['footer-partner-logo']} />
              </a>
              <a href="https://uveg.edu.mx" target="_blank" rel="noopener noreferrer">
                <img src={uvegLogo} alt="UVEG" className={styles['footer-partner-logo']} />
              </a>
            </div>
          </div>
          <div className="col-md-2">
            <h6 className="text-uppercase">Mercado</h6>
            <ul className="list-unstyled">
              <li><Link to="/course/NFTs" className="text-white">Todos los NFT</Link></li>
              <li><Link to="/course/blockchain" className="text-white">Detalles</Link></li>
              <li><Link to="/course/register" className="text-white">Nuestros NFT</Link></li>
              <li><Link to="/course/NFTs" className="text-white">Arte Digital</Link></li>
            </ul>
          </div>
          <div className="col-md-2">
            <h6 className="text-uppercase">Recursos</h6>
            <ul className="list-unstyled">
              <li><Link to="/course/about-us" className="text-white">Conócenos</Link></li>
              <li><Link to="/course/roadmap" className="text-white">Descubre</Link></li>
            </ul>
          </div>
          <div className="col-md-2">
            <h6 className="text-uppercase">Cuenta</h6>
            <ul className="list-unstyled">
              <li><Link to="/course/about-us" className="text-white">Equipo</Link></li>
              <li><Link to="/course/NFTs" className="text-white">Colecciones</Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h6 className="text-uppercase">Marketplace</h6>
            <ul className="list-unstyled">
              <li><Link to="/course/register" className="text-white">¡Únete a la comunidad!</Link></li>
            </ul>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-center">
            <p>© 2024 COACHBLOCKCHAIN</p>
           
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
