import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';


// Importa componentes principales
import Navigation from '../components/NavBar/Navbar';
import Footer from '../components/Layaout/Footer';
import AuthenticatedNav from '../components/NavBar/AuthenticatedNav';
import Home from './Home';
import Treaning from './Traning';
import NFTCollection from './NFTCollection';
import Roadmap from './Roadmap';
import Create from './Create';
import MyListedItems from './MyListedItems';
import MyPurchases from './MyPurchases';
import RegistrationComponent from './Registration';
import MarketplaceAbi from '../contractsData/Marketplace.json';
import MarketplaceAddress from '../contractsData/Marketplace-address.json';
import NFTAbi from '../contractsData/NFT.json';
import NFTAddress from '../contractsData/NFT-address.json';
import Profile from './Profile';
import Course from './ComponentCourse';
import AboutUs from './AboutUs';
import { ethers } from 'ethers';
import Guia from './guia';
import VideoGuide from './Videos'
import './App.css';
import Scene from "../components/FiberElements/CoashBlockchain"; // si usaste Logo.jsx

import TradingTerminal from './ToolsTreading';
import CyptoTools from './CyptoTools'
import MainPage from './MintNft';
import Museum from './Museum';
import IframeRenderer from "./IframeCourseRender";
import HomeContractManager from "./HomeContractManager"
import VideoComponet from "./VideoIframe"
import ChatBotButton from "./BotPage"
import Bot from "./BotInteligente"
import BlockchainSimulator from './BlockchainSimulator';

//Data
import URLCourse from '../data/URLCourse.json'

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [nft, setNFT] = useState({});
  const [marketplace, setMarketplace] = useState({});
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const navigate=useNavigate()

{/*
  useEffect(() => {
    if (window.ethereum) {
      web3Handler();
    } else {
      setLoading(false);
    }
  }, []);
  
  */}

  const web3Handler = async () => {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();  // Aseguramos que signer está definido aquí

        window.ethereum.on('chainChanged', () => window.location.reload());
        window.ethereum.on('accountsChanged', async (newAccounts) => {
            if (newAccounts.length === 0) {
                setAccount(null);
                setLoading(false);
                navigate('/');
            } else {
                setAccount(newAccounts[0]);
                loadContracts(signer);  // Ahora signer sí está disponible
            }
        });

        loadContracts(signer); // Asegurar que signer existe antes de pasarlo a esta función

        if (isEmailVerified) {
            navigate('/course/profile');
        }
    } catch (error) {
     
        setLoading(false);
    }
};




  const loadContracts = async (signer) => {
    try {
      const marketplace = new ethers.Contract(MarketplaceAddress.address, MarketplaceAbi.abi, signer);
      setMarketplace(marketplace);
      const nft = new ethers.Contract(NFTAddress.address, NFTAbi.abi, signer);
      setNFT(nft);
    } catch (error) {
     
    }
    setLoading(false);
  };

  // Rutas en las que NO se mostrará el Footer
  //const noFooterRoutes = ["/course/profile", "/course/CDB", "/course/trading-terminal", "/course/crypto-tools", "/course/mint-nft", "/course/historia-del-dinero","/course/contract-manager","/course/videos"];
  
  const noFooterRoutes = [
    "/course/profile", "/course/CDB", "/course/trading-terminal", 
    "/course/crypto-tools", "/course/mint-nft", "/course/historia-del-dinero",
    "/course/contract-manager", "/course/videos",

    "/course/introduccion-a-la-blockchain", "/course/blockchain-en-la-industria",
    "/course/historia-del-dinero", "/course/bitcoin-historia",
    "/course/criptomonedas-y-tokens", "/course/mineria-blockchain",
    "/course/criptografia-blockchain", "/course/criptografia-asimetrica",
    "/course/explorando-hash-sha256", "/course/arboles-merkle-blockchain",
    "/course/introduccion-wallets-blockchain", "/course/nft-blockchain",
    "/course/contratos-inteligentes", "/course/desarrollo-dapps-blockchain",
    "/course/aspectos-legales-blockchain", "/course/finanzas-descentralizadas-defi",
    "/course/ia-respuestas","/course/tools-blockchain"
  ];

  return (
    <div className='App'>
      <Navigation  />
      {account && <AuthenticatedNav account={account} setAccount={setAccount} />}
      <div>
        <Routes>
          {/* Si el usuario no está autenticado, la ruta principal es Home */}
          {!account ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/course/blockchain" element={<Treaning />} />
              <Route path="/course/NFTs" element={<NFTCollection />} />
              <Route path="/course/roadmap" element={<Roadmap />} />
              <Route path="/course/register" element={<RegistrationComponent web3Handler={web3Handler} account={account} />} />
              <Route path="/course/guia" element={<Guia />} />
              <Route path="/course/about-us" element={<AboutUs />} />
              <Route path="/course/acceso" element={<VideoGuide />} />
              <Route path="/course/ia-respuestas" element={<Bot />} />
         
              

              
            </>
          ) : (
            <>
              {/* Si el usuario está autenticado, la ruta principal es Profile */}
              <Route path="/" element={<Profile />} />
              <Route path="/course/profile" element={<Profile />} />
              <Route path="/course/CDB" element={<Course />} />
              <Route path="/course/trading-terminal" element={<TradingTerminal />} />
              <Route path="/course/crypto-tools" element={<CyptoTools />} />
              <Route path="/course/mint-nft" element={<MainPage />} />
              <Route path="/course/museum" element={<Museum />} />
              <Route path="/course/contract-manager" element={<HomeContractManager />} />
              <Route path="/course/videos" element={<VideoComponet />} />
              <Route path="/course/register" element={<RegistrationComponent web3Handler={web3Handler} account={account} />} />
              <Route path="/course/ia-respuestas" element={<Bot />} />
              <Route path="/course/tools-blockchain" element={<BlockchainSimulator />} />

              



              <Route path="/course/introduccion-a-la-blockchain" element={<IframeRenderer url={URLCourse.introduccionBlockchainURL} />} />
              <Route path="/course/blockchain-en-la-industria" element={<IframeRenderer url={URLCourse.blockchainIndustriaURL} />} />
              <Route path="/course/historia-del-dinero" element={<IframeRenderer url={URLCourse.historiaDelDineroURL} />} />
              <Route path="/course/bitcoin-historia" element={<IframeRenderer url={URLCourse.bitcoinHistoriaURL} />} />
              <Route path="/course/criptomonedas-y-tokens" element={<IframeRenderer url={URLCourse.criptomonedasTokensURL} />} />
              <Route path="/course/mineria-blockchain" element={<IframeRenderer url={URLCourse.mineriaBlockchainURL} />} />
              <Route path="/course/criptografia-blockchain" element={<IframeRenderer url={URLCourse.criptografiaBlockchainURL} />} />
              <Route path="/course/criptografia-asimetrica" element={<IframeRenderer url={URLCourse.criptografiaAsimetricaURL} />} />
              <Route path="/course/explorando-hash-sha256" element={<IframeRenderer url={URLCourse.explorandoHashSHA256URL} />} />
              <Route path="/course/arboles-merkle-blockchain" element={<IframeRenderer url={URLCourse.arbolesMerkleBlockchainURL} />} />
              <Route path="/course/introduccion-wallets-blockchain" element={<IframeRenderer url={URLCourse.introduccionWalletsBlockchainURL} />} />
              <Route path="/course/nft-blockchain" element={<IframeRenderer url={URLCourse.nftBlockchainURL} />} />
              <Route path="/course/contratos-inteligentes" element={<IframeRenderer url={URLCourse.contratosInteligentesURL} />} />
              <Route path="/course/desarrollo-dapps-blockchain" element={<IframeRenderer url={URLCourse.desarrolloDappsBlockchainURL} />} />
              <Route path="/course/aspectos-legales-blockchain" element={<IframeRenderer url={URLCourse.aspectosLegalesBlockchainURL} />} />
              <Route path="/course/finanzas-descentralizadas-defi" element={<IframeRenderer url={URLCourse.finanzasDescentralizadasDefiURL} />} />

            </>
          )}
        </Routes>
      </div>
  

      {/* Muestra el Footer solo si la ruta actual no está en noFooterRoutes */}
      {!noFooterRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
}

export default AppWrapper;
