import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  background: linear-gradient(180deg, #1a1a1a, #000000);
  color: #ffffff;
  text-align: center;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  margin-bottom:20px;
`;

const HighlightBar = styled.div`
  width: 100px;
  height: 4px;
  background-color: #f97316;
  margin: 0 auto 20px auto;
`;

const LargeText = styled.div`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SubText = styled.div`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

const Source = styled.div`
  font-size: 1rem;
  color: #f97316;
  font-style: italic;
`;

const BlockchainMarketCard = () => {
  return (
    <CardContainer>
      <HighlightBar />
      <LargeText>$20 billion</LargeText>
      <SubText>
        el valor esperado del mercado global de blockchain en 2024
      </SubText>
    </CardContainer>
  );
};

export default BlockchainMarketCard;
