import React from 'react';
import styles from './ObjectivesCard.module.css';

const ObjectivesCard = ({ title, objectives }) => {
  return (
    <div className={styles.cardContainer}>
      <h2 className={styles.title}>{title}</h2>
      <ul className={styles.objectivesList}>
        {objectives && objectives.map((objective, index) => (
          <li key={index} className={styles.objectiveItem}>
            {objective}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ObjectivesCard;
