import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import {
  FaTools,
  FaExchangeAlt,
  FaCalculator,
  FaCreditCard,
  FaChartLine,
  FaDollarSign,
  FaNewspaper,
  FaSearchDollar,
  FaBitcoin,
  FaAndroid,
  FaUserCheck,
  FaWallet,
  FaCoins,
  FaServer,
  FaFileAlt,
  FaHandHoldingUsd,
  FaYoutube,
  FaCalendarAlt,
  FaChartBar,
  FaRedditAlien,
  FaDesktop,
  FaApple,
  FaChartPie,
  FaDatabase,
  FaComments,
  FaFilm,
  FaRobot,
  FaRocket,
  FaParachuteBox,
  FaSyncAlt,
  FaBlogger,
  FaLock,
  FaLaptopCode,
} from 'react-icons/fa';
import { GiDiceSixFacesFive, GiMining, GiPoolDive } from 'react-icons/gi';
import styled from 'styled-components';

const iconMapping = {
  1: FaExchangeAlt,
  2: GiDiceSixFacesFive,
  3: FaCalculator,
  4: FaCreditCard,
  5: FaChartLine,
  6: GiMining,
  7: FaDollarSign,
  8: FaNewspaper,
  9: FaComments,
  10: FaSearchDollar,
  11: FaBitcoin,
  12: FaCreditCard,
  13: FaAndroid,
  14: FaUserCheck,
  15: GiPoolDive,
  16: FaLaptopCode,
  17: FaWallet,
  18: FaLock,
  19: FaCoins,
  20: FaServer,
  21: FaFileAlt,
  22: FaHandHoldingUsd,
  23: FaYoutube,
  24: FaCalendarAlt,
  25: FaAndroid,
  26: FaChartBar,
  27: FaRedditAlien,
  28: FaExchangeAlt,
  29: FaSyncAlt,
  30: FaDesktop,
  31: FaApple,
  32: FaChartPie,
  33: FaParachuteBox,
  34: FaDatabase,
  35: FaComments,
  36: FaNewspaper,
  37: FaExchangeAlt,
  38: FaBlogger,
  39: FaFilm,
  40: FaRobot,
  41: FaRocket,
  42: FaChartLine,
};

function useWindowWidth() {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 1024
  );
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return width;
}

const SidebarContainer = styled.div`
  position: fixed;
  top: 4.5vw;
  left: 0;
  height: 90vh;
  width: ${(props) => (props.isCollapsed ? '80px' : '260px')};
  background: #fff;
  color: #333;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  z-index: 1000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 768px) {
    top: 10vh;
    height: calc(100vh - 10vh);
  }
`;

const SidebarToggle = styled.div`
  position: absolute;
  right: -10px;
  top: 20px;
  background: white;
  color: #007cc2;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f0f0f0;
  }
`;

const SidebarHeader = styled.div`
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
`;

const SidebarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  h3 {
    color: #007cc2;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
  }
`;

const SidebarMenu = styled.nav`
  flex-grow: 1;
  padding: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 12px;
    font-size: 16px;
    transition: 0.3s;

    &:hover {
      background: #f1f1f1;
      border-radius: 5px;
    }
  }
`;

const MenuItem = styled.li`
  a {
    background: ${(props) => (props.active ? '#e6f4f8' : 'transparent')};
    border-radius: 5px;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    color: ${(props) => (props.active ? '#007cc2' : '#333')};
  }
`;

const Pagination = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 14px;
  border-top: 1px solid #ccc;
  background: #f9f9f9;
`;

const Sidebar = ({ categories, selectedCategory, onSelect, onToggle }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const width = useWindowWidth();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(categories.length / itemsPerPage);
  const visibleCategories = categories.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Auto-seleccionar la primera categoría si ninguna está seleccionada
  useEffect(() => {
    if ((!selectedCategory || !selectedCategory.id) && categories.length > 0) {
      onSelect(categories[0]);
    }
  }, [selectedCategory, categories, onSelect]);

  // Actualiza el estado de colapso según el ancho de la ventana
  useEffect(() => {
    setIsCollapsed(width <= 768);
  }, [width]);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
    onToggle();
  };

  return (
    <SidebarContainer isCollapsed={isCollapsed}>
      <SidebarToggle onClick={handleToggle}>
        {isCollapsed ? <FiChevronRight size={24} /> : <FiChevronLeft size={24} />}
      </SidebarToggle>

      <SidebarHeader>
        <SidebarLogo>
          <FaTools size={30} />
          {!isCollapsed && <h3>Herramientas</h3>}
        </SidebarLogo>
      </SidebarHeader>

      <SidebarMenu>
        <ul>
          {visibleCategories.map((cat, index) => {
            const IconComponent = iconMapping[cat.id] || FaTools;
            return (
              <MenuItem
                key={cat.id}
                active={selectedCategory && selectedCategory.id === cat.id}
              >
                <a href="#!" onClick={() => onSelect(cat)}>
                  <IconComponent
                    size={20}
                    style={{ marginRight: isCollapsed ? 0 : '8px' }}
                  />
                  {isCollapsed
                    ? index + 1 + (currentPage - 1) * itemsPerPage
                    : cat.title}
                </a>
              </MenuItem>
            );
          })}
        </ul>
      </SidebarMenu>

      {totalPages > 1 && !isCollapsed && (
        <Pagination>
          <button
            onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          &nbsp; Página {currentPage} de {totalPages} &nbsp;
          <button
            onClick={() => setCurrentPage((p) => Math.min(totalPages, p + 1))}
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </Pagination>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
