import React, { useState } from 'react';

// Funciones de validación para cada red

function isValidBitcoinAddress(address) {
  // Acepta Legacy (empieza con "1"), P2SH (empieza con "3") y Bech32 (empieza con "bc1")
  return /^(1|3|bc1)[a-zA-Z0-9]{25,39}$/.test(address);
}

function isValidEthereumAddress(address) {
  // Debe tener 42 caracteres y comenzar con "0x" seguido de 40 caracteres hexadecimales
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}

function isValidSolanaAddress(address) {
  // Aproximadamente entre 32 y 44 caracteres en Base58
  return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address);
}

function isValidRippleAddress(address) {
  // Comienza con "r" y tiene entre 25 y 35 caracteres (Base58Check)
  return /^r[1-9A-HJ-NP-Za-km-z]{24,34}$/.test(address);
}

function isValidCardanoAddress(address) {
  // Comienza con "addr1" y es Bech32 (longitud variable)
  return /^addr1[0-9a-z]{50,}$/.test(address);
}

function isValidPolkadotAddress(address) {
  // Aproximadamente 47-48 caracteres en Base58 (formato SS58)
  return /^[1-9A-HJ-NP-Za-km-z]{47,48}$/.test(address);
}

function isValidAlgorandAddress(address) {
  // 58 caracteres en Base32 (alfabeto: A-Z y dígitos 2-7)
  return /^[A-Z2-7]{58}$/.test(address);
}

function isValidStellarAddress(address) {
  // Comienza con "G" y tiene 56 caracteres
  return /^G[A-Z2-7]{55}$/.test(address);
}

function isValidCosmosAddress(address) {
  // Comienza con "cosmos1" y sigue con caracteres alfanuméricos (Bech32)
  return /^cosmos1[0-9a-z]{38}$/.test(address);
}

function isValidTezosAddress(address) {
  // Puede iniciar con tz1, tz2 o KT1 y tener 36 caracteres en total
  return /^(tz1|tz2|KT1)[A-Za-z0-9]{33}$/.test(address);
}

function isValidNearAddress(address) {
  // Puede ser un nombre legible terminando en ".near" o una cadena alfanumérica
  if (address.endsWith('.near')) return true;
  return /^[a-z0-9_-]{40,}$/.test(address);
}

function isValidHederaAddress(address) {
  // Formato nativo: "0.0.x", o formato EVM similar a Ethereum
  if (/^0\.0\.\d+$/.test(address)) return true;
  if (/^0x[a-fA-F0-9]{40}$/.test(address)) return true;
  return false;
}

function isValidTronAddress(address) {
  // Comienza con "T" y tiene aproximadamente 34 caracteres
  return /^T[A-Za-z1-9]{33}$/.test(address);
}

function isValidEosAddress(address) {
  // Nombre de cuenta EOS: 12 caracteres, minúsculas a-z y dígitos 1-5 (puede incluir el punto)
  return /^[a-z1-5.]{12}$/.test(address);
}

// Datos de las redes: función de validación y formato esperado

const networkData = {
  "Bitcoin": {
    validate: isValidBitcoinAddress,
    expected: "Legacy: empieza con '1', P2SH: empieza con '3', o Bech32: empieza con 'bc1' seguido de 25-39 caracteres alfanuméricos."
  },
  "Ethereum": {
    validate: isValidEthereumAddress,
    expected: "Cadena hexadecimal de 42 caracteres que comienza con '0x'."
  },
  "Solana": {
    validate: isValidSolanaAddress,
    expected: "Cadena en Base58, típicamente entre 32 y 44 caracteres."
  },
  "Ripple": {
    validate: isValidRippleAddress,
    expected: "Cadena en Base58Check que comienza con 'r', de 25 a 35 caracteres."
  },
  "Cardano": {
    validate: isValidCardanoAddress,
    expected: "Dirección Bech32 que comienza con 'addr1' (longitud variable)."
  },
  "Polkadot": {
    validate: isValidPolkadotAddress,
    expected: "Cadena en Base58 usando el formato SS58, normalmente de 47-48 caracteres."
  },
  "Avalanche": {
    validate: isValidEthereumAddress, // Para la C-Chain, igual que Ethereum
    expected: "Similar a Ethereum: cadena hexadecimal de 42 caracteres que comienza con '0x'."
  },
  "Algorand": {
    validate: isValidAlgorandAddress,
    expected: "Cadena de 58 caracteres en Base32 (letras mayúsculas y dígitos del 2 al 7)."
  },
  "Stellar": {
    validate: isValidStellarAddress,
    expected: "Cadena de 56 caracteres que comienza con 'G'."
  },
  "Cosmos": {
    validate: isValidCosmosAddress,
    expected: "Dirección Bech32 que comienza con 'cosmos1' (longitud variable)."
  },
  "Tezos": {
    validate: isValidTezosAddress,
    expected: "Cadena en Base58Check que comienza con 'tz1', 'tz2' o 'KT1'."
  },
  "Fantom": {
    validate: isValidEthereumAddress,
    expected: "Igual que Ethereum: cadena hexadecimal de 42 caracteres que comienza con '0x'."
  },
  "NEAR Protocol": {
    validate: isValidNearAddress,
    expected: "Puede ser un nombre legible que termine en '.near' o una cadena alfanumérica variable."
  },
  "Binance Smart Chain": {
    validate: isValidEthereumAddress,
    expected: "Igual que Ethereum: cadena hexadecimal de 42 caracteres que comienza con '0x'."
  },
  "Hedera Hashgraph": {
    validate: isValidHederaAddress,
    expected: "Nativo: formato '0.0.x'; EVM: igual que Ethereum (42 caracteres hexadecimales)."
  },
  "TRON": {
    validate: isValidTronAddress,
    expected: "Cadena en Base58Check que comienza con 'T', típicamente de 34 caracteres."
  },
  "EOS": {
    validate: isValidEosAddress,
    expected: "Cadena de 12 caracteres con minúsculas a-z y dígitos del 1 al 5."
  }
};

const AddressValidator = () => {
  const [selectedNetwork, setSelectedNetwork] = useState("Ethereum");
  const [address, setAddress] = useState("");
  const [result, setResult] = useState(null);

  const handleNetworkChange = (e) => {
    setSelectedNetwork(e.target.value);
    setResult(null);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value.trim());
    setResult(null);
  };

  const validateAddress = () => {
    const network = networkData[selectedNetwork];
    if (!network) {
      setResult("Red seleccionada no soportada.");
      return;
    }
    const isValid = network.validate(address);
    setResult(isValid ? "Dirección válida" : "Dirección inválida");
  };

  // Estilos para un diseño elegante y moderno
  const containerStyle = {
    maxWidth: "600px",
    margin: "40px auto",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif"
  };

  const headerStyle = {
    textAlign: "center",
    marginBottom: "20px",
    color: "#333"
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold"
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginBottom: "15px",
    fontSize: "16px"
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    display: "block",
    margin: "0 auto"
  };

  const resultStyle = {
    marginTop: "20px",
    padding: "10px",
    borderRadius: "4px",
    textAlign: "center",
    backgroundColor: result === "Dirección válida" ? "#e0ffe0" : "#ffe0e0",
    color: result === "Dirección válida" ? "#008000" : "#a00000",
    fontWeight: "bold"
  };

  return (
    <div style={containerStyle}>
      <h2 style={headerStyle}>Validador de Direcciones Blockchain</h2>
      <div>
        <label style={labelStyle}>
          Selecciona Red:
          <select value={selectedNetwork} onChange={handleNetworkChange} style={inputStyle}>
            {Object.keys(networkData).map((networkName) => (
              <option key={networkName} value={networkName}>
                {networkName}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div>
        <label style={labelStyle}>
          Ingresa la dirección:
          <input
            type="text"
            value={address}
            onChange={handleAddressChange}
            style={inputStyle}
            placeholder="Pega tu dirección aquí"
          />
        </label>
      </div>
      <button onClick={validateAddress} style={buttonStyle}>
        Validar Dirección
      </button>
      {result && <div style={resultStyle}>{result}</div>}
      <div style={{ marginTop: "30px", borderTop: "1px solid #eee", paddingTop: "20px" }}>
        <h3>Formato esperado para {selectedNetwork}:</h3>
        <p>{networkData[selectedNetwork].expected}</p>
      </div>
    </div>
  );
};

export default AddressValidator;
