import React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import uvegLogo from './logo-blanco.png';
import educaLogo from './Escudo-IE.png';
import coachBlockchainLogo from './logo1.png';
import styles from './Navigation.module.css';

const AuthenticatedNav = ({ account, setAccount }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            // Elimina la cuenta almacenada en el estado
            setAccount(null);

            // Borra datos de la wallet en localStorage y sessionStorage
            localStorage.removeItem("walletAccount");
            sessionStorage.removeItem("walletAccount");

            // Si usas ethers.js o web3.js, intenta desconectar la wallet
            if (window.ethereum) {
                try {
                    await window.ethereum.request({
                        method: "wallet_requestPermissions",
                        params: [{ eth_accounts: {} }],
                    });
                } catch (error) {
                    console.error("Error al revocar permisos:", error);
                }
            }

            // Redirige a la página principal
            navigate("/");

        } catch (error) {
            console.error("Error al cerrar sesión:", error);
        }
    };


    return (
        account && (
            <Navbar expand="lg" bg="dark" variant="dark" className={styles.navbar}>
                <Container>
                    <Navbar.Brand>
                        <img src={uvegLogo} width="40" height="40" className={styles.logo} alt="UVEG Logo" />
                        <img src={educaLogo} width="40" height="40" className={`${styles.logo} mx-3`} alt="Educa Logo" />
                        <img src={coachBlockchainLogo} width="100" height="40" className={styles.logo} alt="Coach Blockchain Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="authenticated-navbar" />
                    <Navbar.Collapse id="authenticated-navbar">
                        <Nav className="me-auto">
                            <Nav.Link as={NavLink} to="/course/profile" className={styles.navLink} activeClassName={styles.activeNavLink}>
                                Profile
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/course/mint-nft" className={styles.navLink} activeClassName={styles.activeNavLink}>
                                NFTs
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/course/crypto-tools" className={styles.navLink} activeClassName={styles.activeNavLink}>
                                Herramientas
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/course/trading-terminal" className={styles.navLink} activeClassName={styles.activeNavLink}>
                                Trading
                            </Nav.Link>
                            {/** <Nav.Link as={NavLink} to="/course/museum" className={styles.navLink} activeClassName={styles.activeNavLink}>
                                Museum
                            </Nav.Link>*/}
                            <Nav.Link as={NavLink} to="/course/contract-manager" className={styles.navLink} activeClassName={styles.activeNavLink}>
                                Contract
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/course/ia-respuestas" className={styles.navLink} activeClassName={styles.activeNavLink}>
                                FAQ Inteligente
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/course/tools-blockchain" className={styles.navLink} activeClassName={styles.activeNavLink}>
                               Simulaciones
                            </Nav.Link>
                           

                        </Nav>

                        <Nav className="d-flex align-items-center">
                            <Nav.Link
                                href={`https://etherscan.io/address/${account}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${styles.navButton} mx-4`}
                            >
                                <Button variant="outline-light" className={styles.accountButton}>
                                    {account.slice(0, 6)}...{account.slice(-4)}
                                </Button>
                            </Nav.Link>
                            <Button variant="danger" onClick={handleLogout} className={styles.logoutButton}>
                                Salir
                            </Button>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    );
};

export default AuthenticatedNav;
