import React, { useEffect, useState } from 'react';

const MarkdownProcessor = ({ url, onContentProcessed }) => {
  const [contentSections, setContentSections] = useState([]);

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(url);
        const markdownText = await response.text();
        const lines = markdownText.split('\n');

        const sections = [];
        let currentSection = null;
        let currentTable = [];

        lines.forEach((line) => {
          // 1. Detectar títulos principales (## **Texto**)
          const titleMatch = line.match(/^##\s+\*\*(.+?)\*\*\s*$/);
          if (titleMatch) {
            if (currentSection) {
              sections.push(currentSection);
            }
            currentSection = {
              title: titleMatch[1].trim(),
              subtitle: '',
              content: [], // La imagen ahora queda en el flujo de contenido
              links: [],
              points: [],
              tables: [],
            };
            return;
          }

          // 2. Detectar subtítulos (**Texto**)
          const subtitleMatch = line.match(/^\*\*(.+?)\*\*\s*$/);
          if (subtitleMatch && currentSection) {
            currentSection.content.push(`**${subtitleMatch[1].trim()}**`);
            return;
          }

          // 3. Detectar imágenes (![texto](url)) y mantenerlas dentro del contenido
          if (line.match(/^!\[(.*?)\]\((.*?)\)$/)) {
            if (currentSection) {
              currentSection.content.push(line.trim()); // Se mantiene en el flujo
            }
            return;
          }

          // 4. Detectar tablas ( | Característica | ...)
          const tableMatch = line.match(/^\|(.+?)\|$/);
          if (tableMatch) {
            currentTable.push(line.trim());
            return;
          }

          // Si termina la tabla (cuando hay línea vacía)
          if (currentTable.length > 0 && line.trim() === '') {
            if (currentSection) {
              currentSection.tables.push(currentTable);
            }
            currentTable = [];
            return;
          }

          // 5. Capturar contenido general
          if (line.trim() !== '' && currentSection) {
            currentSection.content.push(line.trim());
          }
        });

        // Agregar la última sección si existe
        if (currentSection) {
          if (currentTable.length > 0) {
            currentSection.tables.push(currentTable);
          }
          sections.push(currentSection);
        }

        setContentSections(sections);
        if (onContentProcessed) {
          onContentProcessed(sections);
        }
      } catch (error) {
        console.error('❌ Error al obtener el archivo Markdown:', error);
      }
    };

    fetchMarkdown();
  }, [url, onContentProcessed]);

  return null;
};

export default MarkdownProcessor;
