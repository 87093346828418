// src/components/Cards/OutcomeCard.js
import React from 'react';
import styles from './OutcomeCard.module.css';

const OutcomeCard = ({ title, outcomes }) => {
  return (
    <div className={styles.cardContainer}>
      <h2 className={styles.title}>
        <span className={styles.checkmark}>✓</span> {title}
      </h2>
      <ul className={styles.outcomeList}>
        {outcomes.map((outcome, index) => (
          <li key={index} className={styles.outcomeItem}>
            <span className={styles.checkmark}>✓</span> {outcome}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OutcomeCard;
