import React, { useRef, useEffect } from 'react';

const SCRIPT_ID = 'tradingview-widget-script';
const CONTAINER_ID = 'vue-trading-view';

const tradingViewOptions = {
  autosize: true,
  symbol: 'BINANCE:BTCUSDT',
  interval: '5',
  timezone: 'Asia/Jerusalem',
  theme: 'dark',
  style: '1',
  locale: 'en',
  toolbar_bg: '#f1f3f6',
  enable_publishing: false,
  withdateranges: true,
  hide_side_toolbar: false,
  allow_symbol_change: true,
  save_image: true,
  details: false,
  hotlist: false,
  studies: [
    { id: 'VWAP@tv-basicstudies' },
    {
      id: 'MAExp@tv-basicstudies',
      inputs: { length: 200 },
      styles: {}
    }
  ]
};

const TradingViewChart = ({ toggleSettings }) => {
  const containerRef = useRef(null);

  const canUseDOM = () => typeof window !== 'undefined' && window.document;
  const getScriptElement = () => document.getElementById(SCRIPT_ID);
  const scriptExists = () => getScriptElement() !== null;

  const appendScript = (onload) => {
    if (!canUseDOM()) {
      onload();
      return;
    }
    if (scriptExists()) {
      if (typeof window.TradingView === 'undefined') {
        getScriptElement().onload = onload;
        return;
      }
      onload();
      return;
    }
    const script = document.createElement('script');
    script.id = SCRIPT_ID;
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/tv.js';
    script.onload = onload;
    document.head.appendChild(script);
  };

  const initWidget = () => {
    if (typeof window.TradingView === 'undefined') return;
    new window.TradingView.widget({
      container_id: CONTAINER_ID,
      ...tradingViewOptions
    });
  };

  useEffect(() => {
    appendScript(initWidget);
  }, []);

  return (
    <div style={{ width: '95vw', height: '90vh', backgroundColor: '#1e1e1e', margin: '5% auto' }}>

  
      <div id={CONTAINER_ID} ref={containerRef} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
};

export default TradingViewChart;
