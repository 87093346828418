import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const syllabusData = [
  {
    module: 1,
    title: "Fundamentos de Blockchain",
    topics: [
      { topic: "Definición Blockchain", duration: "00:45" },
      { topic: "Historia Blockchain", duration: "00:40" },
      { topic: "Funcionamiento Blockchain", duration: "00:50" },
      { topic: "Tipos Blockchain", duration: "00:40" },
      { topic: "Aplicaciones Reales", duration: "00:35" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 2,
    title: "Contratos Inteligentes y Tokenización",
    topics: [
      { topic: "Creación Contratos", duration: "00:45" },
      { topic: "Seguridad Contratos", duration: "00:35" },
      { topic: "Implementación Contratos", duration: "00:40" },
      { topic: "Tipos en Tokenización", duration: "00:35" },
      { topic: "Contrato KaU", duration: "00:35" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 3,
    title: "DeFi y Tokenización",
    topics: [
      { topic: "Fundamentos DeFi", duration: "00:40" },
      { topic: "Impacto en DeFi", duration: "00:30" },
      { topic: "Protocolos DeFi", duration: "00:45" },
      { topic: "Pools Liquidez", duration: "00:40" },
      { topic: "Yield Farming", duration: "00:35" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 4,
    title: "DAO Blockchain",
    topics: [
      { topic: "Definición DAO", duration: "00:40" },
      { topic: "Tipos de DAO", duration: "00:45" },
      { topic: "Gobernanza DAO", duration: "00:35" },
      { topic: "Casos de DAO", duration: "00:40" },
      { topic: "Ventajas DAO", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 5,
    title: "ICOs y STOs",
    topics: [
      { topic: "Definición ICO", duration: "00:45" },
      { topic: "Diferencias ICO-STO", duration: "00:35" },
      { topic: "Ventajas ICO", duration: "00:40" },
      { topic: "Regulación ICO", duration: "00:35" },
      { topic: "Riesgos ICO", duration: "00:35" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 6,
    title: "KYC en Tokenización",
    topics: [
      { topic: "Importancia KYC", duration: "00:35" },
      { topic: "Proceso KYC", duration: "00:45" },
      { topic: "Requisitos Legales", duration: "00:35" },
      { topic: "Impacto en Tokenización", duration: "00:35" },
      { topic: "Seguridad KYC", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 7,
    title: "Normativas en Tokenización",
    topics: [
      { topic: "Regulaciones Globales", duration: "00:45" },
      { topic: "Cumplimiento Normativo", duration: "00:35" },
      { topic: "Requisitos Tokenización", duration: "00:40" },
      { topic: "Leyes Seguridad", duration: "00:30" },
      { topic: "Retos Legales", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 8,
    title: "Modelos en Tokenización",
    topics: [
      { topic: "Modelos Fraccionados", duration: "00:40" },
      { topic: "Activos Financieros", duration: "00:30" },
      { topic: "Propiedades Inmuebles", duration: "00:45" },
      { topic: "Tokenización Arte", duration: "00:35" },
      { topic: "Normativa de Tokenización", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 9,
    title: "Desafíos de Tokenización",
    topics: [
      { topic: "Retos Legales", duration: "00:30" },
      { topic: "Seguridad Blockchain", duration: "00:40" },
      { topic: "Auditorías Regulares", duration: "00:40" },
      { topic: "Gobernanza Descentralizada", duration: "00:35" },
      { topic: "Escalabilidad Redes", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 10,
    title: "Seguridad en Wallets",
    topics: [
      { topic: "Tipos de Wallets", duration: "00:35" },
      { topic: "Seguridad Hot Wallets", duration: "00:30" },
      { topic: "Seguridad Cold Wallets", duration: "00:35" },
      { topic: "Exchanges Centralizados", duration: "00:35" },
      { topic: "Mejores Prácticas", duration: "00:25" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 11,
    title: "Seguridad en NFTs",
    topics: [
      { topic: "Definición NFTs", duration: "00:35" },
      { topic: "Riesgos en NFTs", duration: "00:30" },
      { topic: "Almacenamiento Seguro", duration: "00:35" },
      { topic: "Mercados NFT", duration: "00:25" },
      { topic: "Protección Propiedad", duration: "00:35" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 12,
    title: "Exchanges Descentralizados",
    topics: [
      { topic: "Definición DEX", duration: "00:35" },
      { topic: "Seguridad en DEX", duration: "00:30" },
      { topic: "Liquidez en DEX", duration: "00:35" },
      { topic: "Anonimato y Regulación", duration: "00:30" },
      { topic: "Mejores Prácticas DEX", duration: "00:25" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 13,
    title: "Sistemas Multi-Firma",
    topics: [
      { topic: "Definición Multi-Firma", duration: "00:35" },
      { topic: "Aplicaciones en Seguridad", duration: "00:30" },
      { topic: "Usos en DAO", duration: "00:35" },
      { topic: "Ventajas de Multi-Firma", duration: "00:30" },
      { topic: "Riesgos Asociados", duration: "00:20" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 14,
    title: "Seguridad en Exchanges",
    topics: [
      { topic: "Custodia en Exchanges", duration: "00:35" },
      { topic: "Ataques a Exchanges", duration: "00:30" },
      { topic: "Exchanges Descentralizados", duration: "00:30" },
      { topic: "Regulaciones Exchange", duration: "00:25" },
      { topic: "Seguridad de Claves", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 15,
    title: "Neuroeconomía en Blockchain",
    topics: [
      { topic: "Introducción a Neuroeconomía", duration: "00:35" },
      { topic: "Aplicación en Blockchain", duration: "00:25" },
      { topic: "Toma de Decisiones", duration: "00:35" },
      { topic: "Estrategias de Inversión", duration: "00:25" },
      { topic: "Impacto Emocional", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 16,
    title: "Blockchain en Finanzas",
    topics: [
      { topic: "Transformación en Banca", duration: "00:35" },
      { topic: "Pagos Digitales", duration: "00:30" },
      { topic: "Criptomonedas", duration: "00:30" },
      { topic: "Tokenización de Activos", duration: "00:25" },
      { topic: "DeFi y Finanzas", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 17,
    title: "Blockchain y Gobernanza",
    topics: [
      { topic: "Gobernanza Descentralizada", duration: "00:30" },
      { topic: "DAO y Decisiones", duration: "00:25" },
      { topic: "Políticas en Blockchain", duration: "00:30" },
      { topic: "Impacto Social", duration: "00:30" },
      { topic: "Participación Comunitaria", duration: "00:30" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 18,
    title: "Avances y Futuro de Blockchain",
    topics: [
      { topic: "Nuevas Tendencias", duration: "00:30" },
      { topic: "Escalabilidad", duration: "00:25" },
      { topic: "Interoperabilidad", duration: "00:30" },
      { topic: "Privacidad y Seguridad", duration: "00:25" },
      { topic: "Blockchain Cuántico", duration: "00:35" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 19,
    title: "Aplicaciones Blockchain en Sectores",
    topics: [
      { topic: "Sector Salud", duration: "00:30" },
      { topic: "Sector Logístico", duration: "00:25" },
      { topic: "Sector Energía", duration: "00:30" },
      { topic: "Sector Público", duration: "00:25" },
      { topic: "Sector Educación", duration: "00:35" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  },
  {
    module: 20,
    title: "Blockchain y Sostenibilidad",
    topics: [
      { topic: "Impacto Ambiental", duration: "00:30" },
      { topic: "Soluciones Verdes", duration: "00:25" },
      { topic: "Blockchain y Cambio Climático", duration: "00:30" },
      { topic: "Iniciativas Sostenibles", duration: "00:25" },
      { topic: "Certificación Ecológica", duration: "00:35" }
    ],
    hours: 3.5,
    classHours: 1,
    studyHours: 2.5
  }
];




function CourseContent() {
  return (
    <div style={{ width: "90%", margin: "0 auto" }}>
  
   

    
      {syllabusData.map((section, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{section.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {section.topics.map((item, i) => (
                <li key={i} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
                  <Typography variant="body2">{item.topic}</Typography>
                  <Typography variant="body2" style={{ textAlign: "center", marginTop: "20px", cursor: "pointer", color: "#0073e6" }}>{item.duration}</Typography>
                </li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
      
      <Typography variant="body2" style={{ textAlign: "center", marginTop: "20px", cursor: "pointer", color: "#0073e6" }}>
      114 horas lectivas, distribuidas en 70 horas de formación sobre Blockchain, 24 horas sincrónicas y 20 horas acerca de neuroeconomía.
      </Typography>
    </div>
  );
}

export default CourseContent;