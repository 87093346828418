import React, { useState } from 'react';
import NFTCard from '../components/Cards/NFTCard';

// Definimos estilos como objetos JavaScript
const styles = {
  mainContainer: {
    width: '95vw',
    margin: '10vh auto',
    padding: '2rem',
    textAlign: 'center',
  },
  searchWrapper: {
    marginBottom: '2rem',
  },
  searchInput: {
    width: '300px',
    padding: '0.5rem',
    fontSize: '1rem',
    border: '2px solid #ccc',
    borderRadius: '6px',
    outline: 'none',
  },
  searchInputFocus: {
    borderColor: '#888',
  },
};

const MainPage = () => {
  const [search, setSearch] = useState('');
  const [numCards, setNumCards] = useState(100);

  const handleFocus = (e) => {
    // Podemos fusionar los estilos base con el estilo de focus
    e.target.style.borderColor = styles.searchInputFocus.borderColor;
  };

  const handleBlur = (e) => {
    // Revertimos al estilo base
    e.target.style.borderColor = '#ccc';
  };

  return (
    <div style={styles.mainContainer}>
 

      <NFTCard search={search} numCards={numCards} />
    </div>
  );
};

export default MainPage;
