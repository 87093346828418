// src/components/Cards/InfoCard.js
import React from 'react';
import styles from './InfoCard.module.css';

const InfoCard = ({ title, subtitle, description, imageSrc, imagePosition = 'right', imageWidth = '25%', imageHeight = '200px' }) => {
  return (
    <div
      className={`${styles.cardContainer} ${imagePosition === 'left' ? styles.imageLeft : styles.imageRight}`}
    >
      {imageSrc && (
        <img
          src={imageSrc}
          alt={title}
          className={styles.image}
          style={{ width: imageWidth, height: imageHeight }}
        />
      )}
      <div className={styles.textContainer}>
        <h2 className={styles.title} style={{ textAlign: imagePosition === 'left' ? 'left' : 'right' }}>
          {title}
          <span className={styles.subtitle}>{subtitle}</span>
        </h2>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default InfoCard;
