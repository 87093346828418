import { useState, useEffect } from 'react';

function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < breakpoint);
    }

    // Verificamos el tamaño inicial
    handleResize();

    // Actualizamos cuando se modifica el tamaño de la ventana
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return isMobile;
}

const TextOverlay = ({ title, subtitle, style }) => {
  // Controla si es móvil (menor que 768px) o no
  const isMobile = useIsMobile(768);

  // Estilos básicos del contenedor
  const containerStyle = {
    position: 'absolute',
    left: '50%',
    bottom: '0',
    transform: 'translateX(-50%)',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '1.5',
    zIndex: 1,
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'rgba(10, 83, 100, 0)',
    borderRadius: '15px',
    width: '90%',
    // Sombra sólo en móviles, para dar efecto 3D
    ...(isMobile && {
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)',
    }),
    ...style,
  };

  // Estilos del título
  const titleStyle = {
    fontSize: isMobile ? '24px' : '40px',
    marginBottom: '10px',
    color: '#000000',
    // Si deseas, puedes añadir sombra al texto:
    ...(isMobile && {
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    }),
  };

  // Estilos del subtítulo
  const subtitleStyle = {
    fontSize: isMobile ? '16px' : '24px',
    color: '#000000',
    // También puedes añadir una sombra más sutil al subtítulo:
    ...(isMobile && {
      textShadow: '1px 1px 3px rgba(0, 0, 0, 0.2)',
    }),
  };

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>
        {title.toUpperCase()}
      </div>
      <div style={subtitleStyle}>
        {subtitle}
      </div>
    </div>
  );
};

export default TextOverlay;
