import React, { useState } from 'react';
import SHA256 from 'crypto-js/sha256';

const BlockchainSimulator = () => {
  // Dificultad global para los próximos bloques que se agreguen
  const [globalDifficulty, setGlobalDifficulty] = useState(2);

  // Cadena de bloques (array de objetos)
  const [chain, setChain] = useState([
    {
      index: 0,
      data: '',
      nonce: 0,
      hash: '',
      prevHash: '0',  // Bloque génesis
      difficulty: 2,
      timestamp: ''   // Marca de tiempo
    }
  ]);

  // Función para calcular el hash de un bloque
  const computeHash = (block) => {
    const { index, prevHash, data, nonce, timestamp } = block;
    return SHA256(`${index}${prevHash}${data}${nonce}${timestamp}`).toString();
  };

  // Verifica si un bloque es válido respecto a su contenido y a su bloque anterior
  const isBlockValid = (block, index, entireChain) => {
    // Bloque génesis: check básico
    if (index === 0) {
      // Revisamos que su prevHash sea '0' y que su hash actual concuerde con los datos
      return block.prevHash === '0' && block.hash === computeHash(block);
    } else {
      const prevBlock = entireChain[index - 1];
      // 1) El prevHash debe coincidir con el hash del bloque anterior
      if (block.prevHash !== prevBlock.hash) {
        return false;
      }
      // 2) El hash actual debe coincidir con la función computeHash
      if (block.hash !== computeHash(block)) {
        return false;
      }
      return true;
    }
  };

  // Maneja el cambio de "data" en un bloque específico
  // (Esto hace que potencialmente invalide los bloques siguientes)
  const handleDataChange = (index, newData) => {
    const updatedChain = [...chain];
    updatedChain[index].data = newData;
    setChain(updatedChain);
  };

  // Función para minar un bloque específico
  const mineBlock = (index) => {
    const updatedChain = [...chain];
    const blockToMine = { ...updatedChain[index] };

    // Asignamos la marca de tiempo ANTES de buscar el nonce
    blockToMine.timestamp = new Date().toLocaleString();

    // Construimos el prefijo objetivo según la dificultad
    const targetPrefix = '0'.repeat(blockToMine.difficulty);

    // Arrancamos con el nonce actual del bloque
    let newNonce = blockToMine.nonce;
    blockToMine.nonce = newNonce;

    // Calculamos un hash inicial
    let newHash = computeHash(blockToMine);

    // Buscamos un hash que empiece con N ceros
    while (!newHash.startsWith(targetPrefix)) {
      blockToMine.nonce++;
      newHash = computeHash(blockToMine);
    }

    // Una vez encontrado, actualizamos el hash correcto
    blockToMine.hash = newHash;
    updatedChain[index] = blockToMine;

    // Si este no es el último bloque, actualiza el prevHash del siguiente
    if (index < updatedChain.length - 1) {
      updatedChain[index + 1].prevHash = blockToMine.hash;
    }

    setChain(updatedChain);
  };

  // Agrega un nuevo bloque a la cadena, usando la dificultad global y el hash del último bloque
  const addBlock = () => {
    const lastBlock = chain[chain.length - 1];
    const newBlock = {
      index: chain.length,
      data: '',
      nonce: 0,
      hash: '',
      prevHash: lastBlock.hash || '0',
      difficulty: globalDifficulty,
      timestamp: ''
    };
    setChain([...chain, newBlock]);
  };

  // Estilos básicos
  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
    marginTop: '20px'
  };

  // Bloque en rojo si es inválido
  const getBlockStyle = (invalid) => ({
    width: '320px',
    backgroundColor: invalid ? '#ffdddd' : '#e8f5e9',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px'
  });

  const labelStyle = {
    fontWeight: 'bold',
    display: 'block',
    marginBottom: '6px'
  };

  const inputStyle = {
    width: '100%',
    marginBottom: '10px',
    padding: '6px',
    border: '1px solid #ccc',
    borderRadius: '4px'
  };

  const buttonStyle = {
    padding: '8px 16px',
    cursor: 'pointer',
    backgroundColor: '#008CBA',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    margin: '0 8px'
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center' }}>Simulador de Cadena de Bloques</h1>

      {/* Selección de dificultad y botón para agregar bloque */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <label style={{ marginRight: '8px', fontWeight: 'bold' }}>
          Dificultad para siguiente bloque:
        </label>
        <input
          type="number"
          value={globalDifficulty}
          min="1"
          onChange={(e) => setGlobalDifficulty(Number(e.target.value))}
          style={{ width: '60px', marginRight: '20px' }}
        />
        <button onClick={addBlock} style={buttonStyle}>
          Agregar bloque
        </button>
      </div>

      {/* Render de la cadena de bloques */}
      <div style={containerStyle}>
        {chain.map((block, idx) => {
          const invalid = !isBlockValid(block, idx, chain);
          return (
            <div key={idx} style={getBlockStyle(invalid)}>
              <div>
                <label style={labelStyle}>Block #{block.index}</label>
              </div>

              <div>
                <label style={labelStyle}>Nonce:</label>
                <input
                  type="number"
                  value={block.nonce}
                  readOnly
                  style={inputStyle}
                />
              </div>

              <div>
                <label style={labelStyle}>Data:</label>
                <textarea
                  rows="4"
                  value={block.data}
                  onChange={(e) => handleDataChange(idx, e.target.value)}
                  style={{ ...inputStyle, resize: 'vertical' }}
                />
              </div>

              <div>
                <label style={labelStyle}>Prev Hash:</label>
                <input
                  type="text"
                  value={block.prevHash}
                  readOnly
                  style={inputStyle}
                />
              </div>

              <div>
                <label style={labelStyle}>Hash:</label>
                <input
                  type="text"
                  value={block.hash}
                  readOnly
                  style={inputStyle}
                />
              </div>

              <div>
                <label style={labelStyle}>Timestamp:</label>
                <input
                  type="text"
                  value={block.timestamp}
                  readOnly
                  style={inputStyle}
                />
              </div>

              <button onClick={() => mineBlock(idx)} style={buttonStyle}>
                Minar
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlockchainSimulator;
