import React, { useState, useEffect } from 'react';
import Sidebar from '../components/NavBar/classSidebar';
import VideoPlayer from '../components/VideoComponents/VideoPlayer';

const Home = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    console.log("Video seleccionado en Home.js:", selectedVideo);
  }, [selectedVideo]);

  return (
    <div style={{ display: 'flex', width: '100vw', height: '100vh' }}>
      <div style={{ width: '20%', height: '100vh' }}>
        <Sidebar onSelect={setSelectedVideo} />
      </div>
      <div style={{ width: '80%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {selectedVideo ? (
          <VideoPlayer videoUrl={selectedVideo.videoUrl} />
        ) : (
          <h2>Seleccione un video para reproducir</h2>
        )}
      </div>
    </div>
  );
};

export default Home;
