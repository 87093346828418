import React, { useState } from 'react';
import SHA256 from 'crypto-js/sha256';
import MD5 from 'crypto-js/md5';
import SHA3 from 'crypto-js/sha3';

const algorithmInfo = {
  'SHA-256': {
    name: 'SHA-256',
    networks: ['Bitcoin', 'Bitcoin Cash'],
    generate: (input) => SHA256(input).toString(),
    description: 'Salida de 256 bits en hexadecimal. Usado por Bitcoin y Bitcoin Cash.',
  },
  Ethash: {
    name: 'Ethash',
    networks: ['Ethereum (antes del merge)', 'Linea'],
    generate: (input) => 'Ethash: ' + SHA256(input).toString().substr(0, 64),
    description: 'Simulación de Ethash, basado en Keccak-256 en su base, usado en Ethereum y Linea (antes del cambio a PoS).',
  },
  Scrypt: {
    name: 'Scrypt',
    networks: ['Litecoin'],
    generate: (input) => 'Scrypt: ' + MD5(input).toString(),
    description: 'Simulación de Scrypt, conocido por su mayor consumo de memoria, utilizado en Litecoin.',
  },
  RandomX: {
    name: 'RandomX',
    networks: ['Monero'],
    generate: (input) => {
      const reversed = input.split('').reverse().join('');
      return 'RandomX: ' + SHA256(reversed).toString();
    },
    description: 'Simulación de RandomX, optimizado para CPU en minería de Monero.',
  },
  'Keccak-256': {
    name: 'Keccak-256',
    networks: ['Ethereum (para firmas y direcciones)', 'Linea'],
    generate: (input) => 'Keccak-256: ' + SHA3(input, { outputLength: 256 }).toString(),
    description: 'Simulación de Keccak-256, utilizado en Ethereum para la generación de direcciones y firmas.',
  },
  PolygonPoS: {
    name: 'PolygonPoS',
    networks: ['Polygon (Matic)'],
    generate: (input) => 'PolygonPoS: ' + SHA256(input).toString().substr(0, 64),
    description: 'Simulación de un algoritmo de hash para la cadena Proof-of-Stake de Polygon (demo).',
  },
  DummyHash: {
    name: 'DummyHash',
    networks: [
      'Binance Smart Chain',
      'Avalanche',
      'Solana',
      'Cardano',
      'Ripple',
      'Tron',
      'EOS',
      'Tezos'
    ],
    generate: (input) => 'Dummy: ' + MD5(input).toString(),
    description: 'Simulación de un algoritmo de hash genérico para diversas redes blockchain.',
  },
};

const BlockchainSimulator = () => {
  const [input, setInput] = useState('');
  const [selectedAlg, setSelectedAlg] = useState('SHA-256');
  const [hash, setHash] = useState('');

  const generateHash = () => {
    const algorithm = algorithmInfo[selectedAlg];
    const result = algorithm.generate(input);
    setHash(result);
  };

  // Estilos sobrios y limpios
  const containerStyle = {
    maxWidth: '600px',
    margin: '50px auto',
    padding: '20px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif'
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333'
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '8px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#333'
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '15px',
    fontSize: '14px'
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer'
  };

  const resultStyle = {
    marginTop: '20px',
    padding: '10px',
    borderRadius: '4px',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    color: '#333',
    fontWeight: 'bold'
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Simulación de Algoritmos de Hash</h2>

      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="algorithmSelect" style={labelStyle}>
          Selecciona el algoritmo:
        </label>
        <select
          id="algorithmSelect"
          value={selectedAlg}
          onChange={(e) => setSelectedAlg(e.target.value)}
          style={inputStyle}
        >
          {Object.keys(algorithmInfo).map((alg) => (
            <option key={alg} value={alg}>
              {algorithmInfo[alg].name}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="dataInput" style={labelStyle}>
          Data:
        </label>
        <textarea
          id="dataInput"
          rows="4"
          cols="50"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={inputStyle}
          placeholder="Ingresa los datos aquí"
        />
      </div>

      <button onClick={generateHash} style={buttonStyle}>
        Generar Hash
      </button>

      <div style={{ marginTop: '20px' }}>
        <strong style={{ fontSize: '16px', color: '#333' }}>
          {algorithmInfo[selectedAlg].name} Output:
        </strong>
        <span
          style={{
            display: 'inline-block',
            marginLeft: '8px',
            minWidth: '500px',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            fontSize: '14px',
            padding: '6px 0',
            color: '#333',
          }}
        >
          {hash || '0'.repeat(64)}
        </span>
      </div>

      <div style={{ marginTop: '20px' }}>
        <strong style={{ fontSize: '16px', color: '#333' }}>
          Redes que lo utilizan:
        </strong>
        <ul style={{ marginLeft: '20px', fontSize: '14px', color: '#333' }}>
          {algorithmInfo[selectedAlg].networks.map((red, index) => (
            <li key={index}>{red}</li>
          ))}
        </ul>
      </div>

      <div style={{ marginTop: '20px' }}>
        <em style={{ fontSize: '14px', color: '#555' }}>
          {algorithmInfo[selectedAlg].description}
        </em>
      </div>
    </div>
  );
};

export default BlockchainSimulator;
