import React from 'react';
import ReactMarkdown from 'react-markdown';

const ContentSection = ({ sections, language }) => {
  const speakText = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = language === 'es' ? 'es-ES' : 'en-US';
      speechSynthesis.cancel();
      speechSynthesis.speak(utterance);
    } else {
      alert('Tu navegador no soporta la síntesis de voz.');
    }
  };

  if (!Array.isArray(sections) || sections.length === 0) {
    return <p className="text-center text-gray-500">No hay contenido disponible.</p>;
  }

  return (
    <div style={{ width: '90%', margin: '0 auto', padding: '2rem' }}>
      {sections.map((section, index) => (
        <div key={index} id={`topic-${section.id || index}`} style={{ marginBottom: '3rem' }}>
          {/* Subtítulo de la sección */}
          {section.subtitle && (
            <h4
              style={{
                fontSize: '2rem',
                fontWeight: '600',
                color: '#000',
                textAlign: 'left',
                marginBottom: '1rem',
              }}
            >
              {section.subtitle}
            </h4>
          )}


          {/* Sección de imágenes */}
          {section.images && section.images.length > 0 && (
            <div
              style={{
                width: '80%',

                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              {section.images.map((imgUrl, i) => (
                <img
                  key={i}
                  src={imgUrl}
                  alt={`Imagen de la sección ${section.title || ''}`}
                  style={{
                    width: '100%',

                    height: 'auto',
                    borderRadius: '8px',
                    display: 'block',
                    marginBottom: '10px',
                  }}
                  onError={(e) => {
                    e.target.style.display = 'none';
                    console.error('Error al cargar la imagen:', imgUrl);
                  }}
                />
              ))}

            </div>
          )}



          {/* Contenido de la sección */}
          {section.content && section.content.length > 0 && (
            <div style={{ color: '#4a5568', textAlign: 'justify', lineHeight: '1.8', fontSize: '1.5rem' }}>
              {section.content.map((paragraph, idx) => (
                <div key={idx} style={{ marginBottom: '15px' }}>
                  <ReactMarkdown
                    components={{
                      img: ({ node, ...props }) => (
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                          <img {...props} style={{ width: '80%', maxWidth: '800px', height: 'auto', borderRadius: '8px' }} />
                        </div>
                      ),
                      code: ({ node, inline, ...props }) => (
                        inline ? (
                          // 🔹 Código en línea (Ejemplo: `000000000019d6689c085ae165831e93`)
                          <code style={{
                            backgroundColor: '#f4f4f4',
                            padding: '2px 6px',
                            borderRadius: '4px',
                            fontFamily: 'monospace',
                            color: '#d63384', // Color más resaltante para código en línea
                            fontWeight: 'bold',
                            fontSize: '1rem'
                          }}>
                            {props.children}
                          </code>
                        ) : (
                          // 🔹 Bloque de código completo (Ejemplo: ```Bloque 1 → Bloque 2 → Bloque 3 → ... → Bloque N```)
                          <pre style={{
                            backgroundColor: '#1e1e1e',
                            padding: '15px',
                            borderRadius: '8px',
                            overflowX: 'auto',
                            color: '#50fa7b', // Verde neón para bloques de código
                            fontSize: '1.2rem',
                            fontFamily: 'monospace'
                          }}>
                            <code {...props} />
                          </pre>
                        )
                      )
                    }}
                  >
                    {paragraph}
                  </ReactMarkdown>
                </div>
              ))}
            </div>
          )}


          {/* Renderizar las tablas de la sección */}
          {section.tables && section.tables.length > 0 && (
            <div>
              {section.tables.map((table, tableIndex) => (
                <table
                  key={tableIndex}
                  style={{
                    width: '100%',
                    fontSize: '1.125rem', // Tabla un poco más grande
                    borderCollapse: 'separate',
                    borderSpacing: '0 8px', // Espacio entre filas
                    margin: '0 auto',
                    marginBottom: '2rem',
                    borderRadius: '8px', // Bordes redondeados
                    overflow: 'hidden', // Para que no se “escape” el thead
                  }}
                >
                  {/* CABECERA */}
                  <thead
                    style={{
                      backgroundColor: '#333', // Fondo oscuro
                      color: '#fff', // Texto blanco
                      border: 'none', // Sin líneas
                    }}
                  >
                    <tr>
                      {table[0].split('|').map((header, idx) => (
                        <th
                          key={idx}
                          style={{
                            padding: '12px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            border: 'none', // Sin líneas
                          }}
                        >
                          {header.trim()}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  {/* CUERPO CON “STRIPING” */}
                  <tbody>
                    {table.slice(1).map((row, rowIndex) => {
                      // Alterna fondos (gris claro / gris más claro):
                      const bgColor = rowIndex % 2 === 0 ? '#f2f2f2' : '#e6e6e6';
                      return (
                        <tr key={rowIndex} style={{ backgroundColor: bgColor }}>
                          {row.split('|').map((cell, idx) => (
                            <td
                              key={idx}
                              style={{
                                padding: '12px',
                                textAlign: 'center',
                                border: 'none', // Sin líneas
                              }}
                            >
                              {cell.trim()}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ))}
            </div>
          )}

          {/* Puntos destacados */}
          {section.points && section.points.length > 0 && (
            <ul
              style={{
                listStyleType: 'disc',
                paddingLeft: '1.5rem',
                marginTop: '1rem',
                fontSize: '1.125rem',
              }}
            >
              {section.points.map((point, idx) => (
                <li key={idx} style={{ marginBottom: '0.75rem' }}>
                  {point}
                </li>
              ))}
            </ul>
          )}

          {/* Enlaces de la sección */}
          {section.links && section.links.length > 0 && (
            <div style={{ marginTop: '1rem' }}>
              <h4
                style={{
                  fontSize: '1.25rem',
                  fontWeight: '600',
                  marginBottom: '0.75rem',
                }}
              >
                Referencias:
              </h4>
              <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                {section.links.map((link, idx) => (
                  <li key={idx} style={{ marginBottom: '0.75rem' }}>
                    🔗{' '}
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: '#007bff',
                        textDecoration: 'none',
                        fontSize: '1.125rem',
                      }}
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ContentSection;
