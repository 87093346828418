import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaBook } from "react-icons/fa";
import styled from 'styled-components';
import BTC from '../components/Graphs/BTC';
import Billeteras from './TradingTerminal';

const SidebarContainer = styled.div`
  position: fixed;
  top: 4.5vw;
  left: 0;
  height: 100vh;
  width: ${(props) => (props.isCollapsed ? '80px' : '260px')};
  background: #fff;
  color: #333;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  z-index: 1000;
  overflow: hidden;

  @media (max-width: 768px) {
    top: 10vh;
    height: calc(100vh - 10vh);
  }
`;

const SidebarToggle = styled.div`
  position: absolute;
  right: -10px;
  top: 20px;
  background: white;
  color: #007cc2;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f0f0f0;
  }
`;

const SidebarHeader = styled.div`
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
`;

const SidebarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  h3 {
    color: #007cc2;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
  }
`;

const SidebarMenu = styled.nav`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 12px;
    font-size: 16px;
    transition: 0.3s;

    &:hover {
      background: #f1f1f1;
      border-radius: 5px;
    }
  }
`;

const MenuItem = styled.li`
  a {
    background: ${(props) => (props.active ? '#e6f4f8' : 'transparent')};
    border-radius: 5px;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    color: ${(props) => (props.active ? '#007cc2' : '#333')};
  }
`;

const MainContent = styled.main`
  margin-left: ${(props) => (props.isSidebarCollapsed ? '80px' : '260px')};
  padding: 20px;
  box-sizing: border-box;
  transition: margin-left 0.3s ease-in-out;
`;

const MainComponent = () => {
  const [selectedOption, setSelectedOption] = useState('terminal');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Elementos del menú lateral
  const menuItems = [
    { label: 'Terminal', key: 'terminal' },
    { label: 'BTC', key: 'BTC Chart' },
  ];

  const handleOptionSelect = (optionKey) => {
    setSelectedOption(optionKey);
  };

  const handleToggleSidebar = (collapsed) => {
    setIsSidebarCollapsed(collapsed);
    // Forzamos un redimensionado para que las gráficas se ajusten al nuevo tamaño
    window.dispatchEvent(new Event('resize'));
  };

  const renderSidebar = () => (
    <SidebarContainer isCollapsed={isSidebarCollapsed}>
      <SidebarToggle onClick={() => handleToggleSidebar(!isSidebarCollapsed)}>
        {isSidebarCollapsed ? <FiChevronRight size={24} /> : <FiChevronLeft size={24} />}
      </SidebarToggle>

      <SidebarHeader>
        <SidebarLogo>
          <FaBook size={30} />
          {!isSidebarCollapsed && <h3>Simulador</h3>}
        </SidebarLogo>
      </SidebarHeader>

      <SidebarMenu>
        <ul>
          {menuItems.map((item) => (
            <MenuItem key={item.key} active={selectedOption === item.key}>
              <a href="#" onClick={() => handleOptionSelect(item.key)}>
                {isSidebarCollapsed ? item.label.charAt(0) : item.label}
              </a>
            </MenuItem>
          ))}
        </ul>
      </SidebarMenu>
    </SidebarContainer>
  );

  return (
    <div style={{ minHeight: '100vh' }}>
      {renderSidebar()}
      <MainContent isSidebarCollapsed={isSidebarCollapsed}>
        {selectedOption === 'terminal' && <Billeteras />}
        {selectedOption === 'BTC Chart' && <BTC />}
      </MainContent>
    </div>
  );
};

export default MainComponent;
