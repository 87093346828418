import React, { useState } from 'react';
import SHA256 from 'crypto-js/sha256';

const BlockSimulator = () => {
  const [blockNumber, setBlockNumber] = useState(1);
  const [difficulty, setDifficulty] = useState(1);
  const [data, setData] = useState('');
  const [nonce, setNonce] = useState(0);
  const [hash, setHash] = useState('');
  const [timestamp, setTimestamp] = useState('');

  // Calcula el hash usando blockNumber, nonce y data
  const computeHash = (blkNum, nonceVal, blockData) => {
    return SHA256(`${blkNum}${nonceVal}${blockData}`).toString();
  };

  // Función de "minado" que busca un nonce válido
  const mineBlock = () => {
    let localNonce = 0;
    let newHash = computeHash(blockNumber, localNonce, data);
    const targetPrefix = '0'.repeat(difficulty);
    while (!newHash.startsWith(targetPrefix)) {
      localNonce++;
      newHash = computeHash(blockNumber, localNonce, data);
    }
    setNonce(localNonce);
    setHash(newHash);
    setTimestamp(new Date().toLocaleString());
  };

  // Estilos base para cada fila (label + input/textarea)
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    alignItems: 'center',
    marginBottom: '10px'
  };

  // Estilo para los labels
  const labelStyle = {
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333'
  };

  // Estilo base para inputs y textarea
  const inputStyle = {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '4px'
  };

  // Estilo minimalista para el botón
  const buttonStyle = {
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px'
  };

  // Estilo para la sección de bloque
  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    margin: '20px 0'
  };

  // Estilo para el contenedor principal
  const containerStyle = {
    maxWidth: '600px',
    margin: '0 auto',
    marginTop: '10vh',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
    color: '#333',
    fontFamily: 'Arial, sans-serif'
  };

  return (
    <div style={containerStyle}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Simulador de Bloque</h2>
      <div style={rowStyle}>
        <label style={labelStyle}>Dificultad</label>
        <input
          type="number"
          value={difficulty}
          min="0"
          onChange={(e) => setDifficulty(Math.max(0, Number(e.target.value)))}
          style={inputStyle}
        />
      </div>
      <div style={cardStyle}>
        <div style={rowStyle}>
          <label style={labelStyle}>Block #</label>
          <input
            type="number"
            value={blockNumber}
            onChange={(e) => setBlockNumber(Number(e.target.value))}
            style={inputStyle}
          />
        </div>
        <div style={rowStyle}>
          <label style={labelStyle}>Data</label>
          <textarea
            rows="4"
            value={data}
            onChange={(e) => setData(e.target.value)}
            style={inputStyle}
          />
        </div>
        <div style={rowStyle}>
          <label style={labelStyle}>Nonce</label>
          <input
            type="number"
            value={nonce}
            readOnly
            style={inputStyle}
          />
        </div>
        <div style={rowStyle}>
          <label style={labelStyle}>Hash</label>
          <input
            type="text"
            value={hash}
            readOnly
            style={inputStyle}
          />
        </div>
        <div style={rowStyle}>
          <label style={labelStyle}>Marca de tiempo</label>
          <input
            type="text"
            value={timestamp}
            readOnly
            style={inputStyle}
          />
        </div>
      </div>
      <button onClick={mineBlock} style={buttonStyle}>
        Mine
      </button>
    </div>
  );
};

export default BlockSimulator;
