import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

const CourseCard = () => {
  return (
    <div className="course-card">
      <div className="card-section">
        <CalendarTodayIcon className="card-icon" style={{ color: '#0b5465' }} />
        <div className="card-text">
          <h3>Tiempo de apertura del curso:</h3>
          <p>12 semanas</p>
        </div>
      </div>
      <div className="card-section">
        <AccessTimeIcon className="card-icon" style={{ color: '#0b5465' }} />
        <div className="card-text">
          <h3>Tiempo total:</h3>
          <p>
            114 horas lectivas<br />
            distribuidas en 70 horas de formación sobre Blockchain, 24 horas sincrónicas y 20 horas acerca de neuroeconomía.
          </p>
        </div>
      </div>
      <div className="card-section">
        <SmartphoneIcon className="card-icon" style={{ color: '#0b5465' }} />
        <div className="card-text">
          <h3>Modalidad</h3>
          <p>Asesorado</p>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
