import React, { useState } from 'react';
import SHA256 from 'crypto-js/sha256';

const TxChainMultiple = () => {
  const [blocks, setBlocks] = useState([
    {
      index: 1,
      nonce: 0,
      transactions: [
        { amount: 25.0, from: "Darcy", to: "Bingley" },
        { amount: 10.5, from: "Alice", to: "Bob" }
      ],
      prevHash: "0", // Bloque génesis
      hash: "",
      difficulty: 2
    },
    {
      index: 2,
      nonce: 0,
      transactions: [
        { amount: 4.77, from: "Eric", to: "Lydia" },
        { amount: 8.88, from: "Harker", to: "Dallas" }
      ],
      prevHash: "",
      hash: "",
      difficulty: 2
    },
    {
      index: 3,
      nonce: 0,
      transactions: [
        { amount: 15.0, from: "Wickham", to: "Lady Catherine" }
      ],
      prevHash: "",
      hash: "",
      difficulty: 2
    }
  ]);

  // Calcula el hash del bloque a partir de sus propiedades
  const computeHash = (block) => {
    const { index, prevHash, nonce, transactions } = block;
    // Para que sea sencillo, concatenamos la info de las transacciones en un string
    const txString = transactions
      .map((tx) => `${tx.amount}${tx.from}${tx.to}`)
      .join("");
    return SHA256(`${index}${prevHash}${nonce}${txString}`).toString();
  };

  // Verifica si un bloque es válido
  // 1) Si es el bloque génesis, prevHash debe ser "0" y hash debe coincidir
  // 2) Bloques posteriores: prevHash coincide con el hash del anterior + hash actual es correcto
  const isBlockValid = (block, i, chain) => {
    if (i === 0) {
      return block.prevHash === "0" && block.hash === computeHash(block);
    } else {
      const prevBlock = chain[i - 1];
      if (block.prevHash !== prevBlock.hash) return false;
      if (block.hash !== computeHash(block)) return false;
      return true;
    }
  };

  // Función para minar un bloque específico
  const mineBlock = (i) => {
    const updated = [...blocks];
    const blockToMine = { ...updated[i] };

    const targetPrefix = "0".repeat(blockToMine.difficulty);

    let newNonce = blockToMine.nonce;
    blockToMine.nonce = newNonce;

    let newHash = computeHash(blockToMine);

    // Aumenta el nonce hasta que el hash inicie con la cantidad de ceros requerida
    while (!newHash.startsWith(targetPrefix)) {
      blockToMine.nonce++;
      newHash = computeHash(blockToMine);
    }

    blockToMine.hash = newHash;
    updated[i] = blockToMine;

    // Si no es el último bloque, actualiza el prevHash del siguiente
    if (i < updated.length - 1) {
      updated[i + 1].prevHash = newHash;
    }

    setBlocks(updated);
  };

  // Maneja la modificación de una transacción en un bloque
  const handleTxChange = (blockIndex, txIndex, field, value) => {
    const updated = [...blocks];
    updated[blockIndex].transactions[txIndex][field] = value;
    setBlocks(updated);
  };

  // Agrega una transacción vacía en un bloque
  const addTransaction = (blockIndex) => {
    const updated = [...blocks];
    updated[blockIndex].transactions.push({ amount: 0, from: "", to: "" });
    setBlocks(updated);
  };

  // Estilos básicos
  const containerStyle = {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: "20px"
  };

  // Bloque se pone en rojo si es inválido
  const getBlockStyle = (invalid) => ({
    width: "320px",
    backgroundColor: invalid ? "#ffd2d2" : "#ffeaea",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "16px"
  });

  const labelStyle = {
    fontWeight: "bold",
    marginBottom: "4px",
    display: "block"
  };

  const inputStyle = {
    width: "100%",
    marginBottom: "8px",
    padding: "6px",
    border: "1px solid #ccc",
    borderRadius: "4px"
  };

  const buttonStyle = {
    padding: "6px 12px",
    cursor: "pointer",
    backgroundColor: "#008CBA",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    marginTop: "8px"
  };

  const txTableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "10px"
  };

  const txThTdStyle = {
    border: "1px solid #aaa",
    padding: "6px",
    textAlign: "left"
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Simulación de Bloques con Múltiples Transacciones</h1>
      <div style={containerStyle}>
        {blocks.map((block, i) => {
          const invalid = !isBlockValid(block, i, blocks);
          return (
            <div key={block.index} style={getBlockStyle(invalid)}>
              <div>
                <label style={labelStyle}>Block #{block.index}</label>
              </div>

              <div>
                <label style={labelStyle}>Nonce:</label>
                <input
                  type="number"
                  value={block.nonce}
                  readOnly
                  style={inputStyle}
                />
              </div>

              <div>
                <label style={labelStyle}>Transacciones:</label>
                <table style={txTableStyle}>
                  <thead>
                    <tr>
                      <th style={txThTdStyle}>Monto</th>
                      <th style={txThTdStyle}>De</th>
                      <th style={txThTdStyle}>Para</th>
                    </tr>
                  </thead>
                  <tbody>
                    {block.transactions.map((tx, txIdx) => (
                      <tr key={txIdx}>
                        <td style={txThTdStyle}>
                          <input
                            type="number"
                            value={tx.amount}
                            onChange={(e) =>
                              handleTxChange(i, txIdx, "amount", e.target.value)
                            }
                            style={{ width: "70px" }}
                          />
                        </td>
                        <td style={txThTdStyle}>
                          <input
                            type="text"
                            value={tx.from}
                            onChange={(e) =>
                              handleTxChange(i, txIdx, "from", e.target.value)
                            }
                            style={{ width: "80px" }}
                          />
                        </td>
                        <td style={txThTdStyle}>
                          <input
                            type="text"
                            value={tx.to}
                            onChange={(e) =>
                              handleTxChange(i, txIdx, "to", e.target.value)
                            }
                            style={{ width: "80px" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  onClick={() => addTransaction(i)}
                  style={{ ...buttonStyle, backgroundColor: "#4CAF50" }}
                >
                  + Agregar Tx
                </button>
              </div>

              <div>
                <label style={labelStyle}>Prev Hash:</label>
                <textarea
                  rows={2}
                  value={block.prevHash}
                  readOnly
                  style={{ ...inputStyle, fontSize: "12px" }}
                />
              </div>

              <div>
                <label style={labelStyle}>Hash:</label>
                <textarea
                  rows={2}
                  value={block.hash}
                  readOnly
                  style={{ ...inputStyle, fontSize: "12px" }}
                />
              </div>

              <button onClick={() => mineBlock(i)} style={buttonStyle}>
                Minar
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TxChainMultiple;
