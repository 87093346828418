import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #000;
  font-family: Arial, sans-serif;
  line-height: 1.8;
  text-align: justify; /* Justificar el texto */
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  font-size: 1.5rem;
  color: #000;
  font-weight: bold; /* Negritas para los subtítulos */
  margin: 20px 0;
`;

const Text = styled.p`
  font-size: 1.1rem;
  margin: 10px 0;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #000;
`;

const List = styled.ul`
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px; /* Espaciado desde el borde */
  text-align: left; /* Alinear el texto de la lista a la izquierda */
`;

const ListItem = styled.li`
  font-size: 1.1rem;
  margin-bottom: 10px;
`;

const CollaborationCard = () => {
  return (
    <Container>
      <SubTitle>¿Quiénes somos y qué hacemos?</SubTitle>
      <Text>
        La <Highlight>Universidad Virtual del Estado de Guanajuato (UVEG)</Highlight>, <Highlight>EDUCA</Highlight> y 
        <Highlight> CoachBlockchain</Highlight> se han unido para crear una alianza estratégica que revoluciona la educación 
        en Blockchain, transformación digital y tecnologías emergentes. Esta colaboración impulsa programas innovadores 
        diseñados para satisfacer las demandas de un mercado global en constante evolución.
      </Text>
      <Text>Juntos, ofrecemos certificaciones de clase mundial y programas de formación enfocados en áreas como:</Text>
      <List>
        <ListItem>Blockchain y Transformación Digital Empresarial</ListItem>
        <ListItem>Gestión de Proyectos Digitales y Marketing Digital</ListItem>
        <ListItem>Tokenización de Activos y Ecosistemas Financieros Descentralizados (DeFi)</ListItem>
      </List>
      <SubTitle>Misión de la Colaboración</SubTitle>
      <Text>
        La misión de esta alianza es crear una nueva generación de líderes tecnológicos y profesionales que dominen 
        las herramientas digitales, aprovechen las oportunidades que ofrece Blockchain y conduzcan a las empresas hacia 
        un futuro digital sostenible. Nuestros programas destacan por su enfoque en:
      </Text>
      <List>
        <ListItem>
          <Highlight>Formación práctica y aplicada:</Highlight> Diseñada para resolver problemas reales utilizando 
          tecnologías como Inteligencia Artificial (IA), Realidad Aumentada (AR), Aprendizaje Automático (ML) y Blockchain.
        </ListItem>
        <ListItem>
          <Highlight>Crecimiento organizacional:</Highlight> Un enfoque innovador que combina análisis de datos, 
          experimentación rápida y optimización de procesos para lograr un rendimiento empresarial excepcional.
        </ListItem>
      </List>
      <SubTitle>Educación para el Futuro</SubTitle>
      <Text>
        Nuestros programas educativos, como el certificado en <Highlight>Blockchain y Finanzas Descentralizadas</Highlight>, 
        están diseñados para proporcionar habilidades analíticas, creativas y técnicas, necesarias para prosperar en la  <Highlight>era de economías digitales</Highlight> y ecosistemas centrados en el usuario.
      </Text>
      <Text>
        Las habilidades que nuestros alumnos adquieren no solo son <Highlight>altamente demandadas</Highlight>, sino que 
        también les permiten destacar en un mercado competitivo. Organizaciones globales ya están integrando estos 
        conocimientos para optimizar sus operaciones y alcanzar nuevos niveles de eficiencia automatizada.
      </Text>
      <SubTitle>Nuestro Compromiso</SubTitle>
      <Text>
        Con esta colaboración, <Highlight>UVEG</Highlight>, <Highlight>EDUCA</Highlight> y <Highlight>CoachBlockchain</Highlight>  garantizan un entorno educativo de excelencia, donde estudiantes y profesionales pueden desarrollar las competencias 
        necesarias para liderar en áreas como:
      </Text>
      <List>
        <ListItem>Ecosistemas financieros digitales</ListItem>
        <ListItem>Economías basadas en datos</ListItem>
        <ListItem>Mercados centrados en el usuario</ListItem>
      </List>
      <Text>
        El futuro de la educación y la innovación tecnológica está aquí. Únete a nuestra comunidad y forma parte del cambio 
        que está transformando al mundo.
      </Text>
    </Container>
  );
};

export default CollaborationCard;
