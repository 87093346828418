import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 300px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 250px; /* Asegura que todas las cartas tengan la misma altura */
  flex: 1; /* Asegura que todas las cartas se ajusten igualmente */

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 10px;
  display: block; /* Para que se centre el logo dentro de la carta */
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555555;
  line-height: 1.5;
  text-align: center;
  flex-grow: 1;
`;

const LogoCards = () => {
  const logos = [
    {
      img: "https://d3t7lhga1ksl9p.cloudfront.net/gtoeducador/assets/images/logouvego.webp",
      title: "",
      description:
        "Universidad Virtual del Estado de Guanajuato, líder en educación virtual en México.",
    },
    {
      img: "https://raw.githubusercontent.com/AppsDevsLeon/Logos/refs/heads/main/Escudo-IE.png",
      title: "",
      description:"Institución educativa que promueve el aprendizaje continuo y la innovación.",
        
    },
    {
      img: "https://raw.githubusercontent.com/AppsDevsLeon/Logos/refs/heads/main/logo1.png",
      title: "",
      description: "Plataforma especializada en formación y proyectos blockchain de alto impacto.",
    },
  
  ];

  return (
    <CardContainer>
      {logos.map((logo, index) => (
        <Card key={index}>
          <Logo src={logo.img} alt={`${logo.title} logo`} />
          <Title>{logo.title}</Title>
          <Description>{logo.description}</Description>
        </Card>
      ))}
    </CardContainer>
  );
};

export default LogoCards;
