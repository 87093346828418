import React, { useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import gsap from "gsap";

// Cubo individual con animación GSAP y material metálico
const CubePiece = ({ position, delay }) => {
  const cubeRef = useRef();

  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1, delay });

    // Paso 1: Aparece con 5% de ancho
    timeline.set(cubeRef.current.scale, { x: 0.05, y: 0.05, z: 0.05 });

    // Paso 2: Baja y expande al 100% de tamaño
    timeline.to(cubeRef.current.scale, { x: 1, y: 1, z: 1, duration: 1.5, ease: "power2.out" }, 0);
    timeline.to(cubeRef.current.position, { y: position[1], duration: 1.5, ease: "power2.out" }, 0);

    // Paso 3: Sube y rota 180 grados
    timeline.to(cubeRef.current.position, { y: position[1] + 1, duration: 1.5, ease: "power2.inOut" }, "+=1");
    timeline.to(cubeRef.current.rotation, { y: Math.PI, duration: 1.5, ease: "power2.inOut" }, "-=1");

    // Paso 4: Regresa a la posición original y repite
    timeline.to(cubeRef.current.position, { y: position[1], duration: 1.5, ease: "power2.inOut" }, "+=0.5");
    timeline.to(cubeRef.current.rotation, { y: Math.PI * 2, duration: 1.5, ease: "power2.inOut" }, "-=1");
  }, [position, delay]);

  return (
    <mesh ref={cubeRef} position={position} castShadow receiveShadow>
      <boxGeometry args={[0.9, 0.9, 0.9]} />
      <meshPhysicalMaterial
        color="#888"
        metalness={1}   // Totalmente metálico
        roughness={0.3} // Algo de brillo
        clearcoat={1}   // Añade una capa reflectante
        clearcoatRoughness={0.1} // Suaviza los reflejos
      />
    </mesh>
  );
};

// Grupo de cubos metálicos animados
const MetallicCubes = () => {
  const gridSize = 3;
  return (
    <group>
      {Array.from({ length: gridSize }, (_, x) =>
        Array.from({ length: gridSize }, (_, y) =>
          Array.from({ length: gridSize }, (_, z) => {
            const delay = (x + y + z) * 0.1;
            return (
              <CubePiece key={`${x}-${y}-${z}`} position={[x - 1, y - 1, z - 1]} delay={delay} />
            );
          })
        )
      )}
    </group>
  );
};

// Escena completa con ambiente metálico
const Scene = () => {
  return (
    <Canvas shadows camera={{ position: [5, 5, 5], fov: 50 }}>
      <ambientLight intensity={0.5} />
      <spotLight position={[5, 5, 5]} angle={0.3} penumbra={1} intensity={2} castShadow />
      <MetallicCubes />
      <OrbitControls />
      <Environment preset="city" /> {/* Agrega reflejos urbanos */}
    </Canvas>
  );
};

export default Scene;
