import React from "react";
import "./CustomButton.css"; // Importamos el archivo de estilos

const CustomButton = ({ text, size = "medium", onClick }) => {
  return (
    <button className={`custom-button ${size}`} onClick={onClick}>
      {text}
    </button>
  );
};

export default CustomButton;
