import React, { useState } from "react";
import { ethers } from "ethers";
import useContract from "../../hooks/useContract";

const ContractManager = () => {
    const { contract, account, connectWallet } = useContract();
    const [balance, setBalance] = useState(null);
    const [email, setEmail] = useState("");

    const getBalance = async () => {
        if (!contract) return;
        const balanceWei = await contract.getContractBalance();
        const balanceEth = ethers.utils.formatEther(balanceWei);
        setBalance(balanceEth);
    };

    const linkEmail = async () => {
        if (!contract || !email) return;
        try {
            
            const tx = await contract.linkEmailToWallet(email);
          
            await tx.wait();
            alert(`Email ${email} vinculado con éxito`);
        } catch (error) {
            console.error("Error al vincular email:", error);
            alert(`Error al vincular email: ${error.data?.message || error.message || "Verifica la consola."}`);
        }
    };
    
    

    const styles = {
        container: {
            padding: "20px",
            textAlign: "center",
            background: "#f8f9fa",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            maxWidth: "600px",
            margin: "40px auto",
        },
        title: {
            fontSize: "24px",
            fontWeight: "bold",
            color: "#005f73",
            marginBottom: "30px", // Added margin for separation
        },
        button: {
            backgroundColor: "#0A5364",
            color: "white",
            padding: "10px 15px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            transition: "background 0.3s ease-in-out",
            margin: "10px 0", // Added margin for spacing
        },
        buttonHover: {
            backgroundColor: "#006b6b",
        },
        input: {
            padding: "8px",
            border: "2px solid #0A5364",
            borderRadius: "5px",
            width: "80%",
            fontSize: "16px",
            transition: "border-color 0.3s",
            marginBottom: "15px", // Added margin for separation between input and button
        },
        inputFocus: {
            borderColor: "#005f73",
            outline: "none",
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Gestor de Contrato</h2>
            {!account ? (
                <button style={styles.button} onClick={connectWallet}>
                    Conectar Wallet
                </button>
            ) : (
                <p style={{ fontSize: "16px", color: "#333", marginTop: "10px" }}>
                    Wallet conectada: <span style={{ fontWeight: "bold", color: "#00796b" }}>{account}</span>
                </p>
            )}

            <div style={{ marginTop: "20px" }}>
                <button style={styles.button} onClick={getBalance}>
                    Obtener Balance del Contrato
                </button>
                {balance && <p style={{ fontSize: "18px", color: "#00796b" }}>Balance del Contrato: <span>{balance} MATIC</span></p>}
            </div>

            <div style={{ marginTop: "20px" }}>
                <input 
                    type="email" 
                    placeholder="Ingresa tu email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                    onFocus={(e) => e.target.style.borderColor = styles.inputFocus.borderColor}
                    onBlur={(e) => e.target.style.borderColor = styles.input.border}
                />
                <button 
                    style={{ ...styles.button, marginLeft: "10px" }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = styles.buttonHover.backgroundColor}
                    onMouseLeave={(e) => e.target.style.backgroundColor = styles.button.backgroundColor}
                    onClick={linkEmail}
                >
                    Vincular Email
                </button>
            </div>
        </div>
    );
};

export default ContractManager;
