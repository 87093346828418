import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useCursor, MeshReflectorMaterial, Image, Environment } from '@react-three/drei';
import { useRoute, useLocation } from 'wouter';
import { easing } from 'maath';
import getUuid from 'uuid-by-string';
import TextOverlay from '../Reusable/TextOverlay';

const GOLDENRATIO = 1.6;

export const NFTGallery = ({ images }) => (
  <div id="app" style={{ position: 'relative' }}>
    <Canvas
      style={{ height: '95vh', width: '100%', marginTop: '0', zIndex: 0 }}
      dpr={[1, 1.5]}
      camera={{ fov: 70, position: [0, 2, 15] }}>
      <color attach="background" args={['#F5F5F5']} />
      <fog attach="fog" args={['#F5F5F5', 0, 15]} />
      <group position={[0, -0.5, 0]}>
        <Frames images={images} />
        <mesh rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[120, 120]} />
          <MeshReflectorMaterial color="#F5F5F5" />
        </mesh>
      </group>
      <Environment preset="city" />
    </Canvas>

    {/* Texto grande en la parte inferior centrado */}
    <TextOverlay 
      title="El valor de la Tecnología Blockchain" 
      subtitle="Conoce la Tecnología Blockchain y el Arte Digital formándote con los mejores profesionales."
      style={{ bottom: '80px' }}
    />
  </div>
);

function Frames({ images, q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
  const ref = useRef();
  const clicked = useRef();
  const [, params] = useRoute('/item/:id');
  const [, setLocation] = useLocation();

  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id);
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true);
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25));
      clicked.current.parent.getWorldQuaternion(q);
    } else {
      p.set(0, 0, 5.5);
      q.identity();
    }
  });

  useFrame((state, dt) => {
    easing.damp3(state.camera.position, p, 0.4, dt);
    easing.dampQ(state.camera.quaternion, q, 0.4, dt);
  });

  return (
    <group
      ref={ref}
      onClick={(e) => (e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} />)}
    </group>
  );
}

function Frame({ url, c = new THREE.Color(), ...props }) {
  const image = useRef();
  const frame = useRef();
  const [, params] = useRoute('/item/:id');
  const [hovered, hover] = useState(false);
  const [rnd] = useState(() => Math.random());
  const name = getUuid(url);
  const isActive = params?.id === name;

  useCursor(hovered);

  useFrame((state, dt) => {
    image.current.material.zoom = 1.5 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2;
    easing.damp3(
      image.current.scale,
      [0.85 * (!isActive && hovered ? 0.85 : 1), 0.85 * (!isActive && hovered ? 1.2 : 1), 1],
      0.1,
      dt
    );
    easing.dampC(frame.current.material.color, hovered ? '#C8D1D1' : '#C8D1D1', 0.1, dt);
  });

  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1.5, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2.0, 0]}>
        <boxGeometry />
        <meshStandardMaterial color="#000000" metalness={0.5} roughness={0.1} envMapIntensity={2} />
        
        {/* Marco de la imagen */}
        <mesh ref={frame} raycast={() => null} scale={[0.9, 0.9, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>

        {/* Imagen */}
        <Image
          raycast={() => null}
          ref={image}
          position={[0, 0, 0.7]}
          scale={[0.001, 0.001, 0.01]}
          url={url}
        />
      </mesh>
    </group>
  );
}

export default NFTGallery;
