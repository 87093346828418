import React, { useState, useEffect } from 'react';
import Gallery from '../components/FiberElements/Gallery';

// URLs base de las tres colecciones
const collection1BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/AquaCollection/refs/heads/main/';
const collection2BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/ImagenesEar/refs/heads/main/';
const collection3BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/TerraImages/refs/heads/main/';

// Función para generar un ID aleatorio de cada colección
const getRandomImageUrl = () => {
  const totalWeight = 50 + 40 + 10;
  const random = Math.random() * totalWeight;

  if (random < 50) {
    const id = Math.floor(Math.random() * (10280 - 10181 + 1)) + 10181;
    return `${collection3BaseUrl}${id}.png`;
  } else if (random < 50 + 40) {
    const id = Math.floor(Math.random() * (140 - 47 + 1)) + 47;
    return `${collection1BaseUrl}${id}.png`;
  } else {
    const id = Math.floor(Math.random() * (97 - 1 + 1)) + 1;
    return `${collection2BaseUrl}${id}.png`;
  }
};

const Home = ({ marketplace, nft }) => {
  const [loading, setLoading] = useState(true);
  const [images] = useState([
    { position: [0, 0, 1.5], rotation: [0, 0, 0], url: getRandomImageUrl() },
    { position: [-0.8, 0, -0.8], rotation: [0, 0, 0], url: getRandomImageUrl() },
    { position: [0.8, 0, -0.8], rotation: [0, 0, 0], url: getRandomImageUrl() },
    { position: [-2, 0, -0.1], rotation: [0, Math.PI / 2.5, 0], url: getRandomImageUrl() },
    { position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: getRandomImageUrl() },
    { position: [-2, 0, 2.8], rotation: [0, Math.PI / 2.5, 0], url: getRandomImageUrl() },
    { position: [2, 0, -0.1], rotation: [0, -Math.PI / 2.5, 0], url: getRandomImageUrl() },
    { position: [2.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: getRandomImageUrl() },
    { position: [2, 0, 2.8], rotation: [0, -Math.PI / 2.5, 0], url: getRandomImageUrl() }
  ]);

  const loadMarketplaceItem = async () => {
    try {
      if (!marketplace) {
        setLoading(false);
        return;
      }
      const itemCount = await marketplace.itemCount();
      if (!itemCount) {
        setLoading(false);
        return;
      }
      for (let i = 1; i <= itemCount; i++) {
        const item = await marketplace.items(i);
        if (!item.sold) {
          const uri = await nft.tokenURI(item.tokenId);
          const response = await fetch(uri);
          if (!response.ok) continue;
          // const metadata = await response.json();
          // const totalPrice = await marketplace.getTotalPrice(item.itemId);
          // Si necesitases guardar esos datos, podrías almacenarlos en un estado.
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMarketplaceItem();
  }, []);

  if (loading) {
    return (
      <main
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '15vh',           // El margen superior de 15vh
          minHeight: 'calc(100vh - 15vh)', // Así mantenemos altura para que no pise el footer
          boxSizing: 'border-box',
          padding: '1rem'
        }}
      >
        <Gallery images={images} />
        <p style={{ textAlign: 'center', marginTop: '1rem' }}>Cargando...</p>
      </main>
    );
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box'
      }}
    >
      <main
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '15vh',            // Margen superior para separar del header
          minHeight: 'calc(100vh - 15vh)', // Ajustar la altura para no pisar footer
          boxSizing: 'border-box',
          padding: '1rem'
        }}
      >
        <Gallery images={images} />
      </main>
    </div>
  );
};

export default Home;
