import { ethers } from 'ethers';
import contractData from '../utils/contractABI.json'; // Importa la ABI

const contractAddress = contractData.contractAddress;
const contractABI = contractData.contractABI;

export const checkEmailInContract = async (email) => {
    try {
        if (!window.ethereum) throw new Error("MetaMask no está instalado");

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);

        const [wallet, contractFound, found] = await contract.findEmail(email);
        return { wallet, contractFound, found };
    } catch (error) {
        console.error("Error en la consulta:", error);
        return { wallet: null, contractFound: null, found: false };
    }
};
