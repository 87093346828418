import React, { useState } from 'react';
import SHA256 from 'crypto-js/sha256';

// Genera un número aleatorio en [min, max]
const randomInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Genera un número aleatorio con decimales entre 0 y 1 para el gas
const randomGas = () => (Math.random().toFixed(3));

// Crea una transacción aleatoria de ejemplo
const createRandomTx = () => {
  const names = ["Alice", "Bob", "Charlie", "David", "Eva", "Frank", "Gina",
                 "Henry", "Irene", "Jack", "Karen", "Leo", "Maria", "Nina",
                 "Oscar", "Paula", "Quentin", "Rose", "Steve", "Tina"];

  const fromIndex = randomInRange(0, names.length - 1);
  let toIndex = randomInRange(0, names.length - 1);
  // Asegurar que 'to' sea diferente de 'from'
  while (toIndex === fromIndex) {
    toIndex = randomInRange(0, names.length - 1);
  }

  return {
    from: names[fromIndex],
    to: names[toIndex],
    amount: randomInRange(1, 100), // Monto entre 1 y 100
    gas: randomGas() // Gas aleatorio entre 0 y 1
  };
};

// Crea un array de 20 transacciones aleatorias iniciales
const initialTxs = Array.from({ length: 20 }, () => createRandomTx());

const GasMempoolSimulation = () => {
  // Mempool inicial con 20 transacciones
  const [mempool, setMempool] = useState(initialTxs);

  // Cadena de bloques (comienza con bloque génesis)
  const [chain, setChain] = useState([
    {
      index: 0,
      prevHash: "0",
      hash: "",
      nonce: 0,
      transactions: [],
      difficulty: 2
    }
  ]);

  // Capacidad de transacciones por bloque
  const blockCapacity = 5;

  // Campos para crear una nueva transacción manualmente
  const [txFrom, setTxFrom] = useState("");
  const [txTo, setTxTo] = useState("");
  const [txAmount, setTxAmount] = useState("");

  // Calcula el hash de un bloque
  const computeHash = (block) => {
    const { index, prevHash, nonce, transactions } = block;
    // Unir la info de cada transacción en un string
    const txData = transactions
      .map((tx) => `${tx.from}${tx.to}${tx.amount}${tx.gas}`)
      .join("");
    return SHA256(`${index}${prevHash}${nonce}${txData}`).toString();
  };

  // Verifica si un bloque es válido (hash y prevHash)
  const isBlockValid = (block, i, entireChain) => {
    if (i === 0) {
      // Bloque génesis
      return block.prevHash === "0" && block.hash === computeHash(block);
    } else {
      const prevBlock = entireChain[i - 1];
      if (block.prevHash !== prevBlock.hash) return false;
      if (block.hash !== computeHash(block)) return false;
      return true;
    }
  };

  // Función para minar un nuevo bloque
  const mineBlock = () => {
    if (mempool.length === 0) return; // No hay transacciones que minar

    const updatedChain = [...chain];
    const lastBlock = updatedChain[updatedChain.length - 1];

    // Ordenar transacciones por gas descendente
    const sortedMempool = [...mempool].sort((a, b) => parseFloat(b.gas) - parseFloat(a.gas));

    // Seleccionar las primeras 'blockCapacity' transacciones
    const selectedTxs = sortedMempool.slice(0, blockCapacity);

    // Crear el nuevo bloque
    const newBlock = {
      index: updatedChain.length,
      prevHash: lastBlock.hash || "0",
      hash: "",
      nonce: 0,
      transactions: selectedTxs,
      difficulty: 2
    };

    // Minado: buscamos un hash que empiece con "00" (dificultad=2)
    const targetPrefix = "0".repeat(newBlock.difficulty);
    let newHash = computeHash(newBlock);
    while (!newHash.startsWith(targetPrefix)) {
      newBlock.nonce++;
      newHash = computeHash(newBlock);
    }
    newBlock.hash = newHash;

    // Agregar el nuevo bloque a la cadena
    updatedChain.push(newBlock);
    setChain(updatedChain);

    // Remover las transacciones seleccionadas de la mempool
    const remainingTxs = sortedMempool.slice(blockCapacity);
    setMempool(remainingTxs);
  };

  // Función para agregar una transacción manualmente
  // Se asigna gas aleatorio para imitar la "tarifa"
  const addTransaction = () => {
    if (!txFrom || !txTo || !txAmount) return;
    const newTx = {
      from: txFrom,
      to: txTo,
      amount: parseFloat(txAmount),
      gas: randomGas() // Gas aleatorio
    };
    setMempool([...mempool, newTx]);
    setTxFrom("");
    setTxTo("");
    setTxAmount("");
  };

  // Estilos
  const containerStyle = {
    padding: "20px",
    fontFamily: "Arial, sans-serif"
  };

  const labelStyle = {
    display: "block",
    fontWeight: "bold",
    marginBottom: "4px"
  };

  const inputStyle = {
    width: "100%",
    marginBottom: "8px",
    padding: "6px",
    border: "1px solid #ccc",
    borderRadius: "4px"
  };

  const buttonStyle = {
    padding: "6px 12px",
    cursor: "pointer",
    backgroundColor: "#008CBA",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    marginRight: "10px"
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px"
  };

  const thStyle = {
    border: "1px solid #ccc",
    padding: "6px",
    backgroundColor: "#f2f2f2",
    textAlign: "center"
  };

  const tdStyle = {
    border: "1px solid #ccc",
    padding: "6px",
    textAlign: "center"
  };

  const blockContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
    marginTop: "20px"
  };

  const blockStyle = (invalid) => ({
    width: "320px",
    backgroundColor: invalid ? "#ffd2d2" : "#e3f2fd",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "16px"
  });

  return (
    <div style={containerStyle}>
      <h1 style={{ textAlign: "center" }}>Simulación de Mempool y Prioridad por Gas</h1>

      {/* Form para agregar transacciones manualmente */}
      <div style={{ maxWidth: "400px", margin: "0 auto" }}>
        <label style={labelStyle}>De (Remitente):</label>
        <input
          type="text"
          value={txFrom}
          onChange={(e) => setTxFrom(e.target.value)}
          style={inputStyle}
        />

        <label style={labelStyle}>Para (Destinatario):</label>
        <input
          type="text"
          value={txTo}
          onChange={(e) => setTxTo(e.target.value)}
          style={inputStyle}
        />

        <label style={labelStyle}>Monto:</label>
        <input
          type="number"
          value={txAmount}
          onChange={(e) => setTxAmount(e.target.value)}
          style={inputStyle}
        />

        <button onClick={addTransaction} style={buttonStyle}>
          Agregar Tx
        </button>
        <button onClick={mineBlock} style={buttonStyle}>
          Minar Bloque
        </button>
      </div>

      {/* Mempool en forma de tabla */}
      <div style={{ maxWidth: "500px", margin: "20px auto" }}>
        <h3>Mempool ({mempool.length} transacciones)</h3>
        <p>Capacidad del bloque: {blockCapacity} transacciones</p>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>De</th>
              <th style={thStyle}>Para</th>
              <th style={thStyle}>Monto</th>
              <th style={thStyle}>Gas</th>
            </tr>
          </thead>
          <tbody>
            {mempool.map((tx, idx) => (
              <tr key={idx}>
                <td style={tdStyle}>{tx.from}</td>
                <td style={tdStyle}>{tx.to}</td>
                <td style={tdStyle}>{tx.amount}</td>
                <td style={tdStyle}>{tx.gas}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Cadena de bloques */}
      <h2 style={{ textAlign: "center" }}>Cadena de Bloques</h2>
      <div style={blockContainerStyle}>
        {chain.map((block, i) => {
          const invalid = !isBlockValid(block, i, chain);
          return (
            <div key={i} style={blockStyle(invalid)}>
              <label style={labelStyle}>Block #{block.index}</label>

              <label style={labelStyle}>Nonce:</label>
              <input
                type="number"
                value={block.nonce}
                readOnly
                style={inputStyle}
              />

              <label style={labelStyle}>PrevHash:</label>
              <textarea
                rows={2}
                value={block.prevHash}
                readOnly
                style={{ ...inputStyle, fontSize: "12px" }}
              />

              <label style={labelStyle}>Hash:</label>
              <textarea
                rows={2}
                value={block.hash}
                readOnly
                style={{ ...inputStyle, fontSize: "12px" }}
              />

              <label style={labelStyle}>Transacciones ({block.transactions.length}):</label>
              <table style={{ ...tableStyle, marginTop: "8px" }}>
                <thead>
                  <tr>
                    <th style={{ ...thStyle, fontSize: "12px" }}>De</th>
                    <th style={{ ...thStyle, fontSize: "12px" }}>Para</th>
                    <th style={{ ...thStyle, fontSize: "12px" }}>Monto</th>
                    <th style={{ ...thStyle, fontSize: "12px" }}>Gas</th>
                  </tr>
                </thead>
                <tbody>
                  {block.transactions.map((tx, txIdx) => (
                    <tr key={txIdx}>
                      <td style={{ ...tdStyle, fontSize: "12px" }}>{tx.from}</td>
                      <td style={{ ...tdStyle, fontSize: "12px" }}>{tx.to}</td>
                      <td style={{ ...tdStyle, fontSize: "12px" }}>{tx.amount}</td>
                      <td style={{ ...tdStyle, fontSize: "12px" }}>{tx.gas}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GasMempoolSimulation;
