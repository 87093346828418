import React from "react";
import { FaGlobe, FaMedal, FaNetworkWired, FaCertificate, FaSync, FaMicroscope, FaLightbulb } from "react-icons/fa";
import styles from "./FeaturesGrid.module.css";

const features = [
  { icon: <FaGlobe />, title: "Ecosistema Agnóstico" },
  { icon: <FaMedal />, title: "Estándares Internacionales" },
  { icon: <FaNetworkWired />, title: "Tecnología Agnóstica" },
  { icon: <FaCertificate />, title: "Certificación Global" },
  { icon: <FaNetworkWired />, title: "Redes Agnósticas" },
  { icon: <FaGlobe />, title: "Reconocimiento Mundial" },
  { icon: <FaSync />, title: "Enfoque 360°" },
  { icon: <FaMicroscope />, title: "Capacitación Práctica" },
  { icon: <FaLightbulb />, title: "Innovación en Neoeconomía" },
];

const FeaturesGrid = () => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.gridContainer}>
        {features.map((feature, index) => (
          <div key={index} className={styles.featureItem}>
            <span className={styles.icon}>{feature.icon}</span>
            <span className={styles.title}>{feature.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesGrid;
