import React from 'react';

const ContentSection = ({ sections }) => {
  if (!Array.isArray(sections) || sections.length === 0) {
    return <p className="text-center text-gray-500">No hay contenido disponible.</p>;
  }

  return (
    <div style={{ width: '90%', margin: '0 auto', padding: '2rem' }}>
      {sections.map((section, index) => (
        <div key={index} style={{ marginBottom: '3rem' }}>
          {/* Subtítulo (puedes ocultarlo si ya lo muestras en TitleSection) */}
          <h2
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              textAlign: 'left',
              marginBottom: '1rem',
            }}
          >
            {section.subtitle}
          </h2>

          {/* Imagen a nivel de sección (opcional) */}
          {section.images && section.images.length > 0 && (
            <div style={{ textAlign: "center", marginBottom: "1rem" }}>
              {section.images.map((img, idx) => (
                <img key={idx} src={img.imageUrl} alt={img.suffix} style={{ maxWidth: "400px", borderRadius: "8px" }} />
              ))}
            </div>
          )}


          {/* Contenido textual de la sección */}
          {section.content && (
            <p style={{ color: '#4a5568', textAlign: 'justify', lineHeight: '1.8' }}>
              {section.content}
            </p>
          )}

          {/* Steps (si la sección los tiene) */}
          {section.steps && Array.isArray(section.steps) && section.steps.length > 0 && (
            <div style={{ marginTop: '1.5rem' }}>
              <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Pasos</h3>
              <ul style={{ listStyleType: 'decimal', paddingLeft: '1.5rem' }}>
                {section.steps.map((step, stepIndex) => (
                  <li key={stepIndex} style={{ marginBottom: '1rem' }}>
                    <strong>Paso {step.number}:</strong> {step.content}

                    {/* Imagen dentro de cada paso (opcional) */}
                    {step.image && step.image.trim() !== '' && (
                      <div style={{ margin: '0.5rem 0' }}>
                        <img
                          src={step.image}
                          alt={`Paso ${step.number}`}
                          style={{
                            maxWidth: '300px',
                            borderRadius: '4px',
                          }}
                        />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ContentSection;
