import React, { useState, useEffect } from 'react';

const Menu = ({ topics, isSidebarCollapsed }) => {
  const [menuTop] = useState('10vh');
  const [hovered, setHovered] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const getLeftOffset = () => {
    if (windowWidth < 768) {
      return '0px';
    } else {
      const percentage = isSidebarCollapsed ? 0.14 : 0.22;
      return `${Math.round(windowWidth * percentage)}px`;
    }
  };


  const getMenuWidth = () => (windowWidth < 768 ? '100%' : '80%');


  const handleMenuClick = (e, topicId) => {
    e.preventDefault(); // Evita la navegación predeterminada del navegador

    // Pequeña espera para asegurar que el DOM está actualizado
    setTimeout(() => {
      const element = document.getElementById(`topic-${topicId}`);

      if (element) {
        const headerOffset = 100; // Altura fija del menú superior
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      } else {
        console.warn(`No se encontró la sección con id: topic-${topicId}`);
      }
    }, 50); // Espera mínima para garantizar que el DOM esté listo
  };



  return (
    <>

      <style>{`
       .scrollable-menu::-webkit-scrollbar {
          height: 8px;
       }
       .scrollable-menu::-webkit-scrollbar-track {
          background: #f1f1f1;
       }
       .scrollable-menu::-webkit-scrollbar-thumb {
          background: #ADD8E6;
      
       }
       .scrollable-menu::-webkit-scrollbar-thumb:hover {
          background: #87CEEB;
       }
       .scrollable-menu {
          scrollbar-width: thin;
          scrollbar-color: #ADD8E6 #f1f1f1;
       }
      `}</style>

      <nav
        style={{
          width: getMenuWidth(),
          maxWidth: '1200px',
          padding: '0.5rem',
          background: 'rgba(255, 255, 255, 0.3)',
          backdropFilter: 'blur(10px)',
          position: 'fixed',
          top: menuTop,
          left: getLeftOffset(),
          zIndex: '999',
  
          transition: 'left 0.5s ease-in-out, top 0.5s ease-in-out',
        }}
      >
        <div
          className="scrollable-menu"
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: '0.5rem',
            overflowX: 'auto',
            paddingBottom: '0.5rem',
          }}
        >
          {topics.map((topic) => (
            <a
              href={`#topic-${topic.id}`} // Esto es útil si el usuario tiene JS desactivado
              onClick={(e) => handleMenuClick(e, topic.id)}
              onMouseEnter={() => setHovered(topic.id)}
              onMouseLeave={() => setHovered(null)}
              style={{
                flex: "0 0 auto",
                minWidth: "80px",
                padding: "0.5rem",
                height: "60px",
                background: hovered === topic.id ? "rgba(201, 201, 201, 0.5)" : "rgba(255, 255, 255, 0.2)",
                backdropFilter: "blur(10px)",
                color: "black",
                textAlign: "center",
                textDecoration: "none",
                transition: "all 0.3s ease-in-out",
               
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
              >
                {topic.title}
              </span>
            </a>


          ))}
        </div>
      </nav>
    </>
  );
};

export default Menu;
