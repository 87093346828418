import React from 'react';
import styles from './TrainingInfoCard.module.css';  // Asegúrate de tener el archivo CSS

const TrainingInfoCard = () => {
    return (
        <div className={styles.cardContainer}>
            <div className={styles.card}>
                <div className={styles.textContainer}>
                    <h2 className={styles.title}>Candidato C|BP</h2>
                    <h3 className={styles.subtitle}>¿A quién está dirigido?</h3>
                    <ul className={styles.list}>
                        <li className={styles.listItem}>
                            <h4 className={styles.sectionTitle}>Ejecutivos de Tecnología Empresarial</h4>
                            <p className={styles.description}>
                                Para los tomadores de decisiones (líderes de unidades, ejecutivos de crecimiento e innovadores tecnológicos) interesados en liderar proyectos basados en Blockchain.
                            </p>
                        </li>
                        <li className={styles.listItem}>
                            <h4 className={styles.sectionTitle}>Funcionarios de Tecnología Gubernamental</h4>
                            <p className={styles.description}>
                                Para los responsables de políticas (funcionarios gubernamentales, reguladores y oficiales de cumplimiento) asignados a casos relacionados con Blockchain.
                            </p>
                        </li>
                        <li className={styles.listItem}>
                            <h4 className={styles.sectionTitle}>Desarrolladores Full Stack</h4>
                            <p className={styles.description}>
                                Para los creadores de tecnología (desarrolladores, programadores, codificadores) con comprensión básica de lenguajes de programación y conocimientos técnicos para profundizar en el desarrollo, estrategia e implementación de Blockchain.
                            </p>
                        </li>
                        <li className={styles.listItem}>
                            <h4 className={styles.sectionTitle}>Profesionales del Derecho, Economía y Finanzas</h4>
                            <p className={styles.description}>
                                El curso está dirigido también a abogados, economistas, contables, gestores, estudiantes, y cualquier profesional interesado en sumergirse en el mundo Blockchain.
                                No se requiere experiencia previa, ya que los módulos están diseñados para proporcionar una comprensión profunda de la tecnología.
                            </p>
                        </li>
                    </ul>
                    <p className={styles.info}>
                        <strong>Duración del curso:</strong> 70 horas (3 meses)
                    </p>
                    <p className={styles.info}>
                        <strong>Modalidad:</strong> Capacitación avanzada, con aplicaciones globales.
                    </p>
                </div>
                <div className={styles.imageContainer}>
                    <img
                        src="https://img.recraft.ai/jo58tDsFSklhdblWInTDv3w45c-b5Jw5gLVZURaebvA/rs:fit:1820:1024:0/q:80/g:no/plain/abs://prod/images/f96ef2a2-20b8-4b70-b50b-fcb5a8a7ce0b@avif"
                        alt="C|BP"
                        className={styles.image}
                    />
                </div>
            </div>
        </div>
    );
}

export default TrainingInfoCard;
