import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner
} from 'react-bootstrap';

import SphereToPlaneWithRotation from '../components/FiberElements/SphereToPlaneWithRotation';
import NFTCard from '../components/Cards/NFTCard';
import NFTDescription from '../components/Cards/NFTDescription';
import TitleSection from '../components/Reusable/TitleSection';
import ContentSection from '../components/Reusable/ContentSection';
import topics from '../data/NFTTopics.json';

const Home = ({ marketplace, nft }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(topics[0].id);

  const handleSelectTopic = (id) => {
    setSelectedTopic(id);
  };

  const currentTopic =
    topics.find((topic) => topic.id === selectedTopic) || topics[0];

  const loadMarketplaceItem = async () => {
    try {
      if (!marketplace) {
        setLoading(false);
        return;
      }

      const itemCount = await marketplace.itemCount();
      if (!itemCount) {
        setLoading(false);
        return;
      }

      let loadedItems = [];
      for (let i = 1; i <= itemCount; i++) {
        const item = await marketplace.items(i);
        if (!item.sold) {
          const uri = await nft.tokenURI(item.tokenId);
          const response = await fetch(uri);
          if (!response.ok) {
            continue;
          }
          const metadata = await response.json();
          const totalPrice = await marketplace.getTotalPrice(item.itemId);

          loadedItems.push({
            totalPrice,
            itemId: item.itemId,
            seller: item.seller,
            name: metadata.name,
            description: metadata.description,
            image: metadata.image
          });
        }
      }
      setItems(loadedItems);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const buyMarketItem = async (item) => {
    await (
      await marketplace.purchaseItem(item.itemId, {
        value: item.totalPrice
      })
    ).wait();
    loadMarketplaceItem();
  };

  useEffect(() => {
    document.title = 'NFTs - Nuestra Plataforma';
    loadMarketplaceItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ padding: 0, marginTop: '5%' }}>
      {/* Sección superior con 3D (similar a AboutUs) */}
      <div style={{ height: '50vh', overflow: 'hidden' }}>
        <SphereToPlaneWithRotation />
      </div>

      {/* Contenedor fluido con margin top para simular la misma separación de AboutUs */}
      <Container fluid className="py-4" style={{ maxWidth: '95%', margin: '0 auto' }}>
        {/* Manejamos estado de carga */}
        {loading ? (
          <>
            {/* Spinner centrado */}
            <Row className="justify-content-center my-4">
              <Col xs="auto">
                <Spinner animation="border" role="status" />
              </Col>
            </Row>

            {/* Contenido mientras carga: un ejemplo con la parte estática */}
            <Row className="my-5">
              <Col xs={12}>
                <TitleSection title={currentTopic.title || 'Cargando...'} />
                <ContentSection sections={currentTopic.sections || []} />
              </Col>
            </Row>
            <Row>
              <Col>
                <NFTDescription />
              </Col>
            </Row>
          </>
        ) : (
          <>
            {items.length > 0 ? (
              // Si hay ítems para mostrar
              <Row className="my-4">
                <Col xs={12}>
                  <h2 className="fw-bold text-center mb-4">Galería de NFTs</h2>
                </Col>
                <Row xs={1} md={2} lg={4} className="g-4">
                  {items.map((item, idx) => (
                    <Col key={idx}>
                      <Card className="h-100">
                        <Card.Img
                          variant="top"
                          src={item.image}
                          style={{ objectFit: 'cover', height: '250px' }}
                        />
                        <Card.Body>
                          <Card.Title>{item.name}</Card.Title>
                          <Card.Text>{item.description}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <div className="d-grid">
                            <Button
                              onClick={() => buyMarketItem(item)}
                              variant="primary"
                              size="lg"
                            >
                              Comprar por{' '}
                              {ethers.utils.formatEther(item.totalPrice)} ETH
                            </Button>
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Row>
            ) : (
              // Si NO hay ítems
              <Row>
                <Col>
                  <h2 className="fw-bold text-center my-4">No hay artículos disponibles</h2>

                  {/* Puedes mostrar tu contenido estático tal cual */}
                  <section className="mb-4">
                    {topics.map((topic) => (
                      <div key={topic.id} id={`topic-${topic.id}`} className="py-4">
                        <TitleSection title={topic.title} />
                        <ContentSection sections={topic.sections} />
                      </div>
                    ))}
                    <NFTDescription />
                  </section>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Home;
