import React, { useEffect } from "react";
import Header from "../components/LoadPages/AcademicCollaborationCard"; // Asegúrate de la ruta correcta al archivo
import LogoCards from "../components/Cards/LogoCard";
import InfoCard from "../components/Cards/InfoCard";
import BlockchainCard from "../components/Cards/CardContainer";
import CardContainerB from "../components/Cards/CardContainerB";
import Container from "../components/ContentCourse/Container";
import Cube from "../components/FiberElements/City";

const AboutUs = () => {

    useEffect(() => {
        document.title = "Conócenos - Nuestra Plataforma";
        
      }, []);

    return (
        <div style={{ padding: "0", marginTop: "5%" }}> {/* Contenedor con espaciado general */}
            
            {/* CubeLight ocupa la mitad superior */}
            <div style={{ height: "50vh", overflow: "hidden" }}>
                <Cube />
            </div>

            <Header title="UVEG, EDUCA y CoachBlockchain" subtitle="" height="300px" />

            <p style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", margin: "40px 0" }}>
                Aprende más sobre nuestra colaboración académica con UVEG, EDUCA y CoachBlockchain.
            </p>

            <div style={{ marginTop: "40px" }}>
                <LogoCards />
            </div>

            <InfoCard
                title="El Consejo Consultivo"
                subtitle="El Consejo Consultivo de Blockchain"
                description="El Consejo Consultivo de Blockchain es una red de expertos reconocidos por UVEG, EDUCA y CoachBlockchain como líderes clave en el campo de la tecnología blockchain. Los miembros son cuidadosamente seleccionados de la industria y están comprometidos a crear un impacto positivo en la educación y la práctica profesional en blockchain.

                Poseen un conocimiento profundo y experiencia en criptomonedas, tecnología financiera, seguridad de la información, desarrollo full-stack y tecnología de libro mayor distribuido (DLT), entre otros. Actúan como una voz independiente para la industria.

                Su rol de liderazgo es reconocido y apoyado por la gerencia y el equipo de investigación y desarrollo de estas instituciones. Con la formación de este Consejo Consultivo, se busca monitorear los avances en las políticas y procedimientos de certificación de blockchain para asegurar que las certificaciones sean valiosas y actualizadas. Asimismo, se pretende que los profesionales certificados continúen ampliando su base de conocimientos.

                El Consejo es responsable del desarrollo y mantenimiento de los estándares éticos y de calidad de las certificaciones en blockchain otorgadas por CoachBlockchain, EDUCA y la Universidad Virtual del Estado de Guanajuato."
                imagePosition="right" // Puedes usar "left" o "right"
                imageWidth="30%"     // Ajusta el ancho de la imagen
                imageHeight="350px"  // Ajusta la altura de la imagen
            />

            <div style={{ marginBottom: "40px" }}>
                <Header
                    title="UVEG, EDUCA y CoachBlockchain"
                    background="#fff" // Fondo personalizado
                    textColor="#333333"
                    height="20vh" // Alto personalizado
                />
            </div>

            <Container />

           
        </div>
    );
};

export default AboutUs;
