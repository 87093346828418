import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './Navigation.module.css';

const ConnectWallet = ({ web3Handler, account }) => {
    const buttonStyle = {
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#D6AF44',
        color: 'black',
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '100%',
        marginTop: '10px'
    };

    return (
        <>
            {account ? (
                <a
                    href={`https://etherscan.io/address/${account}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.navButton} mx-4`}
                >
                    <Button style={buttonStyle} className={styles.accountButton}>
                        {account}
                    </Button>
                </a>
            ) : (
                <Button onClick={web3Handler} style={buttonStyle} className={`${styles.connectButton} mx-2`}>
                    Connect Wallet
                </Button>
            )}
        </>
    );
};

export default ConnectWallet;
