import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  color: #000;
  border-radius: 10px;
  padding: 30px;

  max-width: 1200px;
  margin: 0 auto;
`;

const TextSection = styled.div`
  flex: 1;
  padding: 20px;
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: justify; /* Justifica el texto */
`;

const HighlightSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Highlight = styled.div`
  background: transparent; /* Fondo transparente */
  color: #e63946; /* Color del texto rojo */
  font-size: 1.8rem;
  font-weight: bold;
  padding: 10px 20px;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 5px;
`;

const Source = styled.div`
  font-size: 1rem;
  margin-top: 20px;
  font-style: italic;
  color: #ffddcc;
`;

const BlockchainCard = () => {
  return (
    <CardContainer>
      <TextSection>
        <p>
          La principal ventaja de la tecnología blockchain es su <strong>transparencia</strong> y{" "}
          <strong>seguridad</strong>. Las acciones de todos los participantes son abiertas y visibles en tiempo real,
          lo que evita falsificaciones de información.
        </p>
        <p>
          Como resultado, la demanda de profesionales en blockchain se ha disparado hasta un <strong>700%</strong> en los
          últimos años.
        </p>
        <p>
          El alcance es altamente positivo en el campo de Blockchain, ya que el mercado está evolucionando hacia la
          industria 4.0, con aprendizaje automático aplicado a soluciones de IoT y A.I. impulsadas por blockchain.
        </p>
      </TextSection>
      <HighlightSection>
        <Highlight>“Actualmente hay</Highlight>
        <Highlight style={{ fontSize: "3rem" }}>14 ofertas de empleo</Highlight>
        <Highlight>por cada profesional de blockchain”</Highlight>
      </HighlightSection>
    </CardContainer>
  );
};

export default BlockchainCard;
