import MuseumViewer from '../components/FiberElements/MuseumViewer';import React from "react";


export default function App() {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <MuseumViewer />
    </div>
  );
}
