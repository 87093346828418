import React, { useState,useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';  
import Logo from '../components/FiberElements/Logo';
import { checkEmailInContract } from '../hooks/useBlockchain';
import ConnectWallet from '../components/Buttons/ConnectWallet';

const RegistrationComponent = ({ web3Handler, account }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const navigate = useNavigate();  

  useEffect(() => {
    document.title = "Acceso - Nuestra Plataforma";
    if (account && isRegistered) {

        navigate('/course/profile');
    }
}, [account, isRegistered, navigate]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setError(emailRegex.test(value) ? '' : 'Correo inválido');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!error) {
        try {
      
            const { wallet, contractFound, found } = await checkEmailInContract(email);
            
           

            if (found) {
           
                setIsRegistered(true);
            } else {
                alert("Correo no encontrado en la blockchain.");
            }
        } catch (err) {

        }
    }
};

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(10px)',
        color: 'white'
      }}
    >
      <div
        style={{
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(20px)',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          width: '300px',
          textAlign: 'center',
          border: '1px solid rgba(255, 255, 255, 0.3)'
        }}
      >
        {/* Canvas con la animación del logo de Ethereum */}
        <div style={{ width: '150px', height: '150px', margin: '0 auto' }}>
          <Logo />
        </div>

        {/* Si el usuario ya inició sesión y verificó su email, mostrar mensaje de bienvenida */}
        {account && isRegistered ? (
          <>
          <div>
          <h2 style={{ color: 'black', marginTop:'10vh' }}>¡Bienvenido!</h2>
            <p style={{ color: 'green', fontWeight: 'bold' }}>
              Ya has iniciado sesión con la cuenta: <br />
              <span style={{ color: 'black' }}>{account}</span>
            </p>
            <button
              onClick={() => navigate('/course/profile')}
              style={{
                padding: '10px',
                borderRadius: '5px',
                border: 'none',
                backgroundColor: '#D6AF44',
                color: 'black',
                fontWeight: 'bold',
                cursor: 'pointer',
                width: '100%',
                marginTop: '10px'
              }}
            >
              Ir a mi perfil
            </button>

          </div>
           
          </>
        ) : (
          <>
            {/* Formulario de verificación */}
            <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
              <label style={{ marginBottom: '10px', display: 'block', color: 'black' }}>
                <strong>Correo Electrónico:</strong>
              </label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Correo electrónico"
                required
                style={{
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid rgba(255, 255, 255, 0.5)',
                  background: 'rgba(255, 252, 252,0.6)',
                  color: 'black',
                  width: '100%',
                  outline: 'none',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                  transition: 'all 0.3s ease',
                }}
              />
              {error && <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{error}</p>}
              <button
                type="submit"
                style={{
                  padding: '10px',
                  borderRadius: '5px',
                  border: 'none',
                  backgroundColor: '#D6AF44',
                  color: 'black',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  width: '100%',
                  marginTop: '10px'
                }}
                disabled={!!error}
              >
                Verificar Email
              </button>
            </form>

            {/* Si el usuario ha registrado su email, mostrar opción de conectar wallet */}
            {isRegistered && (
              <>
                <p style={{ color: 'green', marginTop: '10px' }}>Usuario registrado. Conectar Wallet.</p>
                <ConnectWallet web3Handler={web3Handler} account={account} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RegistrationComponent;
