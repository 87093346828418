const rawFiles = {
  day1_es: "https://raw.githubusercontent.com/AppsDevsLeon/Revista_blockchain/refs/heads/main/Day01/Day01_ES.md",
  day1_en: "https://raw.githubusercontent.com/AppsDevsLeon/Revista_blockchain/refs/heads/main/Day01/Day01.md",
};

// Generar la lista para los días (sin incluir el día 24)
for (let i = 2; i <= 33; i++) {
  if (i === 24) continue; // Saltar el día 24

  const dayFormatted = i < 10 ? `0${i}` : `${i}`;
  rawFiles[`day${i}_es`] = `https://raw.githubusercontent.com/AppsDevsLeon/Revista_blockchain/refs/heads/main/Day${dayFormatted}/Day${dayFormatted}_ES.md`;
  rawFiles[`day${i}_en`] = `https://raw.githubusercontent.com/AppsDevsLeon/Revista_blockchain/refs/heads/main/Day${dayFormatted}/Day${dayFormatted}.md`;
}

// Generar la lista de imágenes para cada día (sin incluir el día 24)
const rawImages = {};


export { rawFiles, rawImages };
