import React, { useState, useEffect } from "react";

const IPFSVideoPlayer = () => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Guía en Video - Plataforma";
  }, []);

  useEffect(() => {
    const videoLink = "https://www.youtube.com/embed/B7Aa2AcU5CE";
    setVideoUrl(videoLink);
  }, []);

  if (error) {
    return <p style={styles.errorText}>{error}</p>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>📌 Guía en Video para Acceder a la Plataforma</h1>
      <p style={styles.subtitle}>
        Sigue las instrucciones para conocer cómo usar la plataforma.
      </p>

      {videoUrl ? (
        <div style={styles.videoWrapper}>
          <iframe
            style={styles.video}
            src={videoUrl}
            title="Guía en Video"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <p style={styles.loadingText}>⏳ Cargando video...</p>
      )}
    </div>
  );
};

// Estilos en JSX
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#f8f9fa", // Fondo suave para destacar el video
  },
  title: {
    fontSize: "clamp(1.8rem, 5vw, 2.5rem)", // Se ajusta según el ancho de pantalla
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "clamp(1rem, 3vw, 1.2rem)", // Tamaño adaptable
    color: "#555",
    marginBottom: "20px",
  },
  videoWrapper: {
    position: "relative",
    width: "90%", // Ocupa el 90% del contenedor
    maxWidth: "900px", // Máximo tamaño en escritorio
    aspectRatio: "16/9", // Mantiene proporciones correctas
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  loadingText: {
    fontSize: "1rem",
    color: "#007bff",
  },
  errorText: {
    fontSize: "1rem",
    color: "red",
  },
};

export default IPFSVideoPlayer;
