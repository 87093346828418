import React, { useState, useEffect } from 'react';
import { ethers } from "ethers";
import { Row, Col, Card, Button } from 'react-bootstrap';
import Roadmap from '../components/Roadmaps/Roadmap';
import Logo from '../components/FiberElements/City'

const Home = ({ marketplace, nft }) => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);

    const loadMarketplaceItem = async () => {
        try {
            if (!marketplace) {
             
                setLoading(false);
                return;
            }

            const itemCount = await marketplace.itemCount();
            if (!itemCount) {
           
                setLoading(false);
                return;
            }

            let items = [];
            for (let i = 1; i <= itemCount; i++) {
                const item = await marketplace.items(i);
                if (!item.sold) {
                    const uri = await nft.tokenURI(item.tokenId);
                    const response = await fetch(uri);
                    if (!response.ok) {
                     
                        continue;
                    }
                    const metadata = await response.json();
                    const totalPrice = await marketplace.getTotalPrice(item.itemId);
                    items.push({
                        totalPrice,
                        itemId: item.itemId,
                        seller: item.seller,
                        name: metadata.name,
                        description: metadata.description,
                        image: metadata.image
                    });
                }
            }
            setLoading(false);
        } catch (error) {
      
            setLoading(false);
        }
    };


    const buyMarketItem = async (item) => {
        await (await marketplace.purchaseItem(item.itemId, { value: item.totalPrice })).wait();
        loadMarketplaceItem();
    };

    useEffect(() => {
        loadMarketplaceItem();
    }, []);

    if (loading) return (
        <main style={{ padding: "1rem 0" }}>
            <Logo />
            <Roadmap />
        </main>
    );

    return (
        <div className="flex justify-center">

            {items.length > 0 ? (
                <div className="px-5 container">
                    <Row xs={1} md={2} lg={4} className="g-4 py-5">
                        {items.map((item, idx) => (
                            <Col key={idx} className="overflow-hidden">
                                <Card>
                                    <Card.Img variant="top" src={item.image} />
                                    <Card.Body color="secondary">
                                        <Card.Title>{item.name}</Card.Title>
                                        <Card.Text>{item.description}</Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <div className="d-grid">
                                            <Button onClick={() => buyMarketItem(item)} variant="primary" size="lg">
                                                Buy by {ethers.utils.formatEther(item.totalPrice)} ETH
                                            </Button>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            ) : (
                <main style={{ padding: "1rem 0" }}>
                    <div style={{ height: '50vh', overflow: 'hidden' }}>
                        <Logo />

                    </div>

                    <Roadmap />
                </main>
            )}
        </div>
    );
};

export default Home;
