import { useState, useEffect } from "react";
import { ethers } from "ethers";
import abiData from "../utils/abi.json";
import addresses from "../utils/addresses.json";

const useContract = () => {
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState(null);

    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                const _provider = new ethers.providers.Web3Provider(window.ethereum);
                const _signer = _provider.getSigner();
                const _contract = new ethers.Contract(addresses.contractAddress, abiData.abi, _signer);

                setProvider(_provider);
                setSigner(_signer);
                setContract(_contract);

                const accounts = await _provider.listAccounts();
                if (accounts.length > 0) {
                    setAccount(accounts[0]);
                }
            } else {
                console.error("Metamask no detectado.");
            }
        };

        init();
    }, []);

    const connectWallet = async () => {
        try {
            if (!window.ethereum) throw new Error("Metamask no está instalado");
            const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
            setAccount(accounts[0]);
        } catch (error) {
            console.error("Error al conectar wallet:", error);
        }
    };

    return { provider, signer, contract, account, connectWallet };
};

export default useContract;
