import React, { useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import gsap from "gsap";

// Componente para cada rectángulo animado
const Rectangle = ({ startPos, finalPos, size, delay }) => {
  const ref = useRef();

  useEffect(() => {
    gsap.fromTo(
      ref.current.position,
      { x: startPos[0], y: startPos[1], z: startPos[2] },
      { x: finalPos[0], y: finalPos[1], z: finalPos[2], duration: 1.5, delay, ease: "power2.out" }
    );

    gsap.fromTo(
      ref.current.rotation,
      { y: Math.PI }, // Inicia rotado
      { y: 0, duration: 1.5, delay, ease: "power2.out" }
    );

    gsap.fromTo(
      ref.current.scale,
      { x: 0.1, y: 0.1, z: 0.1 },
      { x: 1, y: 1, z: 1, duration: 1.5, delay, ease: "power2.out" }
    );
  }, [startPos, finalPos, delay]);

  return (
    <mesh ref={ref} position={finalPos}>
      <boxGeometry args={size} />
      <meshStandardMaterial color="#D4AF37" roughness={0.3} metalness={0.8} />
    </mesh>
  );
};

const Logo3D = () => {
  return (
    <group>
      {/* Estructura del logo con animaciones */}
      <Rectangle startPos={[-4, 1, 0]} finalPos={[-1.5, 1, 0]} size={[0.4, 2.2, 0.2]} delay={0} />
      <Rectangle startPos={[-4, 0, 0]} finalPos={[-0.6, 0, 0]} size={[2.2, 0.4, 0.2]} delay={0.2} />
      <Rectangle startPos={[-4, 1, 0]} finalPos={[1.5, 1, 0]} size={[0.4, 2.2, 0.2]} delay={0.4} />
      <Rectangle startPos={[-4, 0, 0]} finalPos={[1.3, 0, 0]} size={[0.8, 0.4, 0.2]} delay={0.6} />
      <Rectangle startPos={[-4, 2, 0]} finalPos={[0.6, 2, 0]} size={[2.2, 0.4, 0.2]} delay={0.8} />
      <Rectangle startPos={[-4, 2, 0]} finalPos={[-1.3, 2, 0]} size={[0.8, 0.4, 0.2]} delay={1} />
      <Rectangle startPos={[-4, 0.65, 0]} finalPos={[0.3, 0.65, 0]} size={[0.4, 1.7, 0.2]} delay={1.2} />
      <Rectangle startPos={[-4, 1.3, 0]} finalPos={[-0.3, 1.3, 0]} size={[0.4, 1.7, 0.2]} delay={1.4} />
    </group>
  );
};

const Scene = () => {
  return (
    <Canvas shadows camera={{ position: [0, -2, 4], fov: 50 }}>
      <ambientLight intensity={1} />
      <directionalLight position={[5, 5, 5]} intensity={1} />
      <Logo3D />
      <OrbitControls />
    </Canvas>
  );
};

export default Scene;
