import React from "react";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: "40vh";
  background: linear-gradient(135deg, #80B1B7, #80B1B7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: bold;
  overflow: hidden;
  text-align: center;
  padding: 20px;
`;

const Title = styled.h1`
  z-index: 10;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const SubTitle = styled.p`
  font-size: 1.5rem;
  margin-top: 10px;
  color: inherit;
  max-width: 600px;
  line-height: 1.5;
  text-align: center;
`;

const DecorativeCircles = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.7;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  background: radial-gradient(circle, #80B1B7, #80B1B7);
  border-radius: 50%;
  margin: 8px;
  animation: pulse 3s ease-in-out infinite;
`;

const Header = ({ title, subtitle, height }) => {
  return (
    <HeaderWrapper height={height}>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>

      {/* Círculos dorados decorativos */}
      <DecorativeCircles>
        {Array(10).fill('').map((_, index) => (
          <Circle key={index} />
        ))}
      </DecorativeCircles>
    </HeaderWrapper>
  );
};

export default Header;
