import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Row, Col, Card, Button } from 'react-bootstrap';

// Componentes personalizados
import SphereWithCubes from '../components/FiberElements/SphereWithCubes';
import CourseContent from '../components/ContentCourse/CourseContent.js';
import CourseCard from '../components/Cards/CourseCard.js';
import ResourceCard from '../components/Cards/ResourceCard.js';
import InfoCard from '../components/Cards/InfoCard.js';
import ObjectivesCard from '../components/Cards/ObjectivesCard.js';
import OutcomeCard from '../components/Cards/OutcomeCard.js';
import FeaturesGrid from '../components/Grid/FeaturesGrid.js';
import TrainingInfoCard from '../components/Cards/TrainingInfoCard.js';
import Header from '../components/LoadPages/AcademicCollaborationCard.jsx';
import FoxViewer from '../components/FiberElements/FoxViewer.js';

// Contenido de objetivos y resultados
const objectives = [
  "Dominar las bases teóricas y técnicas de la tecnología blockchain",
  "Comprender el concepto de descentralización, su impacto y su relación con la tecnología blockchain",
  "Comprender los principios técnicos subyacentes del desarrollo e implementación de blockchain",
  "Comprender los mecanismos de la tecnología blockchain detrás de Bitcoin y otras criptomonedas",
  "Entender cómo se utiliza la criptografía para asegurar los datos con ejemplos prácticos",
  "Comprender las bases teóricas de los contratos inteligentes y cómo Ethereum se usa para desarrollar aplicaciones descentralizadas utilizando Solidity y marcos de desarrollo relevantes",
  "Investigar soluciones alternativas de blockchain, como Hyperledger, Corda y muchas más",
  "Descubrir casos de uso de aplicaciones potenciadas por blockchain en diversas industrias",
  "Explorar estrategias de implementación y comercialización de blockchain",
  "Aprender las mejores prácticas para la integración de blockchain en arquitecturas de negocios actuales",
  "Identificar las posibles implicaciones de la implementación de blockchain en aspectos legales",
  "Predecir riesgos de adopción y fallos de estrategias de aplicaciones, y aprender cómo evitarlos mediante una gestión de cambios y proyectos eficaz",
  "Identificar nuevos temas de investigación y el alcance futuro de la tecnología blockchain"
];

const outcomes = [
  "Comprensión profunda de la criptografía y las criptomonedas, libros de contabilidad distribuidos, descentralización y contratos inteligentes.",
  "Habilidad para construir aplicaciones descentralizadas potentes y altamente seguras utilizando Ethereum para crear contratos inteligentes y facilitar transacciones confiables en la aplicación.",
  "Capacidad para proporcionar soluciones innovadoras para resolver problemas de adopción y escalabilidad en la industria."
];

const Home = ({ marketplace, nft }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  // Carga de ítems del marketplace
  const loadMarketplaceItem = async () => {
    try {
      if (!marketplace) {
        setLoading(false);
        return;
      }
      const itemCount = await marketplace.itemCount();
      if (!itemCount) {
        setLoading(false);
        return;
      }

      let loadedItems = [];
      for (let i = 1; i <= itemCount; i++) {
        const item = await marketplace.items(i);
        if (!item.sold) {
          const uri = await nft.tokenURI(item.tokenId);
          const response = await fetch(uri);
          if (!response.ok) continue;

          const metadata = await response.json();
          const totalPrice = await marketplace.getTotalPrice(item.itemId);

          loadedItems.push({
            totalPrice,
            itemId: item.itemId,
            seller: item.seller,
            name: metadata.name,
            description: metadata.description,
            image: metadata.image
          });
        }
      }
      setItems(loadedItems);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Función para comprar ítem
  const buyMarketItem = async (item) => {
    await (await marketplace.purchaseItem(item.itemId, { value: item.totalPrice })).wait();
    loadMarketplaceItem();
  };

  // Efecto para cargar ítems al montar
  useEffect(() => {
    loadMarketplaceItem();
  }, []);

  // Muestra una vista de carga si aún se están obteniendo datos
  if (loading) {
    return (
      <main style={{ padding: "1rem 0" }}>
        <SphereWithCubes />
        <CourseContent />
        <CourseCard />
        <ResourceCard />
      </main>
    );
  }

  // Render principal
  return (
    <div className="flex justify-center">
      {items.length > 0 ? (
        // Si hay ítems disponibles en el marketplace
        <div className="px-5 container">
          <Row xs={1} md={2} lg={4} className="g-4 py-5">
            {items.map((item, idx) => (
              <Col key={idx} className="overflow-hidden">
                <Card>
                  <Card.Img variant="top" src={item.image} />
                  <Card.Body color="secondary">
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <div className="d-grid">
                      <Button onClick={() => buyMarketItem(item)} variant="primary" size="lg">
                        Buy by {ethers.utils.formatEther(item.totalPrice)} ETH
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        // Si no hay ítems en el marketplace, se muestra la página principal
        <main style={{ padding: "1rem 0" }}>
          {/* Sección 1: Sphere + Header */}
          <div style={{ height: '50vh', overflow: 'hidden' }}>
            <SphereWithCubes />
          </div>
          <Header
            title="CERTIFICACIÓN DOMINANDO BLOCKCHAIN"
            background="#fff"
            textColor="#333333"
            height="20vh"
          />

          {/* InfoCard 1 */}
          <div style={{ margin: "40px auto", maxWidth: "1200px" }}>
            <InfoCard
              title="CERTIFICACIÓN|Dominando Blockchain"
              subtitle="Descripción del Programa"
              description={`Blockchain, la piedra angular de una estrategia de descentralización, es una base de datos distribuida que se replica en múltiples nodos para permitir un registro de transacciones inmutable, permanente, transparente y seguro. Básicamente, Blockchain es un sistema de almacenamiento de datos y validación de transacciones.

La tecnología blockchain es la única red descentralizada peer-to-peer que autoriza a los usuarios a firmar transacciones digitalmente con una precisión del 100%. Blockchain está ganando popularidad en industrias como finanzas, gobierno, seguros, logística, medios de comunicación, salud, y muchas otras por ser una red sin fronteras de intercambio de valor en tiempo real.

El curso C|DB proporciona una visión completa y práctica al 100% sobre la tecnología blockchain y su implementación en el mundo real.

La certificación C|DB consta de tres áreas de conocimiento y competencia en la tecnología Blockchain: Desarrollo, Implementación y Estrategia.

Durante el curso, los estudiantes no solo serán expuestos a múltiples conceptos de implementación de blockchain, sino que también se sumergirán en una guía única para el desarrollo de blockchain sostenible y escalable con el uso de registros resistentes a la computación cuántica.`}
              imagePosition="left"
              imageWidth="30%"
              imageHeight="350px"
            />
          </div>

          {/* InfoCard 2 */}
          <div style={{ margin: "40px auto", maxWidth: "1200px" }}>
            <InfoCard
              title="Curso Certificado Dominando Blockchain (C|DB)"
              subtitle="Capacitación Contextual, Aplicación Global"
              description={`A medida que las organizaciones se transforman en empresas digitales inteligentes, adaptativas y las economías evolucionan hacia ecosistemas centrados en el consumidor y basados en datos de pares, nuestra misión es, y siempre ha sido, ayudar a los profesionales de negocios a obtener habilidades digitales relevantes, implementables y prácticas que faciliten el crecimiento corporativo global. El curso Certified Blockchain Professional (C|DB) está diseñado para ayudar a los respectivos aspirantes a profesionales a obtener un conocimiento exhaustivo de la tecnología Blockchain y su implicación en los negocios y más allá. El programa C|DB, un curso práctico, independiente de proveedores y de 360°, se enfoca en el potencial actual y futuro de la tecnología Blockchain. El curso profundiza en las principales características y características de la tecnología de libro mayor distribuido (DLT) e introduce el nuevo marco propietario 3S de Blockchain (Seguro-Escalable-Sostenible).`}
              imagePosition="right"
              imageWidth="30%"
              imageHeight="350px"
            />
          </div>

          {/* Sección 3: TrainingInfoCard + ObjectivesCard sobre fondo gris */}
          <div style={{ background: "#fff", padding: "40px 0" }}>
            <div style={{ margin: "0 auto", maxWidth: "95%" }}>
              <TrainingInfoCard />
            </div>
            <div style={{ margin: "40px auto", maxWidth: "95%" }}>
              <ObjectivesCard title="Objetivos" objectives={objectives} />
            </div>
          </div>

          {/* OutcomeCard */}
          <div style={{ margin: "40px auto", maxWidth: "1200px" }}>
            <OutcomeCard title="Resultados" outcomes={outcomes} />
          </div>

          {/* Sección 4: Header + CourseCard */}
          <div style={{ margin: "40px 0" }}>
            <Header
              title="Detalles de la Certificación"
              background="#fff"
              textColor="#333333"
              height="20vh"
            />
          </div>

          <div style={{ margin: "40px auto", maxWidth: "900px" }}>
            <CourseCard />
          </div>

          {/* InfoCard 3 */}
          <div style={{ margin: "40px auto", maxWidth: "1200px" }}>
            <InfoCard
              title="Recursos Adicionales y Retroalimentación"
              description={`Los estudiantes contarán con material de lectura, videos y ejercicios prácticos que complementan el aprendizaje. Además, se fomenta la retroalimentación constante para adaptar el contenido a las necesidades individuales de los alumnos, ajustando la duración y profundidad de los módulos según la comprensión y el ritmo de cada grupo. Este curso es ideal para aquellos que deseen dominar Blockchain y finanzas descentralizadas, preparándose para las demandas tecnológicas y económicas del futuro.`}
              imageSrc="https://img.recraft.ai/xcg3zgirsSRSh4m10LNuPALNwaO67kqKEQlBL5vM99k/rs:fit:1536:1024:0/q:80/g:no/plain/abs://prod/images/a064f510-1bd1-4701-8fb6-56d382e1927c@avif"
              imagePosition="left"
              imageWidth="30%"
              imageHeight="350px"
            />
          </div>

          {/* Sección 5: Header + FeaturesGrid */}
          <div style={{ margin: "40px 0" }}>
            <Header
              title="Características del Curso"
              background="#fff"
              textColor="#333333"
              height="20vh"
            />
          </div>
          <div style={{ margin: "40px auto", maxWidth: "1200px" }}>
            <FeaturesGrid />
          </div>
        </main>
      )}
    </div>
  );
};

export default Home;
