import React from 'react';

const VideoPlayer = ({ videoUrl }) => {
  if (!videoUrl) {
    return (
      <h2 style={{ textAlign: 'center', padding: '50px' }}>
        Seleccione un video para reproducir
      </h2>
    );
  }

  // Convertir URL normal de YouTube en formato embed
  let embedUrl = videoUrl.includes("watch?v=")
    ? videoUrl.replace("watch?v=", "embed/")
    : videoUrl;

  // Agregar parámetros para ocultar controles, compartir, logo, etc.
  embedUrl += "?rel=0&controls=0&modestbranding=1&showinfo=0&disablekb=1&fs=0&iv_load_policy=3";

  return (
    <div
      style={{
        width: '80%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        perspective: '1000px', // Profundidad 3D
      }}
    >
      <div
        style={{
          width: '100%',
          height: '0',
          paddingBottom: '60.25%', // Relación de aspecto 16:9
          position: 'relative',
          transform: 'rotateX(10deg) rotateY(-5deg)', // Efecto 3D
          boxShadow: '5px 10px 20px rgba(0, 0, 0, 0.5)', // Sombra 3D
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={embedUrl}
          title="Video Player"
          frameBorder="0"
          // Nota: "allowFullScreen" no funcionará con fs=0
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
