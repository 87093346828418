// MarkdownProcessor.jsx
import React, { useEffect } from 'react';

const MarkdownProcessor = ({ url, onContentProcessed }) => {
  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(url);
        const markdownText = await response.text();

        const lines = markdownText.split('\n');
        const sections = [];
        let currentSection = null;

        lines.forEach((line) => {
          // Detecta títulos (H1, H2, H3)
          const headingRegex = /^#{1,3}\s+(.*)/;
          const match = line.match(headingRegex);

          if (match) {
            // Si había una sección abierta, la guardamos
            if (currentSection) {
              sections.push(currentSection);
            }

            // Creamos nueva sección
            const [_, headingText] = match; // headingText es el texto del título sin ##
            currentSection = {
              id: headingText
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^a-z0-9-]/g, ''), // Limpieza opcional
              title: headingText.trim(),
              content: [],
            };
          }
          // Detectamos la línea de imagen
          else if (line.trim().startsWith('![') && line.includes('](')) {
            // Si estamos en una sección, agregamos la línea de imagen
            if (currentSection) {
              currentSection.content.push(line);
            }
          }
          // Otras líneas (párrafos, listas, etc.) que no estén vacías y no sean título
          else if (line.trim() !== '') {
            if (currentSection) {
              currentSection.content.push(line);
            }
          }
        });

        // Agregar la última sección si quedó algo pendiente
        if (currentSection) {
          sections.push(currentSection);
        }

        // Retornamos el resultado a quien use este componente
        if (onContentProcessed) {
          onContentProcessed(sections);
        }
      } catch (error) {
        console.error('Error al obtener el archivo Markdown:', error);
      }
    };

    fetchMarkdown();
  }, [url, onContentProcessed]);

  return null; // no renderiza nada por sí solo
};

export default MarkdownProcessor;
