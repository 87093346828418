import { Canvas, useFrame, useLoader, extend, useThree } from '@react-three/fiber';
import { OrbitControls, Stars, Text } from '@react-three/drei';
import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { useSpring, animated } from '@react-spring/three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass';
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader';

extend({ EffectComposer, RenderPass, ShaderPass, FilmPass });

const atmosphereShader = {
  vertexShader: `
    varying vec3 vNormal;
    void main() {
      vNormal = normalize( normalMatrix * normal );
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `,
  fragmentShader: `
    varying vec3 vNormal;
    void main() {
      float intensity = pow( 0.8 - dot( vNormal, vec3( 0, 0, 1.0 ) ), 3.0 );
      gl_FragColor = vec4( 0.3, 0.4, 0.6, 0.05 ) * intensity;
    }
  `,
};

function Earth({ data }) {
  const earthRef = useRef();
  const radius = 300;

  const earthTexture = useLoader(THREE.TextureLoader, 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/68727/earth-lights.jpg');
  const bumpTexture = useLoader(THREE.TextureLoader, 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/68727/earth-bump.jpg');

  useFrame(() => {
    if (earthRef.current) {
      earthRef.current.rotation.y += 0.005;
      TWEEN.update();
    }
  });

  return (
    <group position={[150, 0, 0]}>
      <mesh ref={earthRef}>
        <sphereGeometry args={[radius, 50, 30]} />
        <meshPhongMaterial
          map={earthTexture}
          bumpMap={bumpTexture}
          bumpScale={4}
          emissiveMap={earthTexture}
          emissive="#ffffff"
          specular="#ffffff"
        />
      </mesh>

      <mesh>
        <sphereGeometry args={[radius * 1.3, 50, 30]} />
        <shaderMaterial
          vertexShader={atmosphereShader.vertexShader}
          fragmentShader={atmosphereShader.fragmentShader}
          side={THREE.BackSide}
          blending={THREE.AdditiveBlending}
          transparent
        />
      </mesh>

      {data.map((pointData, index) => (
        <Point key={index} lat={pointData.lat} lon={pointData.long} r={pointData.r} radius={radius} />
      ))}
    </group>
  );
}

function Point({ lat, lon, r, radius }) {
  const position = latLongToVector3(lat, lon, radius);
  return (
    <mesh position={position}>
      <sphereGeometry args={[r, 32, 32]} />
      <meshBasicMaterial color="#080808" opacity={0} transparent />
    </mesh>
  );
}

function latLongToVector3(lat, lon, r) {
  const phi = (lat * Math.PI) / 180;
  const theta = ((lon - 180) * Math.PI) / 180;

  const x = -r * Math.cos(phi) * Math.cos(theta);
  const y = r * Math.sin(phi);
  const z = r * Math.cos(phi) * Math.sin(theta);

  return new THREE.Vector3(x, y, z);
}

function Effects() {
  const composer = useRef();
  const { gl, scene, camera, size } = useThree();
  const aspect = size.width / size.height;

  useEffect(() => {
    composer.current.setSize(size.width, size.height);
  }, [size]);

  useEffect(() => {
    const renderPass = new RenderPass(scene, camera);
    const filmPass = new FilmPass(0.35, 0.025, 2048, false);
    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms['amount'].value = 0.0015;

    composer.current.addPass(renderPass);
    composer.current.addPass(rgbShift);
    composer.current.addPass(filmPass);
  }, [scene, camera]);

  useFrame(() => composer.current.render(), 1);

  return <effectComposer ref={composer} args={[gl]} />;
}

export default function EarthScene() {
  const data = [
    { long: -119.698189, lat: 34.420830, r: 5 },
    // Agrega más puntos según sea necesario
  ];

  const { opacity } = useSpring({
    from: { opacity: 0.5 },
    to: { opacity: 1 },
    config: { duration: 1000 },
    loop: { reverse: true },
  });

  return (
    <div id="app" style={{ position: 'relative' }}>



      {/* Texto grande en la izquierda */}
      <div style={{
        position: 'absolute',
        right: '100x',
        top: '50%',
        transform: 'translateY(-50%)',
        color: 'white',
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '1.5',
        zIndex: 1,
        textAlign: 'left',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '85%'
      }}>
      
      </div>


      <Canvas style={{ height: '50vh', backgroundColor: '#080808' }} camera={{ position: [0, 0, 700], fov: 60 }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} intensity={0.3} />
        <Earth data={data} />
        <Stars radius={300} depth={50} count={1000} factor={7} saturation={0} fade />
        <OrbitControls enableZoom={false} enableRotate={false} enablePan={false} autoRotate autoRotateSpeed={0.2} />
        <Effects />
      </Canvas>
    </div>
  );
}
