import React from "react";
import VideoList from "../components/VideoComponents/VideoList";

function App() {
  return (
    <div className="w-screen h-screen">
      <VideoList />
    </div>
  );
}

export default App;
