import React, { useState, useEffect } from 'react';
import styles from './Carta.module.css';

const collection1BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/AquaCollection/main/';
const collection2BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/ImagenesEar/main/';
const collection3BaseUrl = 'https://raw.githubusercontent.com/CoachblockchainDapp/TerraImages/main/';

const getRandomImage = () => {
  const totalWeight = 50 + 40 + 10;
  const random = Math.random() * totalWeight;
  let id, url, collection;

  if (random < 50) {
    id = Math.floor(Math.random() * (10280 - 10181 + 1)) + 10181;
    url = `${collection3BaseUrl}${id}.png`;
    collection = 'TERRA COLECCIÓN';
  } else if (random < 50 + 40) {
    id = Math.floor(Math.random() * (140 - 47 + 1)) + 47;
    url = `${collection1BaseUrl}${id}.png`;
    collection = 'AQUA COLECCIÓN';
  } else {
    id = Math.floor(Math.random() * (97 - 1 + 1)) + 1;
    url = `${collection2BaseUrl}${id}.png`;
    collection = 'AER COLECCIÓN';
  }

  return { id, url, collection };
};

const NFTCard = () => {
  const [cards, setCards] = useState([]);
  const [maticPrice, setMaticPrice] = useState(null);
  const usdPrice = 10; // Precio en USD

  useEffect(() => {
    // Obtener imágenes aleatorias con IDs
    const newCards = Array.from({ length: 54 }, () => getRandomImage());
    setCards(newCards);

    // Obtener el precio actual de MATIC en USD
    const fetchMaticPrice = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd');
        const data = await response.json();
        setMaticPrice(data['matic-network'].usd);
      } catch (error) {
        console.error('Error al obtener el precio de MATIC:', error);
      }
    };

    fetchMaticPrice();
  }, []);

  const handleMint = (index) => {
    // Lógica para mintear el NFT
   
  };

  // Calcular el precio en MATIC equivalente a usdPrice
  const priceInMatic = maticPrice ? (usdPrice / maticPrice).toFixed(2) : 'Cargando...';

  return (
    <div className={styles.cartaContainer}>
      {cards.map((card, index) => (
        <div className={styles.carta} key={index} >
          <img src={card.url} alt={`Carta ${index + 1}`} className={styles.cartaImg} />
          <h3 className={styles.cartaTitle}>{`${card.collection} # ${card.id}`}</h3>
          <p className={styles.priceText}>Precio: {priceInMatic} POL / 50 USD</p>
          <button
            onClick={() => handleMint(index)}
            className={styles.mintButton}
          >
            Mint
          </button>
        </div>
      ))}
    </div>
  );
};

export default NFTCard;
