import React from 'react';
import styles from './resourceCard.module.css';

const ResourceCard = () => {
  return (
    <div className={styles.resourceCard}>
      <h2 className={styles.animatedTitle}>Recursos Adicionales y Retroalimentación</h2>
      <p>
        Los estudiantes contarán con material de lectura, videos y ejercicios prácticos que complementan el aprendizaje. Además, se fomenta la retroalimentación constante para adaptar el contenido a las necesidades individuales de los alumnos, ajustando la duración y profundidad de los módulos según la comprensión y el ritmo de cada grupo.
      </p>
      <p>
        Este curso es ideal para aquellos que deseen dominar Blockchain y finanzas descentralizadas, preparándose para las demandas tecnológicas y económicas del futuro.
      </p>
    </div>
  );
};

export default ResourceCard;
