import React, { useState, useEffect } from 'react';
import styles from './roadmap.module.css';
import Header from '../LoadPages/AcademicCollaborationCard'

const roadmap = [
  { step: 1, title: "Fundamentos de Blockchain", color: "#0A5364", icon: "📖", description: "Comprende los principios básicos de blockchain: descentralización, consenso y seguridad." },
  { step: 2, title: "Economía de los Tokens", color: "#0A5364", icon: "💰", description: "Aprende cómo funcionan los tokens y su impacto en la economía digital." },
  { step: 3, title: "Tokenización de Activos", color: "#0A5364", icon: "🏠", description: "Descubre cómo los activos físicos y digitales pueden representarse en blockchain." },
  { step: 4, title: "Seguridad en Blockchain", color: "#0A5364", icon: "🔐", description: "Explora los mecanismos de seguridad, criptografía y protección contra ataques." },
  { step: 5, title: "Contratos Inteligentes y Legalidad", color: "#0A5364", icon: "⚖️", description: "Entiende cómo los contratos inteligentes pueden automatizar procesos legales y comerciales." },
  { step: 6, title: "Desarrollo de Proyectos", color: "#0A5364", icon: "🛠", description: "Aprende a construir un proyecto blockchain desde cero con herramientas modernas." },
  { step: 7, title: "Cumplimiento Regulatorio", color: "#0A5364", icon: "✅", description: "Conoce las regulaciones y normativas legales en distintas jurisdicciones." },
  { step: 8, title: "Marketing y Comunidad", color: "#0A5364", icon: "📢", description: "Descubre cómo construir y hacer crecer una comunidad en torno a tu proyecto blockchain." },
  { step: 9, title: "Trading y Exchanges", color: "#0A5364", icon: "📈", description: "Aprende estrategias de trading y el funcionamiento de los exchanges de criptomonedas." },
  { step: 10, title: "NFTs y Mercado Digital", color: "#0A5364", icon: "🎨", description: "Explora la creación y comercialización de NFTs en el mercado digital." },
  { step: 11, title: "Transacciones y Wallets", color: "#0A5364", icon: "💳", description: "Conoce cómo funcionan las billeteras digitales y las transacciones en blockchain." },
  { step: 12, title: "Aplicaciones Prácticas", color: "#0A5364", icon: "🔧", description: "Ejemplos reales de cómo blockchain está revolucionando industrias." },
  { step: 13, title: "Riesgos y Beneficios", color: "#0A5364", icon: "⚠️", description: "Análisis de los pros y contras de la tecnología blockchain en distintos sectores." },
  { step: 14, title: "Nuevas Tecnologías", color: "#0A5364", icon: "🚀", description: "Explora tecnologías emergentes como la interoperabilidad y la escalabilidad." },
  { step: 15, title: "Cierre y Proyecto Final", color: "#0A5364", icon: "🏆", description: "Consolida tus conocimientos con un proyecto final aplicando lo aprendido." },
];

const Roadmap = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setItems(roadmap);
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <div className={styles.loading}>Cargando roadmap...</div>;
  }

  return (
    <div className={styles.roadmap}>
      <Header title="Roadmap de Blockchain" subtitle="" height="300px" />
      <h1 className={styles.title}>  Explora este roadmap integral para convertirte en un experto en blockchain, desde lo básico hasta aplicaciones avanzadas.</h1>
      <p className={styles.description}>
       
      </p>

      {items.map((item) => (
        <div key={item.step} className={styles.roadmapStep} style={{ borderColor: item.color }}>
          <div className={styles.roadmapIcon} style={{ backgroundColor: item.color }}>
            {item.icon}
          </div>
          <div className={styles.roadmapContent}>
            <h2>{item.title}</h2>
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Roadmap;
